import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Text, Heading, Divider, Flex, Link } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { cmsUrl } from "../../../url";

const Resources = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = () => {
    fetch(`${cmsUrl}/events?_sort=date:ASC`)
      .then((res) => res.json())
      .then((resJson) => {
        setLoading(false);
        setEvents(resJson);
      });
  };
  return (
    <Box>
      <Box className="container">
        <Box pb="38px">
          <Heading
            fontSize={48}
            fontWeight="600"
            marginBottom={2}
            color="rgba(0, 0, 0, 0.9)"
          >
            Events
          </Heading>
          <Text fontSize={18} color="#000000">
            Our curated list of events and showcases.
          </Text>
          <Divider color="rgba(130, 127, 152, 0.1)" mt="23px" />
        </Box>
      </Box>
      <Box backgroundColor="#FBFBFB">
        <Box className="container">
          <Flex justifyContent="center" p="81px 0 91px">
            <Text
              textAlign="center"
              maxWidth="70%"
              color="#000000"
              fontSize={18}
            >
              Join us and industry experts for interactive sessions featuring a
              brief presentation followed by a Q&A. Topics will cover our latest
              perspectives on public health and shaping the future for what lies
              ahead.{" "}
              <Text as="span" fontWeight={500}>
                Registration is limited on a first-come, first-served basis
              </Text>
              . Replays are available below.
            </Text>
          </Flex>
        </Box>
      </Box>
      <Box className="container">
        <Box p="77px 0">
          <Text color="#000000" fontSize={14} mb="77px">
            REGISTER FOR UPCOMING EVENTS
          </Text>
          {events.length === 0 && !loading && (
            <Flex justifyContent="center">
              <Text>There are no upcoming events at the moment.</Text>
            </Flex>
          )}
          {events.map((event) => {
            const { id, title, description, date, time, registration_url } = event;
            const day = moment(date).format("DD");
            const month = moment(date).format("MMM");
            const year = moment(date).format("YYYY");
            const WAT = moment(time, "hh:mm:ss").format("h:mmA");
            return (
              <Flex
                mb="62px"
                pb="62px"
                borderBottom="1px solid rgba(130, 127, 152, 0.1)"
                key={id}
              >
                <Flex
                  h="75px"
                  width="75px"
                  borderRadius="3px"
                  backgroundColor="#EBEBEB"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  color="#000000"
                  fontSize={12}
                  fontWeight={500}
                  mr="47px"
                >
                  <Text>{month}</Text>
                  <Text>{day}</Text>
                  <Text>{year}</Text>
                </Flex>
                <Box>
                  <Heading
                    color="#252733"
                    fontSize={28}
                    fontWeight={500}
                    maxW="670px"
                    mb="32px"
                  >
                    {title}
                  </Heading>
                  <Text color="#252733" mb="32px">
                    TIME: {WAT} GMT+1/WAT
                  </Text>
                  <Text
                    color="#252733"
                    fontSize={18}
                    fontWeight={300}
                    maxW="905px"
                    mb="20px"
                  >
                    {description}
                  </Text>
                  <Link href={registration_url} isExternal>
                    <Text
                      color="#0E62FF"
                      fontSize={14}
                      fontWeight={600}
                      cursor="pointer"
                      display="inline-block"
                    >
                      Register to Attend{" "}
                      <Text as="span">
                        <ArrowForwardIcon color="#0E62FF" />
                      </Text>
                    </Text>
                  </Link>
                </Box>
              </Flex>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Resources;
