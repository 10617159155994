import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  CLEAR_MESSAGE,
} from "../constants"

import axios from "axios"
import { baseUrl } from "../../url"

const userProfileFail = payload => {
  return {
    type: USER_PROFILE_FAIL,
    payload,
  }
}

const updateProfileFail = payload => {
  return {
    type: PROFILE_UPDATE_FAIL,
    payload,
  }
}

const clearMessage = () => {
  return { type: CLEAR_MESSAGE }
}

export const getUserProfile = (id, token) => async dispatch => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  await dispatch({ type: USER_PROFILE_REQUEST })
  await axios
    .get(`${baseUrl}/users/profile/${id}`, config)
    .then(res => {
      dispatch({ type: USER_PROFILE_SUCCESS, payload: res.data.data })
      localStorage.setItem("user", JSON.stringify(res.data.data))
    })
    .catch(ex => {
      dispatch(userProfileFail(`Check internet connection and retry.`))
    })
}

export const updateUserPersonalProfile = formData => async dispatch => {
  const token = localStorage.getItem("bearerToken")
  const config = { headers: { Authorization: `Bearer ${token}` } }
  await dispatch({ type: PROFILE_UPDATE_REQUEST })
  await axios
    .put(`${baseUrl}/users/profile/personal`, formData, config)
    .then(res => {
      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: { data: res.data.data, message: res.data.message },
      })
      setTimeout(() => dispatch(clearMessage()), 1000)
      localStorage.setItem("user", JSON.stringify(res.data.data))
    })
    .catch(err => {
      dispatch(updateProfileFail(`Profile update failed.`))
      setTimeout(() => dispatch(clearMessage()), 1000)
    })
}

export const updateUserProProfile = data => async dispatch => {
  const token = localStorage.getItem("bearerToken")
  const config = { headers: { Authorization: `Bearer ${token}` } }
  await dispatch({ type: PROFILE_UPDATE_REQUEST })
  await axios
    .put(`${baseUrl}/users/profile/professional`, data, config)
    .then(res => {
      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: { data: res.data.data, message: res.data.message },
      })
      setTimeout(() => dispatch(clearMessage()), 1000)
      localStorage.setItem("user", JSON.stringify(res.data.data))
    })
    .catch(err => {
      dispatch(updateProfileFail(`Profile update failed.`))
      setTimeout(() => dispatch(clearMessage()), 1000)
    })
}
