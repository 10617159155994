import React from "react";
import { Progress, Box } from "@chakra-ui/react";

const ProgressBar = ({ progress }) => {
  return (
    <Box>
      <Progress
        color="green"
        size="sm"
        value={progress}
        width={172}
        borderRadius={20}
      />
    </Box>
  );
};

export default ProgressBar;
