import React from "react";
import { Box, Flex, Heading, HStack, Text, Select } from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import SearchComponent from "../../components/SearchComponent";
import GoBack from "../../components/GoBack";
import axios from "axios";
import { baseUrl } from "../../../../url";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Visualizations = () => {
  let location = useLocation();
  let query = useQuery();
  let history = useHistory();
  const [selectedIndicator, setselectedIndicator] = React.useState("");
  const [indicators, setindicators] = React.useState([]);
  const [disease, setdisease] = React.useState({});

  React.useEffect(() => {
    // get disease details
    axios
      .get(
        `${baseUrl}/external/diseases/${query.get(
          "diseaseId"
        )}?token=${window.localStorage.getItem("bearerToken")}`
      )
      .then((response) => {
        setdisease(response.data.data);
        setindicators(response.data.data.externalIndicators);
        console.log(response.data.data.externalIndicators);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box className='container-sm'>
      <Box marginTop={10}>
        <GoBack
          to={"/databank/external/indicators?disease=" + query.get("diseaseId")}
        />
      </Box>

      <Flex
        marginY={10}
        justifyContent='space-between'
        alignItems='center'
        borderBottom='1px solid #eee'
        paddingBottom={10}
      >
        <Heading fontSize={32} fontWeight='500' fontFamily='CamptonMedium'>
          {disease.name}
        </Heading>
        <HStack spacing={3} alignItems='center'>
          <Text>Filtered by</Text>
          <Select
            width='140px'
            borderRadius={2}
            border='1px solid rgba(103, 121, 142, 0.15)'
            borderColor='gray'
            onChange={e => setselectedIndicator(e.target.value)}
          >
            {indicators.map(indicator => (
              <option value={indicator}>{indicator.name}</option>
            ))}
          </Select>
        </HStack>
      </Flex>

      <Box
        marginBottom={20}
        background='rgba(255, 255, 255, 0.4)'
        border='1px solid rgba(103, 121, 142, 0.15)'
      >
        <iframe
          width='100%'
          height='800px'
          src={location.state.visualizations}
          title={query.get("diseaseName")}
        ></iframe>
      </Box>
    </Box>
  )
};

export default Visualizations;
