import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Heading,
  Avatar,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  HStack,
  Input,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import SearchComponent from "../../components/SearchComponent";
import axios from "axios";
import { baseUrl } from "../../../../url";
import Skeleton from "react-loading-skeleton";
import { MagnifyIcon } from "../../../../components/Icons";

const Countries = () => {
  let history = useHistory();
  const [countries, setcountries] = useState([]);
  const [countriesCopy, setcountriesCopy] = useState([]);

  const handleSearch = (searchWord) => {
    let filteredCountries = countriesCopy.filter((country) => {
      return country.name.toLowerCase().includes(searchWord.toLowerCase());
    });
    setcountries(filteredCountries);
  };

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/country/all?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setcountries(response.data.data);
        setcountriesCopy(response.data.data);
      });
  }, []);

  return (
    <Box className="container">
      <Breadcrumb
        marginTop={10}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        fontWeight={500}
      >
        <BreadcrumbItem>
          <BreadcrumbLink color="#2A81FF" href="/databank/overview">
            Databank
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#" color="rgba(0,0,0,0.5)">
            Countries
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex marginY={10} justifyContent="space-between">
        <SearchComponent
          searchPlaceholder="Search for a country"
          pageTitle="Select a country"
        />
        <HStack
          background="white"
          border="1px solid rgba(103, 121, 142, 0.12)"
          borderRadius={2}
          height="45px"
          paddingLeft={4}
        >
          <MagnifyIcon />
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            _focus={{ outline: 0 }}
            placeholder="Search for a country"
            border={0}
            borderRadius={0}
          />
        </HStack>
      </Flex>

      {/* countries */}
      <Grid templateColumns="repeat(4, 1fr)" marginTop={10} gap={10}>
        {countries.length === 0 && (
          <>
            <Skeleton width="100%" height={130} />
            <Skeleton width="100%" height={130} />
            <Skeleton width="100%" height={130} />
            <Skeleton width="100%" height={130} />
            <Skeleton width="100%" height={130} />
            <Skeleton width="100%" height={130} />
          </>
        )}

        {countries.map((country, index) => {
          return (
            <Box
              _hover={{ background: "rgba(255,255,255,0.3)" }}
              cursor={country.diseases.length > 0 ? "pointer" : "default"}
              key={index}
              w='100%'
              p={5}
              bg={country.diseases.length > 0 ? "white" : "transparent"}
              style={{ opacity: country.diseases.length > 0 ? 1 : 0.4 }}
              borderRadius={5}
              border='1px solid rgba(103, 121, 142, 0.15)'
              onClick={() =>
                country.diseases.length > 0 &&
                history.push(
                  `/databank/overview/Diseases?country=${country.id}`
                )
              }
            >
              <Flex alignItems='center'>
                <Avatar
                  size='sm'
                  src={"http://api.adein.cchub.xyz" + country.flag}
                />

                <Heading
                  marginLeft={2}
                  fontSize={20}
                  fontWeight={500}
                  fontFamily='CamptonMedium'
                >
                  {country.name}
                </Heading>
              </Flex>
              <Text marginTop={3}>
                Currently, we have a total of{" "}
                <b>{country.diseases.length} Diseases</b> in {country.name}.
              </Text>
            </Box>
          )
        })}
      </Grid>
    </Box>
  );
};

export default Countries;
