import React from "react";
import { Image, Button, Flex, Link } from "@chakra-ui/react";
import Logo from "../../../assets/adein-logo.svg";
import { useHistory } from "react-router-dom";

const Nav = ({ showLoginButton, showSignupButton }) => {
  let history = useHistory();
  return (
    <Flex
      justifyContent="space-between"
      maxWidth="1037px"
      width="90%"
      marginX="auto"
    >
      <Link href="/">
        <Image src={Logo} alt="logo" />
      </Link>
      {showLoginButton && (
        <Button
          onClick={() => history.push("/login")}
          _hover={{
            boxShadow: "inset 200px 0 0 0 #2A81FF",
            color: "#fff",
          }}
          backgroundColor="transparent"
          borderWidth={2}
          borderStyle="solid"
          borderColor="#2A81FF"
          borderRadius={8}
          padding={5}
          color="#2A81FF"
          width={95}
        >
          Login
        </Button>
      )}
      {showSignupButton && (
        <Button
          display={["none", "none", "flex"]}
          marginLeft={[0, 0, 10]}
          background="transparent"
          color="#2A81FF"
          borderColor="#2A81FF"
          borderWidth={2}
          borderStyle="solid"
          size="lg"
          fontFamily="Gordita"
          fontWeight={500}
          fontSize={14}
          onClick={() => history.push("/signup")}
          _hover={{
            boxShadow: "inset 200px 0 0 0 #2A81FF",
            color: "#fff",
          }}
        >
          Become a member
        </Button>
      )}
    </Flex>
  );
};

export default Nav;
