import React from "react";
import { Button, Flex, Image, Text } from "@chakra-ui/react";

const FormButton = ({ label, icon, handleButtonAction, loading }) => {
  return (
    <Button
      type="submit"
      onClick={handleButtonAction}
      _hover=""
      background="#2A81FF"
      size="lg"
      width={189}
      color="white"
      fontFamily="Gordita"
      fontWeight={500}
      fontSize={14}
      borderRadius={8}
      isLoading={loading}
    >
      <Flex>
        <Text>{label}</Text>
        {icon && <Image src={icon} marginLeft={3} />}
      </Flex>
    </Button>
  );
};
export default FormButton;
