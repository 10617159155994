export const formatBehaviourData = (data) => {
  const { female, male } = data;
  const femaleData = formatGenderData(female);
  const maleData = formatGenderData(male);
  return [
    //Female data
    {
      name: "SELF",
      yes: femaleData.yes,
      no: femaleData.no,
    },
    {
      name: "PARTNER",
      ...femaleData.partnerInfo,
    },
    //Male data
    {
      name: "SELF",
      yes: maleData.yes,
      no: maleData.no,
    },
    {
      name: "PARTNER",
      ...maleData.partnerInfo,
    },
  ];
};

const formatGenderData = (gender) => {
  const {
    no: { partnerInfo: no_partnerInfo },
    yes: { partnerInfo: yes_partnerInfo },
  } = gender;
  const partnerInfoObj = sumObjectsByKey(no_partnerInfo, yes_partnerInfo); //Add values of partnerInfo for yes and no together
  const genderNo = sumObjValues(no_partnerInfo); //Add all the values of partnerInfo for no to get the total value of no.
  const genderYes = sumObjValues(yes_partnerInfo); //Add all the values of partnerInfo for yes to get the total value of yes.
  return {
    no: genderNo,
    yes: genderYes,
    partnerInfo: partnerInfoObj,
  };
};

//Add the values of 2 indentical object together.
const sumObjectsByKey = (...objs) => {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
    }
    return a;
  }, {});
};

//Sum all the values of an object.
const sumObjValues = (obj) => {
  return Object.keys(obj).reduce(
    (sum, key) => sum + parseFloat(obj[key] || 0),
    0
  );
};

export const compareObjects = (x, y) => {
  return JSON.stringify(x) === JSON.stringify(y);
}

export const omitObjEmptyKeys = (obj, arr) =>
  Object.keys(obj)
    .filter(k => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {})
    // use case
    // let emptyKeys = Object.keys(obj).filter(k => !obj[k])
    // let data = omitObjEmptyKeys(obj, emptyKeys)

export const facebookData = {
  created_at: "2021-01-14 09:14:50",
  externalIndicators: [
    {
      id: "fbdfg-1",
      external_disease_id: "fbdfg-1",
      name: "Relative Wealth Index",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link: "https://data.humdata.org/dataset/relative-wealth-index",
    },
    {
      id: "fbdfg-2",
      external_disease_id: "fbdfg-2",
      name: "Population Density",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link: "https://data.humdata.org/organization/facebook",
    },
    {
      id: "fbdfg-3",
      external_disease_id: "fbdfg-3",
      name: "Movement Range Maps",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link: "https://data.humdata.org/dataset/movement-range-maps",
    },
    {
      id: "fbdfg-4",
      external_disease_id: "fbdfg-4",
      name: "COVID-19 Symptom Survey",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link:
        "https://gisumd.github.io/COVID-19-API-Documentation/docs/indicators/indicators_symptoms.html",
    },
    {
      id: "fbdfg-5",
      external_disease_id: "fbdfg-5",
      name: "COVID-19 Preventive Health Survey",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link: "https://covidsurvey.mit.edu/api.html",
    },
    {
      id: "fbdfg-6",
      external_disease_id: "fbdfg-6",
      name: "Social Connectedness Index",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link: "https://data.humdata.org/dataset/social-connectedness-index",
    },
    {
      id: "fbdfg-7",
      external_disease_id: "fbdfg-7",
      name: "COVID-19 Forecasts",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link: "https://data.humdata.org/dataset/fair-covid-dataset",
    },
    {
      id: "fbdfg-8",
      external_disease_id: "fbdfg-8",
      name: "CrowdTangle COVID-19 Live Displays",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link:
        "https://dataforgood.facebook.com/dfg/tools/crowd-tangle#accessdata",
    },
    {
      id: "fbdfg-9",
      external_disease_id: "fbdfg-9",
      name: "Future of Business Survey",
      created_at: "2021-01-14 09:14:50",
      updated_at: "2021-01-14 09:14:50",
      data_link:
        "https://data.humdata.org/dataset/future-of-business-survey-aggregated-data",
    },
  ],
  name: "Facebook Data for Good",
  updated_at: "2021-02-01 13:49:14",
  id: "fbdfg",
  icon: "",
}