import React from "react";
import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";
import Nav from "./components/Nav";
import Completeicon from "../../assets/complete.svg";

const Complete = () => {
  return (
    <Box backgroundColor='#F3F7FF' height='100vh'>
      <Box paddingY={5}>
        <Nav />
      </Box>

      <Flex
        maxW='491px'
        width='94%'
        background={["transparent", "transparent", "white"]}
        paddingX={[2, 20]}
        paddingY={[20]}
        marginX='auto'
        borderRadius={20}
        position='relative'
        top={[8, 20]}
        border='1px solid'
        borderColor={["transparent", "transparent", "rgba(103, 121, 142, 0.2)"]}
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Image src={Completeicon} />
        <Heading
          fontFamily='CamptonLight'
          fontSize={20}
          fontWeight={700}
          color='#18131A'
          marginTop={8}
          marginBottom={5}
          textAlign='center'
        >
          Successful!
        </Heading>
        <Text
          maxW='400px'
          width='100%'
          marginX='auto'
          fontFamily='CamptonLight'
          fontSize={16}
          fontWeight={500}
          color='#1D1E22'
          textAlign='center'
          lineHeight='25px'
        >
          Your form has been submitted successfully, you will receive an email
          if your application is accepted.
        </Text>
      </Flex>
    </Box>
  )
};

export default Complete;
