import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Grid,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  // Heading,
} from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { baseUrl } from "../../../../../url"
import axios from "axios"
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts"

import {
  CommonCustomInput,
  CommonCustomTextarea,
} from "../../../../../components/inputsComponent"
import RequestData from "../../RequestData"

import { useQuery } from "../../../../../customhooks/useQuery"

const EligibilityByEnrollment = () => {
  let location = useLocation()
  let query = useQuery()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [eligibilityByDateData, seteligibilityByDateData] = useState([])

  const [firstAndMiddleName, setFirstAndMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [position, setPosition] = useState("")
  const [requestReason, setRequestReason] = useState("")
  const [disease, setDisease] = useState("")
  const [dataRequestLoading, setDataRequestLoading] = useState(false)

  const closeModal = () => {
    onClose()
  }

  const setInitialStates = () => {
    const userInfo = JSON.parse(localStorage.getItem("user"))

    setLastName(userInfo.last_name)
    setEmail(userInfo.login_email)
    setPosition(userInfo.current_occupation || "")
    setFirstAndMiddleName(userInfo.first_name)
  }

  const requestData = () => {
    let data = {
      data: disease,
      reason: requestReason,
    }
    if (!requestReason) {
      toast({
        title: "Important",
        description: "Please enter reason for data request.",
        status: "info",
        position: "top",
        duration: 5000,
        isClosable: true,
      })
      return
    }
    setDataRequestLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = { headers: { Authorization: `Bearer ${token}` } }
    axios
      .post(`${baseUrl}/data-request`, data, config)
      .then(res => {
        // console.log(res, "data req res")
        setDataRequestLoading(false)
        closeModal()
        toast({
          title: "Important",
          description: "Data requested successfully.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(ex => {
        setDataRequestLoading(false)
        toast({
          title: "Something went wrong",
          description: "Check internet connection and retry.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
  }

  useEffect(() => {
    setInitialStates()
    const diseaseName = query.get("diseaseName")
    const indicatorPathname = query.get("indicatorPathname")
    setDisease(indicatorPathname)

    axios
      .get(
        `${baseUrl}/disease/${diseaseName.toLowerCase()}/indicator/${indicatorPathname}?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        const { eligibilityByDate } = response.data.data
        const propertynames = Object.keys(eligibilityByDate)
        const propertyvalues = Object.values(eligibilityByDate)
        let arrayedData = []
        for (let i = 0; i <= propertynames.length; i++) {
          arrayedData.push({
            value: propertyvalues[i],
            name: propertynames[i],
          })
        }
        seteligibilityByDateData(arrayedData)
      })
      .catch(error => {
        console.log(error)
      })
  }, [location.state])

  return (
    <Box paddingTop={10}>
      {/* <Heading marginTop={20} fontSize={18}>
        {location.state.indicator.name}
      </Heading> */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Eligibility by date
        </Text>
        <LineChart width={1050} height={500} data={eligibilityByDateData}>
          <XAxis dataKey='name' stroke='#000' fontSize='12px' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Line
            type='monotone'
            dataKey='value'
            stroke='#0E62FF'
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </Box>

      {/*Request Data Banner*/}
      <Box paddingBottom={20}>
        <RequestData handleClick={() => onOpen()} />
      </Box>

      {/* DATA REQUEST MODAL */}
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request for Data on Covid-19 </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid gap={6} templateColumns='repeat(2, 1fr)'>
              <Box>
                <CommonCustomInput
                  // handleInput={value => setFirstAndMiddleName(value)}
                  value={firstAndMiddleName}
                  width='100%'
                  label='First name'
                  inputType='text'
                  placeholder='John'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  // handleInput={value => setLastName(value)}
                  value={lastName}
                  width='100%'
                  label='Last name'
                  inputType='text'
                  placeholder='Doe'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Grid gap={6} templateColumns='repeat(2, 1fr)' marginTop={6}>
              <Box>
                <CommonCustomInput
                  // handleInput={value => setEmail(value)}
                  value={email}
                  width='100%'
                  label='Email Address'
                  inputType='email'
                  placeholder='example@email.com'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  // handleInput={value => setPosition(value)}
                  value={position}
                  width='100%'
                  label='Current Position'
                  inputType='text'
                  placeholder='UX Designer'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Box marginTop={10}>
              <CommonCustomTextarea
                handleInput={value => setRequestReason(value)}
                value={requestReason}
                width='100%'
                label='Reason for Data Request'
                placeholder='Enter your reason for requesting this data'
                inputType='text'
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              paddingX={8}
              paddingY={6}
              fontSize={15}
              colorScheme='blue'
              variant='outline'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              fontSize={15}
              paddingX={8}
              paddingY={6}
              ml={5}
              onClick={requestData}
              isLoading={dataRequestLoading}
            >
              Request Data
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default EligibilityByEnrollment;
