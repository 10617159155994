import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const GoBack = () => {
  let history = useHistory();
  return (
    <Flex
      backgroundColor="transparent"
      padding={0}
      onClick={() => history.goBack()}
      cursor="pointer"
      alignItems="baseline"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.871 5.25L6.894 1.227L5.8335 0.166504L9.53674e-07 6L5.8335 11.8335L6.894 10.773L2.871 6.75H12V5.25H2.871Z"
          fill="#2A81FF"
        />
      </svg>
      <Text
        marginLeft={3}
        color="#2A81FF"
        fontFamily="Gordita"
        fontWeight={500}
        fontSize={14}
      >
        Go Back
      </Text>
    </Flex>
  );
};

export default GoBack;
