import React from "react";
import { Image, Flex, Heading } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const DiseaseCard = ({ disease, link }) => {
  const history = useHistory();

  return (
    <Flex
      cursor="pointer"
      _hover={{ background: "rgba(191, 227, 245, 1)" }}
      w="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={5}
      h={48}
      bg="rgba(191, 227, 245, 0.6)"
      borderRadius={50}
      onClick={() => history.push(link)}
    >
      <Image src={"http://api.adein.cchub.xyz" + disease.icon} />
      <Heading
        marginTop={5}
        fontSize={20}
        fontWeight={700}
        fontFamily="CamptonBold"
        textAlign="center"
      >
        {disease.name}
      </Heading>
    </Flex>
  );
};

export default DiseaseCard;
