import React from "react";
import { Box, Text, Image, Heading, Button } from "@chakra-ui/react";
import Illustration from "../../assets/images/dashboard-illustration.png";
import { useHistory } from "react-router-dom";

const Hero = () => {
  let history = useHistory();

  return (
    <Box background="#F6F9FC" paddingTop={20}>
      <Box className="container" textAlign="center">
        <Heading
          textAlign="center"
          fontSize={46}
          fontWeight={700}
          marginBottom={5}
          className="gradient-heading"
          lineHeight="64px"
          maxWidth={650}
          width="100%"
          marginX="auto"
          position="relative"
          fontFamily="CamptonSemiBold"
        >
          Africa Digital Epidemiology Innovation Network.
        </Heading>
        <Text
          maxWidth={603}
          textAlign="center"
          width="100%"
          marginX="auto"
          fontSize={18}
          fontWeight={400}
          fontFamily="CamptonLight"
          color="#47484C"
          lineHeight="32px"
          className="sub-heading"
        >
          A community that provides an opportunity for members to lend their
          expertise and resources, to collaborate and conduct research for
          various projects in epidemiology.
        </Text>
        <Button
          marginTop={8}
          width={234}
          paddingY={8}
          size="lg"
          background="#2A81FF"
          fontSize={18}
          color="white"
          _hover=""
          onClick={() => history.push("/signup")}
        >
          Become a Member
        </Button>
        <Box className="curve" overflow="hidden" paddingTop={10}>
          <Image src={Illustration} width="898px" marginX="auto" />
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
