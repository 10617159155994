import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Box,
  Grid,
  Flex,
  Image,
  Button,
  Input,
  Avatar,
  useToast,
} from "@chakra-ui/react"
import axios from "axios"
import PageHeader from "../../../../components/profile/pageHeader"
import {
  CommonCustomInput,
  CustomDropDown,
} from "../../../../components/inputsComponent"
import { UploadIcon } from "../../../../components/Icons"
import { updateUserPersonalProfile } from "../../../../redux/actions/user.action"

const appUrl = process.env.REACT_APP_URL
const API_TOKEN =
  "FDshA17s4G79Ga7wBDOAgAhkTI1mJ9z8U5oGOAg8JGR48tJX6QTDnk8EWQbB9mzKe2s"
const UNIVERSAL_API_EMAIL = "dev@cchubnigeria.com"
const UNIVERSAL_API = "https://www.universal-tutorial.com"

const PersonalInfo = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const userAvatarRef = useRef()

  const user = useSelector(state => state.user)
  const { userData, isLoading, message, profileUpdateError } = user

  const [countries, setCountries] = useState([])
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [selectedImage, setSelectedImage] = useState(false)
  const [imgPayload, setImgPayload] = useState(null)

  let ImagePayload = null
  const previewImage = e => {
    const file = e.target.files[0]
    ImagePayload = file
    setImgPayload(ImagePayload)
    const reader = new FileReader()
    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string for preview
        setSelectedImage(true)
        setTimeout(() => (userAvatarRef.current.src = reader.result), 500)
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    message &&
      toast({
        title: "Important",
        description: `${message}`,
        status: "success",
        position: "top",
        duration: 5000,
        isClosable: true,
      })
    profileUpdateError &&
      toast({
        title: "Error",
        description: `${profileUpdateError}`,
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      })
    return () => {}
  }, [message, profileUpdateError])

  const fetchCountriesList = () => {
    const config = {
      headers: {
        Accept: "application/json",
        "api-token": API_TOKEN,
        "user-email": UNIVERSAL_API_EMAIL,
      },
    }
    axios
      .get(`${UNIVERSAL_API}/api/getaccesstoken`, config)
      .then(resp => {
        const authToken = resp.data.auth_token
        const authConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }

        axios
          .get(`${UNIVERSAL_API}/api/countries`, authConfig)
          .then(({ data }) => {
            let countryOptions = data.map(country => {
              let aCountry = { ...country, name: country.country_name }
              return aCountry
            })
            setCountries(countryOptions)
          })
          .catch(error => {
            console.dir(error.response)
          })
      })
      .catch(error => {
        console.error(error.response)
      })
  }

  useEffect(() => {
    // axios.get("https://restcountries.eu/rest/v2/all").then(response => {
    //   setCountries(response.data)
    // })
    fetchCountriesList()
  }, [])

  useEffect(() => {
    const setInitialStates = () => {
      userData && setFirstName(userData.first_name)
      userData && setLastName(userData.last_name)
      userData && setEmail(userData.login_email)
      userData && setPhone(userData.phone || "")
      userData && setCity(userData.city)
      userData && setCountry(userData.country)
      userData && setImageUrl(userData.avatar || "")
    }
    setInitialStates()
  }, [userData])

  const handleSubmit = () => {
    let userData = {
      first_name: firstName,
      last_name: lastName,
      phone,
      country,
      city,
    }

    let formData = new FormData()
    for (let key in userData) {
      formData.append(key, userData[key])
    }

    if (imgPayload) {
      formData.append("avatar", imgPayload)
    }

    if (!firstName || !lastName || !phone || !country || !city) {
      toast({
        title: "Important",
        description: `All fields are required.`,
        status: "info",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      })
      return
    }

    dispatch(updateUserPersonalProfile(formData))
  }

  return (
    <Box className='container'>
      <Box marginTop={10} marginBottom={14}>
        <PageHeader
          title='⚙️ Personal Information'
          subtitle='You can view or update your personal information.'
        />
      </Box>
      <Box width={443} marginX='auto'>
        <Flex
          align='center'
          background='rgba(255, 255, 255, 0.5)'
          border='1px solid rgba(29, 30, 34, 0.06)'
          borderRadius={10}
          height={150}
          paddingX={30}
          paddingY={30}
        >
          <Box width={90} height={90}>
            {userData && imageUrl === "default.png" && !selectedImage ? (
              <Avatar
                size='sm'
                name={userData.first_name + " " + userData.last_name}
                bg='blue'
                color='white'
                width={90}
                height={90}
              />
            ) : (
              <Image
                ref={userAvatarRef}
                borderRadius='full'
                boxSize='90px'
                objectFit='cover'
                src={`${appUrl}/${imageUrl}`}
                ignoreFallback={true}
                alt={firstName}
              />
            )}
          </Box>
          <Flex
            position='relative'
            align='center'
            ml={5}
            height='100%'
            width={156}
          >
            <Button
              fontFamily='CamptonMedium'
              leftIcon={<UploadIcon />}
              colorScheme='blue'
              variant='outline'
              position='absolute'
              width={156}
            >
              Upload Photo
            </Button>
            <Input
              width={156}
              position='absolute'
              left={0}
              type='file'
              opacity={0}
              onChange={previewImage}
            />
          </Flex>
        </Flex>
        <Grid gap={6} templateColumns='repeat(2, 1fr)' marginTop={6}>
          <Box>
            <CommonCustomInput
              handleInput={value => setFirstName(value)}
              value={firstName}
              width='100%'
              label='First name'
              inputType='text'
              placeholder='John'
              isDisabled={false}
              labelFontSize={15}
            />
          </Box>
          <Box>
            <CommonCustomInput
              handleInput={value => setLastName(value)}
              value={lastName}
              width='100%'
              label='Last name'
              inputType='text'
              placeholder='Doe'
              isDisabled={false}
              labelFontSize={15}
            />
          </Box>
        </Grid>
        <Box marginTop={6}>
          <CommonCustomInput
            // handleInput={value => setLastName(value)}
            value={email}
            width='100%'
            label='Email Address'
            inputType='text'
            placeholder='Doe'
            isDisabled={true}
            labelFontSize={15}
          />
        </Box>
        <Box marginTop={6}>
          <CommonCustomInput
            handleInput={value => setPhone(value)}
            value={phone}
            width='100%'
            label='Phone Number'
            inputType='text'
            placeholder='(+234) 8090706050'
            isDisabled={false}
            labelFontSize={15}
          />
        </Box>{" "}
        <Box marginTop={6}>
          <CustomDropDown
            required={false}
            options={countries}
            handleOption={value => setCountry(value)}
            value={country}
            width='100%'
            label='Country'
            placeholder='Select Country'
            inputType='text'
          />
        </Box>
        <Box marginTop={6}>
          <CommonCustomInput
            handleInput={value => setCity(value)}
            value={city}
            width='100%'
            label='City'
            inputType='text'
            placeholder='Lagos'
            isDisabled={false}
            labelFontSize={15}
          />
        </Box>
        <Flex justify='center' marginTop={16}>
          <Button
            colorScheme='blue'
            fontFamily='CamptonMedium'
            fontSize={16}
            fontWeight={500}
            paddingX={8}
            paddingY={6}
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Save Changes
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default PersonalInfo
