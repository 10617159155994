import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Grid,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  // Heading,
} from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { baseUrl } from "../../../../../url"
import axios from "axios"
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts"

import {
  CommonCustomInput,
  CommonCustomTextarea,
} from "../../../../../components/inputsComponent"
import RequestData from "../../RequestData"

import { useQuery } from "../../../../../customhooks/useQuery"

const EligibilityForTesting = () => {
  let location = useLocation()
  let query = useQuery()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [byAgeGroupData, setbyAgeGroupData] = useState([])
  const [byBCGVaccineData, setbyBCGVaccineData] = useState([])
  const [byGenderData, setbyGenderData] = useState([])
  const [byMaritalStatusData, setbyMaritalStatusData] = useState([])
  const [byNationalityData, setbyNationalityData] = useState([])

  const [firstAndMiddleName, setFirstAndMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [position, setPosition] = useState("")
  const [requestReason, setRequestReason] = useState("")
  const [disease, setDisease] = useState("")
  const [dataRequestLoading, setDataRequestLoading] = useState(false)

  const closeModal = () => {
    onClose()
  }

  const setInitialStates = () => {
    const userInfo = JSON.parse(localStorage.getItem("user"))

    setLastName(userInfo.last_name)
    setEmail(userInfo.login_email)
    setPosition(userInfo.current_occupation || "")
    setFirstAndMiddleName(userInfo.first_name)
  }

  const requestData = () => {
    let data = {
      data: disease,
      reason: requestReason,
    }
    if (!requestReason) {
      toast({
        title: "Important",
        description: "Please enter reason for data request.",
        status: "info",
        position: "top",
        duration: 5000,
        isClosable: true,
      })
      return
    }
    setDataRequestLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = { headers: { Authorization: `Bearer ${token}` } }
    axios
      .post(`${baseUrl}/data-request`, data, config)
      .then(res => {
        // console.log(res, "data req res")
        setDataRequestLoading(false)
        closeModal()
        toast({
          title: "Important",
          description: "Data requested successfully.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(ex => {
        setDataRequestLoading(false)
        toast({
          title: "Something went wrong",
          description: "Check internet connection and retry.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
  }

  const format = data => {
    const propertynames = Object.keys(data)
    const propertyvalues = Object.values(data)
    let arrayedData = []
    for (let i = 0; i <= propertynames.length; i++) {
      arrayedData.push({ value: propertyvalues[i], name: propertynames[i] })
    }
    return arrayedData
  }

  const byAgeGroup = data => {
    let formattedData = format(data)
    setbyAgeGroupData(formattedData)
  }

  const byBCGVaccine = data => {
    let formattedData = format(data)
    setbyBCGVaccineData(formattedData)
  }

  const byGender = data => {
    let formattedData = format(data)
    setbyGenderData(formattedData)
  }

  const byMaritalStatus = data => {
    let formattedData = format(data)
    setbyMaritalStatusData(formattedData)
  }
  const byNationality = data => {
    let formattedData = format(data)
    setbyNationalityData(formattedData)
  }

  useEffect(() => {
    setInitialStates()
    const diseaseName = query.get("diseaseName")
    const indicatorPathname = query.get("indicatorPathname")
    setDisease(indicatorPathname)

    axios
      .get(
        `${baseUrl}/disease/${diseaseName.toLowerCase()}/indicator/${indicatorPathname}?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        byAgeGroup(response.data.data.byAgeGroup)
        byBCGVaccine(response.data.data.byBCGVaccine)
        byGender(response.data.data.byGender)
        byMaritalStatus(response.data.data.byMaritalStatus)
        byNationality(response.data.data.byNationality)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <Box paddingTop={10}>
      {/* <Heading marginTop={20} fontSize={18}>
        {location.state.indicator.name}
      </Heading> */}
      {/* tests by age group */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Age Group
        </Text>

        <BarChart width={1050} height={449} data={byAgeGroupData}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='value'
            fill='#50E3C2'
            barSize={20}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by BCG Vaccine */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by BCG Vaccine
        </Text>
        <BarChart width={1050} height={449} data={byBCGVaccineData}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='value'
            fill='#2D9CDB'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by Gender */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Gender
        </Text>
        <BarChart width={1050} height={449} data={byGenderData}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='value'
            fill='#2D9CDB'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by martial sTATUS */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Marital Status
        </Text>
        <BarChart width={1050} height={449} data={byMaritalStatusData}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='value'
            fill='#50E3C2'
            barSize={35}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by nationality */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Nationality
        </Text>
        <BarChart width={1050} height={449} data={byNationalityData}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='value'
            fill='#50E3C2'
            barSize={20}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/*Request Data Banner*/}
      <Box paddingBottom={20}>
        <RequestData handleClick={() => onOpen()} />
      </Box>

      {/* DATA REQUEST MODAL */}
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request for Data on Covid-19 </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid gap={6} templateColumns='repeat(2, 1fr)'>
              <Box>
                <CommonCustomInput
                  handleInput={value => setFirstAndMiddleName(value)}
                  value={firstAndMiddleName}
                  width='100%'
                  label='First name'
                  inputType='text'
                  placeholder='John'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  handleInput={value => setLastName(value)}
                  value={lastName}
                  width='100%'
                  label='Last name'
                  inputType='text'
                  placeholder='Doe'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Grid gap={6} templateColumns='repeat(2, 1fr)' marginTop={6}>
              <Box>
                <CommonCustomInput
                  handleInput={value => setEmail(value)}
                  value={email}
                  width='100%'
                  label='Email Address'
                  inputType='email'
                  placeholder='example@email.com'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  handleInput={value => setPosition(value)}
                  value={position}
                  width='100%'
                  label='Current Position'
                  inputType='text'
                  placeholder='UX Designer'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Box marginTop={10}>
              <CommonCustomTextarea
                handleInput={value => setRequestReason(value)}
                value={requestReason}
                width='100%'
                label='Reason for Data Request'
                placeholder='Enter your reason for requesting this data'
                inputType='text'
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              paddingX={8}
              paddingY={6}
              fontSize={15}
              colorScheme='blue'
              variant='outline'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              fontSize={15}
              paddingX={8}
              paddingY={6}
              ml={5}
              onClick={requestData}
              isLoading={dataRequestLoading}
            >
              Request Data
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default EligibilityForTesting;
