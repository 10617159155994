import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Heading,
  Image,
  Stack,
  useToast,
  Progress,
} from "@chakra-ui/react";
import Nav from "./components/Nav";
import ProgressBar from "./components/PregressBar";
import GoBack from "./components/GoBack";
import { CustomInput } from "./components/CustomInputs";
import { useHistory } from "react-router-dom";
import FormButton from "./components/FormButton";
import ArrowRight from "../../assets/arrow-right.svg";
import UploadIcon from "../../assets/upload.svg";
import UploadedIcon from "../../assets/uploaded.svg";
import { updateStore } from "../../utils/littleStore/action";
import { useStateMachine } from "little-state-machine";
import { CloseIcon } from "@chakra-ui/icons";
import axios from "axios";
import { baseUrl } from "../../url";
import { CustomDropDown } from "./components/CustomInputs";

const ProfessionalQualifications = () => {
  const form = useRef(null);
  let toast = useToast();
  let history = useHistory();

  const {
    action,
    state: {
      littleStore: {
        resume_file,
        linkedin_profile,
        organization_name,
        current_occupation,
        professional_email,
        expertise,
      },
    },
  } = useStateMachine(updateStore);

  const [uploadStatus, setuploadStatus] = useState(null);
  const [occupations, setOccupations] = useState([]);

  const handleSubmit = () => {
    if (!professional_email) {
      return toast({
        title: "Please include Professional Email Address",
        description: "",
        status: "error",
        duration: 4000,
        position: "top",
        isClosable: true,
      });
    }
    history.push("/Signup/LoginDetails");
  };

  const closeUpload = () => {
    setuploadStatus(null);
    action({ resume_file: "" });
  };

  const handleUpload = e => {
    e.preventDefault();
    setuploadStatus("uploading");
    const data = new FormData(form.current);
    console.log(data.get("file"));

    axios
      .post(
        `${baseUrl}/registration/upload`,

        data
      )
      .then(response => {
        setuploadStatus("uploaded");
        action({ resume_file: response.data.data.path });
        console.log(response);
      })
      .catch(err => {
        setuploadStatus(null);
        console.log(err.data);
      });
  };

  useEffect(() => {
    const fetchOccupations = () => {
      axios
        .get(`${baseUrl}/users/filter/dd`)
        .then(response => {
          let extendArr = response.data.data.map(occupation => {
            return { name: occupation };
          });
          setOccupations(extendArr);
        })
        .catch(err => {
          console.log(err.data);
        });
    };
    fetchOccupations();
  }, []);

  return (
    <Box paddingBottom={20}>
      <Box
        paddingY={5}
        position={["relative", "sticky"]}
        top={0}
        width='100%'
        backgroundColor='#F3F7FF'
        border='1px solid rgba(103, 121, 142, 0.1)'
        zIndex={2}
      >
        <Nav showLoginButton={true} />
        <Flex
          maxWidth='400px'
          marginX='auto'
          width='90%'
          position='relative'
          top={["0", "-30px"]}
          flexDirection='column'
          alignItems='center'
          paddingTop={[10, 5, 0]}
          // paddingBottom={[15, 5, 0]}
        >
          <ProgressBar progress={70} />
          <Heading
            fontFamily='Gordita'
            fontSize={24}
            fontWeight={700}
            color='#1D1E22'
            marginTop={10}
            marginBottom={3}
          >
            Professional Qualifications
          </Heading>
          <Text
            fontFamily='CamptonLight'
            fontSize={16}
            fontWeight={500}
            color='#1D1E22'
            textAlign='center'
            lineHeight='25px'
          >
            The following are requirements for the general public to join the
            <b>Digital Epidemiology Network.</b>
          </Text>
        </Flex>
      </Box>
      {/* body */}
      <Flex
        flexDirection={["column", "column", "row"]}
        maxWidth='1037px'
        width='100%'
        marginX='auto'
        marginY={10}
        justifyContent='space-between'
      >
        <Box
          marginLeft={[5, 10, 18, 0]}
          width={["90%", "20%"]}
          marginBottom={10}
        >
          <GoBack />
        </Box>
        <Box maxW={443} width={["90%", "90%", "60%"]} marginX='auto'>
          <Stack spacing={[4, 8]}>
            {/* upload doc */}
            <Text
              color='#1D1E22'
              fontFamily='CamptonLight'
              fontSize={15}
              fontWeight={400}
            >
              Upload Sample of previous Works (Publications, articles,
              documents, etc)
            </Text>
            <Box
              marginBottom={10}
              borderRadius={10}
              borderColor='rgba(29,30,34,.5)'
              borderWidth={1}
              borderStyle='dashed'
              height={161}
              position='relative'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              {!resume_file && uploadStatus === null && (
                <form
                  ref={form}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                  }}
                >
                  <input
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      opacity: 0,
                    }}
                    name='file'
                    onChange={e => handleUpload(e)}
                    id='uploadBtn'
                    type='file'
                    accept='.pdf,.docx,.png,.jpg'
                    className='upload'
                  />
                </form>
              )}

              {/* not uploaded yet */}
              {!resume_file && uploadStatus === null && (
                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Image src={UploadIcon} width='36px' marginBottom={5} />
                  <Flex
                    color='#2A81FF'
                    alignItems='center'
                    fontSize={14}
                    fontWeight={400}
                    fontFamily='CamptonLight'
                  >
                    <Text color='rgba(29, 30, 34, 0.8)'>
                      Drop file here or{" "}
                    </Text>
                    Browse
                  </Flex>
                  <Text
                    color='rgba(29, 30, 34, 0.6)'
                    fontSize={14}
                    fontWeight={400}
                    fontFamily='CamptonLight'
                  >
                    Size limit: 5MB (pdf or docx)
                  </Text>
                </Flex>
              )}
              {/* uploading */}
              {uploadStatus === "uploading" && (
                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Image src={UploadIcon} width='36px' marginBottom={5} />
                  <Text
                    color='rgba(29, 30, 34, 0.6)'
                    fontSize={14}
                    fontWeight={400}
                    fontFamily='CamptonLight'
                  >
                    Uploading
                  </Text>
                  <Progress
                    marginTop={5}
                    color='#2A81FF'
                    size='sm'
                    height='5px'
                    width={142}
                    borderRadius={20}
                    isIndeterminate
                  />
                </Flex>
              )}
              {/* uploaded */}
              {(resume_file || uploadStatus === "uploaded") && (
                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Image src={UploadedIcon} width='36px' marginBottom={5} />
                  <Flex
                    background='rgba(14,98,255,0.1)'
                    borderRadius={4}
                    alignItems='center'
                    justifyContent='space-between'
                    width={184}
                    paddingX={13}
                    paddingY={11}
                  >
                    <Text
                      color='#0E62FF'
                      fontSize={14}
                      fontWeight={400}
                      fontFamily='CamptonLight'
                    >
                      {resume_file.slice(0, 15) + "..."}
                    </Text>
                    <CloseIcon
                      onClick={() => closeUpload()}
                      color='#0E62FF'
                      fontSize={14}
                    />
                  </Flex>
                </Flex>
              )}
            </Box>

            <Box>
              <CustomInput
                handleInput={value => action({ linkedin_profile: value })}
                value={linkedin_profile}
                width='100%'
                label='LinkedIn profile'
                inputType='text'
                placeholder='Enter your LinkedIn URL'
              />
            </Box>

            <Box>
              <CustomDropDown
                required={true}
                options={occupations}
                handleOption={value => action({ current_occupation: value })}
                value={current_occupation}
                width='100%'
                label='Current Occupation'
                placeholder='Select Occupation'
              />
            </Box>
            <Box>
              <CustomInput
                handleInput={value => action({ organization_name: value })}
                value={organization_name}
                width='100%'
                label='Organization Name'
                placeholder='Enter your organization’s name'
                inputType='text'
              />
            </Box>
            {/* <Box>
              <CustomInput
                handleInput={(value) => action({ professional_email: value })}
                value={professional_email}
                width="100%"
                label="Personal Email Address"
                placeholder="Enter your personal email"
                inputType="email"
              />
            </Box> */}
            <Box>
              <CustomInput
                required={true}
                handleInput={value => action({ professional_email: value })}
                value={professional_email}
                width='100%'
                label='Professional Email Address'
                placeholder='Enter email address'
                inputType='email'
              />
            </Box>
            <Box>
              <CustomInput
                handleInput={value => action({ expertise: value })}
                value={expertise}
                width='100%'
                label='Expertise'
                placeholder='Researcher, Health Practitioners, others.'
                inputType='text'
              />
            </Box>
          </Stack>
        </Box>
        <Box width='20%'></Box>
      </Flex>
      <Flex justifyContent='center' marginTop={18}>
        <FormButton
          label='Continue'
          handleButtonAction={handleSubmit}
          icon={ArrowRight}
        />
      </Flex>
    </Box>
  );
};

export default ProfessionalQualifications;
