import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  CLEAR_MESSAGE,
} from "../constants"

const userDataFromStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null

const initialState = {
  profileLoading: false,
  error: null,
  userData: userDataFromStorage,
  profileUpdateError: null,
  isLoading: false,
  message: null,
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { ...state, profileLoading: true, error: null }
    case USER_PROFILE_SUCCESS:
      return { ...state, profileLoading: false, userData: action.payload }
    case USER_PROFILE_FAIL:
      return { ...initialState, error: action.payload }
    case PROFILE_UPDATE_REQUEST:
      return { ...state, isLoading: true }
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload.data },
        message: action.payload.message,
        isLoading: false,
      }
    case PROFILE_UPDATE_FAIL:
      return {
        ...initialState,
        profileUpdateError: action.payload,
      }
    case CLEAR_MESSAGE:
      return { ...state, message: null, profileUpdateError: null }
    default:
      return state
  }
}
