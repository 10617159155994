import React, { useState } from "react"
import { Box, Flex, Button, useToast } from "@chakra-ui/react"
import axios from "axios"
import PageHeader from "../../../../components/profile/pageHeader"
import { CustomInputPassword } from "../../../../components/inputsComponent"
import { baseUrl } from "../../../../url"

const ProfilePassword = () => {
  let toast = useToast()

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [isLoading, setisLoading] = useState("")

  const handleSubmit = () => {
    if (!oldPassword || !newPassword) {
      toast({
        title: "Important",
        description: "Please enter old password and new password.",
        status: "info",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      })
      return
    }
    if (newPassword.length < 8) {
      toast({
        title: "Important",
        description: "New password must be a minimum of 8 characters.",
        status: "info",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      })
    } else {
      setisLoading(true)
      let userData = {
        old_password: oldPassword,
        new_password: newPassword,
      }
      const token = localStorage.getItem("bearerToken")
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      axios
        .put(`${baseUrl}/users/profile/password`, { ...userData }, config)
        .then(response => {
          console.log(response, "pass")
          setOldPassword("")
          setNewPassword("")
          toast({
            title: "Important",
            description: `${response.data.message}.`,
            status: "success",
            position: "top",
            duration: 5000,
            isClosable: true,
          })
          setisLoading(false)
        })
        .catch(err => {
          setisLoading(false)
          if (err.response) {
            toast({
              title: "Error!",
              description: `${err.response.data.message}`,
              status: "error",
              position: "top",
              duration: 5000,
              isClosable: true,
            })
          } else {
            toast({
              title: "An error has occured",
              description: `Check internet connection and try again.`,
              status: "error",
              position: "top",
              duration: 5000,
              isClosable: true,
            })
          }
        })
    }
  }
  return (
    <Box className='container'>
      <Box marginTop={10} marginBottom={14}>
        <PageHeader
          title='🔑️ Password'
          subtitle='Change your old password to a new one.'
        />
      </Box>
      <Box width={443} marginX='auto'>
        <Box marginTop={6}>
          <CustomInputPassword
            required={false}
            handleInput={value => setOldPassword(value)}
            value={oldPassword}
            width='100%'
            label='Old Password'
            inputType='Password'
            placeholder='Enter Old password'
          />
        </Box>
        <Box marginTop={6}>
          <CustomInputPassword
            required={false}
            handleInput={value => setNewPassword(value)}
            value={newPassword}
            width='100%'
            label='New Password'
            inputType='Password'
            placeholder='Enter New password'
          />
        </Box>{" "}
        <Flex justifyContent='center' marginTop={16}>
          <Button
            colorScheme='blue'
            fontFamily='CamptonMedium'
            fontSize={16}
            fontWeight={500}
            paddingX={8}
            paddingY={6}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Change Password
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default ProfilePassword
