import React from "react";
import { Box, Flex, Heading, Button, Text } from "@chakra-ui/react";

const RequestData = ({ handleClick }) => {
  return (
    <Flex
      background='#0e62ff'
      justify='space-between'
      paddingX={14}
      paddingY={14}
      borderRadius={10}
      align='center'
    >
      <Box>
        <Heading
          color='white'
          fontFamily='CamptonSemiBold'
          fontSize={30}
          fontWeight='700'
        >
          Request Data
        </Heading>
        <Text
          color='white'
          fontSize={18}
          fontFamily='CamptonLight'
          fontWeight={500}
          width='80%'
        >
          This data is available to all registered members of ADEIN.
        </Text>
      </Box>
      <Button
        color='#0e62ff'
        fontSize={18}
        paddingX={10}
        fontWeight={400}
        fontFamily='CamptonLight'
        onClick={handleClick}
      >
        Request Data
      </Button>
    </Flex>
  )
}

export default RequestData;
