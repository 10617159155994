import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
} from "@chakra-ui/react";
import Dropdown from "../../assets/accordion-dropdown.svg";
import AccordionActive from "../../assets/accordion-active-arrow.svg";

const Accordion = ({ accordionData, toggleAccordion }) => {
  const [showAnswer, setShowAnswer] = useState(false)
  return (
    <Box marginBottom={5}>
      <Flex
        background={showAnswer ? "transparent" : "#FAFAFE"}
        onClick={() => setShowAnswer(!showAnswer)}
        justifyContent='space-between'
        alignItems='center'
        paddingY={[4, 4, 8]}
        paddingX={[2, 2, 8]}
        cursor='pointer'
        borderRadius={2}
      >
        <Text
          width='85%'
          fontFamily='CamptonLight'
          fontWeight={500}
          fontSize={[18, 18, 20]}
          color={showAnswer ? "#2A81FF" : "#47484C"}
        >
          {accordionData.question}
        </Text>
        <Flex justifyContent='flex-end' width='10%'>
          <Image src={showAnswer ? AccordionActive : Dropdown} />
        </Flex>
      </Flex>
      <Box
        fontSize={[16, 16, 20]}
        lineHeight='28.63px'
        color='#47484C'
        paddingX={[2, 2, 8]}
        marginBottom={showAnswer && 20}
      >
        {showAnswer && accordionData.answer()}
      </Box>
    </Box>
  )
}

const FrequentlyAskedQuestion = () => {
  let data = [
    {
      question: "How can I become an eligible member?",
      answer: () => {
        return (
          <Text fontFamily='CamptonLight'>
            You can become an eligible member by applying via the ADEIN Website{" "}
            <Link
              color='#000'
              fontWeight='bold'
              href='https://www.adeinetwork.org'
            >
              www.adeinetwork.org
            </Link>
            . All applications will be assessed based on academic and
            professional qualifications.
          </Text>
        )
      },
    },
    {
      question: "Is membership free or paid?",
      answer: () => {
        return <Text fontFamily='CamptonLight'>The membership is free.</Text>
      },
    },
    {
      question: "What are the benefits of joining the Network?",
      answer: () => {
        return (
          <Box fontFamily='CamptonLight' lineHeight='40px'>
            <Text fontFamily='CamptonLight'>
              The benefits of joining the Digital Epidemiology Network are:
            </Text>
            <List as='ol' styleType='decimal'>
              <ListItem>
                Contribution to the advancement of digital epidemiology in
                Africa.
              </ListItem>
              <ListItem>
                Collaborations on innovative projects in digital epidemiology
                and public health.
              </ListItem>
              <ListItem>
                Access to a wide range of data and information
              </ListItem>
              <ListItem>
                Networking and interactions with experts in public health, data
                science and technology, government officials and policymakers
              </ListItem>
              <ListItem>
                Participation in the innovation showcases and hackathons.
              </ListItem>
            </List>
          </Box>
        )
      },
    },
    {
      question:
        "How long does it take to get confirmed as a registered network member?",
      answer: () => {
        return (
          <Text fontFamily='CamptonLight'>
            Confirmation of membership takes 3-5 working days from date of
            application
          </Text>
        )
      },
    },
    {
      question:
        "Must I have a Public Health or Epidemiology background before I can join?",
      answer: () => {
        return (
          <Text fontFamily='CamptonLight'>
            No. Having a background in Public Health or Epidemiology is not a
            requirement or necessity. However, an eligible member of the network
            is expected to have great interests in healthcare, research, digital
            epidemiology, data analytics and health systems strengthening.
          </Text>
        )
      },
    },
    {
      question: "Is the network only meant for individuals?",
      answer: () => {
        return (
          <Text fontFamily='CamptonLight'>
            No. The network is meant for individuals, organizations and agencies
            with interests in disease detection and surveillance, research, data
            analytics and health system strengthening.
          </Text>
        )
      },
    },
    {
      question: "What are the activities of the network?",
      answer: () => {
        return (
          <Box fontFamily='CamptonLight' lineHeight='40px'>
            <Text fontFamily='CamptonLight'>
              The activities of the network include, but are not limited to:
            </Text>
            <List as='ol' styleType='decimal'>
              <ListItem>Collaborative research</ListItem>
              <ListItem>Innovation showcases</ListItem>
              <ListItem>Capacity building sessions</ListItem>
              <ListItem>Conferences</ListItem>
              <ListItem>Hackathons</ListItem>
              <ListItem>Networking Activities</ListItem>
            </List>
          </Box>
        )
      },
    },
  ]

  const toggleAccordion = item => {
    console.log(item)
  }

  return (
    <Box background='#fff' paddingY={32}>
      <Box className='container'>
        <Text
          textAlign={["center", "center", "left"]}
          fontFamily='CamptonMedium'
          fontSize={16}
          fontWeight={500}
          color='#1D1E22'
        >
          NEED ANSWERS? FIND THEM HERE!
        </Text>
        <Heading
          textAlign={["center", "center", "left"]}
          fontSize={34}
          fontWeight={700}
          marginTop={3}
          className='gradient-heading'
          fontFamily='CamptonSemiBold'
        >
          Frequently Asked Questions
        </Heading>

        <Box marginTop={20}>
          {data.map(item => {
            return (
              <Accordion
                key={item.question}
                accordionData={item}
                toggleAccordion={payload => toggleAccordion(payload)}
              />
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default FrequentlyAskedQuestion;
