import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Input,
  HStack,
  Container,
  Select,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import SearchComponent from "../../components/SearchComponent";
import GoBack from "../../components/GoBack";
import { baseUrl } from "../../../../url";
import axios from "axios";
import IndicatorList from "../../components/IndicatorList";
import { MagnifyIcon } from "../../../../components/Icons";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Indicators = () => {
  let history = useHistory();

  const [indicators, setindicators] = useState([]);
  const [indicatorsCopy, setindicatorsCopy] = useState([]);
  const [disease, setdisease] = useState({});
  const [primaryExists, setPrimaryExists] = useState(true);

  let query = useQuery();

  useEffect(() => {
    // get disease detail
    axios
      .get(
        `${baseUrl}/disease/${query.get(
          "disease"
        )}?token=${window.localStorage.getItem("bearerToken")}`
      )
      .then((response) => {
        setdisease(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // get indicators
    axios
      .get(
        `${baseUrl}/indicator/disease/${query.get(
          "disease"
        )}?token=${window.localStorage.getItem("bearerToken")}`
      )
      .then((response) => {
        setindicators(response.data.data);
        setindicatorsCopy(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSorting = (option) => {
    if (option === "a-z") {
      let sorted = indicators.sort((a, b) =>
        a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
      );
      setPrimaryExists((x) => !x);
      setindicators(sorted);
    }

    if (option === "z-a") {
      let sorted = indicators
        .sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0))
        .reverse();
      setPrimaryExists((x) => !x);
      // setindicators(sort);
      setindicators(sorted);
    }
  };

  const handleSearch = (searchWord) => {
    let filteredIndicators = indicatorsCopy.filter((indicator) => {
      return indicator.name
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(searchWord.replace(/\s/g, "").toLowerCase());
    });
    setindicators(filteredIndicators);
  };

  return (
    <Container maxW='5xl' paddingTop={10}>
      {/* <GoBack to={"/databank/overview/diseases"} /> */}
      <Box
        cursor='pointer'
        color='#2A81FF'
        fontFamily='CamptonMedium'
        fontWeight={500}
        fontSize={16}
        onClick={() =>
          history.push(
            `/databank/overview/diseases?country=${query.get("country")}`
          )
        }
      >
        {"< "}Go Back
      </Box>

      <Flex justifyContent='space-between' marginY={10}>
        <SearchComponent searchPlaceholder='' pageTitle={disease.name} />
        <HStack
          background='white'
          border='1px solid rgba(103, 121, 142, 0.12)'
          borderRadius={2}
          height='45px'
          paddingLeft={4}
        >
          <MagnifyIcon />
          <Input
            onChange={e => handleSearch(e.target.value)}
            _focus={{ outline: 0 }}
            placeholder='Search for Indicators'
            border={0}
            borderRadius={0}
          />
        </HStack>
      </Flex>

      <Box
        marginBottom={20}
        paddingTop={16}
        paddingBottom={5}
        paddingX={20}
        background='rgba(255, 255, 255, 0.4)'
        border='1px solid rgba(103, 121, 142, 0.15)'
      >
        <Flex justifyContent='space-between'>
          <Text fontWeight={700} fontSize={18} fontFamily='CamptonMedium'>
            All Indicators
          </Text>
          <Select
            placeholder='Sort by'
            width='108px'
            borderRadius={2}
            border='1px solid rgba(103, 121, 142, 0.15)'
            borderColor='gray'
            onChange={e => handleSorting(e.target.value)}
          >
            <option value='a-z'>A to Z</option>
            <option value='z-a'>Z to A</option>
          </Select>
        </Flex>

        <Box marginTop={4} key={String(primaryExists)}>
          {indicators.map((indicator, index) => {
            return (
              <Flex
                borderBottom={
                  index === indicators.length - 1 ? "none" : "1px solid #F0F0F0"
                }
                paddingY={5}
                justifyContent='space-between'
                alignItems='center'
                key={index}
                cursor='pointer'
                onClick={() =>
                  history.push(
                    `/databank/overview/charts?diseaseId=${query.get(
                      "disease"
                    )}&indicatorPathname=${
                      indicator.path_name
                    }&country=${query.get("country")}&diseaseName=${
                      disease.name
                    }`
                  )
                }
              >
                <Text fontFamily='CamptonLight' fontSize={16} fontWeight={400}>
                  {indicator.name}
                </Text>
                <svg
                  width='10'
                  height='10'
                  viewBox='0 0 10 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.00305 3.0605L1.5478 9.51575L0.487305 8.45525L6.9418 2H1.25305V0.5H9.50305V8.75H8.00305V3.0605Z'
                    fill='#2A81FF'
                  />
                </svg>
              </Flex>
            )
          })}
        </Box>
      </Box>
    </Container>
  )
};

export default Indicators;
