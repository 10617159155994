import React from "react";
import { Box, Flex, Text, Image, Link } from "@chakra-ui/react";
import dl_logo from "../../assets/dl-logo.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/instagram.svg";
import linkedin from "../../assets/linkedin.svg";

const Footer = () => {
  let socials = [
    {
      icon: twitter,
      link: "",
    },
    {
      icon: facebook,
      link: "",
    },

    {
      icon: linkedin,
      link: "",
    },
    {
      icon: instagram,
      link: "",
    },
  ];
  return (
    <Box background='#FCFCFE' paddingY={20}>
      <Flex
        className='container'
        justifyContent='space-between'
        flexWrap='wrap'
        alignItems='center'
      >
        <Box width={["100%", "50%"]} marginBottom={[10, 0]}>
          <Text
            fontSize={16}
            fontWeight={400}
            fontFamily='CamptonLight'
            marginBottom={4}
          >
            The secretariat of the Network is hosted by
          </Text>
          <Image src={dl_logo} />
        </Box>
        <Flex
          flexDirection='column'
          alignItems={["flex-start", "flex-end"]}
          width={["100%", "50%"]}
        >
          <Text
            fontSize={16}
            fontWeight={500}
            fontFamily='CamptonLight'
            marginBottom={4}
          >
            Contact Us
          </Text>
          <Flex width='120px' justifyContent='space-between'>
            {socials.map((social, idx) => {
              return (
                <Link key={idx} href={social.link}>
                  <Image src={social.icon} />
                </Link>
              )
            })}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
};

export default Footer;
