import React, { useState, useEffect } from "react";
import moment from 'moment';
import ReactMarkdown from "react-markdown";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Container,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { cmsUrl } from "../../../url";

const ReadSharedLearning = () => {
  const history = useHistory();
  const { id } = useParams();

  const [articleData, setArticleData] = useState({});

  useEffect(() => {
    fetchArticle(id);
  }, [id]);

  const fetchArticle = (id) => {
    console.log(`${cmsUrl}/innovation/${id}`)
    fetch(`${cmsUrl}/innovation/${id}`)
      .then((res) => res.json())
      .then((resJson) => {
        setArticleData(resJson);
      });
  };

  const thumbnailUrl = articleData.thumbnail
    ? `${cmsUrl}${articleData.thumbnail.url}`
    : "";

  const formatUrl = (text) => {
    return text.replaceAll("/uploads/", `${cmsUrl}/uploads/`);
  };

  return (
    <Box className="container">
      {/* go back */}
      <Flex
        marginTop={5}
        marginBottom={5}
        backgroundColor="transparent"
        padding={0}
        onClick={() => history.goBack()}
        cursor="pointer"
        alignItems="baseline"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.871 5.25L6.894 1.227L5.8335 0.166504L9.53674e-07 6L5.8335 11.8335L6.894 10.773L2.871 6.75H12V5.25H2.871Z"
            fill="#2A81FF"
          />
        </svg>
        <Text
          marginLeft={3}
          color="#2A81FF"
          fontFamily="Gordita"
          fontWeight={500}
          fontSize={14}
        >
          Go Back
        </Text>
      </Flex>

      <Heading fontSize={40} fontWeight="600" maxW="940px" marginBottom={8}>
        {articleData.title}
      </Heading>

      <Image
        src={thumbnailUrl}
        rounded={5}
        width="100%"
        height="508px"
        style={{ objectFit: "cover" }}
        marginBottom={12}
      />

      <Container maxW="4xl" marginBottom={10}  paddingBottom="150px" >
        <HStack justifyContent="space-between" width="100%">
          <Box width="90%">
            <Box marginBottom={5} fontSize={15}>
              <Text fontWeight="600">{articleData.author}</Text>
              <Text color="rgba(5, 0, 56, 0.6)">{moment(articleData.published_at).format("MMMM DD, YYYY")}</Text>
            </Box>
            <Box className="markdown">
            <ReactMarkdown>
              {articleData.body && formatUrl(articleData.body)}
            </ReactMarkdown>
            {/* <ReactMarkdown
              components={ChakraUIRenderer()}
              escapeHtml={false}
              children={articleData.body}
            /> */}
            </Box>
          </Box>
          <VStack width="8%" marginTop={10} spacing={3}>
            <Box>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6266 10.3333C11.6265 10.6869 11.4858 11.026 11.2356 11.2759C10.9855 11.5258 10.6462 11.6661 10.2926 11.6659C9.93901 11.6658 9.59994 11.5251 9.35001 11.2749C9.10009 11.0248 8.95978 10.6856 8.95996 10.3319C8.96014 9.97831 9.10078 9.63924 9.35096 9.38932C9.60113 9.1394 9.94034 8.99909 10.294 8.99927C10.6476 8.99944 10.9867 9.14009 11.2366 9.39026C11.4865 9.64044 11.6268 9.97965 11.6266 10.3333ZM11.6666 12.6533H8.99996V20.9999H11.6666V12.6533ZM15.88 12.6533H13.2266V20.9999H15.8533V16.6199C15.8533 14.1799 19.0333 13.9533 19.0333 16.6199V20.9999H21.6666V15.7133C21.6666 11.5999 16.96 11.7533 15.8533 13.7733L15.88 12.6533Z"
                  fill="#00051E"
                />
                <circle cx="15" cy="15" r="14.5" stroke="#00051E" />
              </svg>
            </Box>
            <Box>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="15" r="14.5" stroke="#00051E" />
                <path
                  d="M16.3337 15.9999H18.0003L18.667 13.3333H16.3337V11.9999C16.3337 11.3133 16.3337 10.6666 17.667 10.6666H18.667V8.42659C18.4497 8.39792 17.629 8.33325 16.7623 8.33325C14.9523 8.33325 13.667 9.43792 13.667 11.4666V13.3333H11.667V15.9999H13.667V21.6666H16.3337V15.9999Z"
                  fill="#00051E"
                />
              </svg>
            </Box>
            <Box>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="15" r="14.5" stroke="#00051E" />
                <path
                  d="M21.7742 10.7706C21.2653 10.9957 20.7255 11.1436 20.1729 11.2093C20.7554 10.8609 21.1913 10.3126 21.3995 9.66662C20.8529 9.99195 20.2535 10.22 19.6289 10.3433C19.2093 9.89434 18.6531 9.59659 18.0468 9.49634C17.4406 9.39609 16.8182 9.49895 16.2764 9.78892C15.7346 10.0789 15.3038 10.5397 15.051 11.0998C14.7981 11.6599 14.7374 12.2878 14.8782 12.886C13.7696 12.8304 12.6851 12.5423 11.6951 12.0404C10.705 11.5385 9.83163 10.834 9.13155 9.97262C8.88374 10.3983 8.75352 10.8821 8.75421 11.3746C8.75421 12.3413 9.24621 13.1953 9.99421 13.6953C9.55155 13.6813 9.11864 13.5618 8.73155 13.3466V13.3813C8.73168 14.0251 8.95446 14.649 9.36211 15.1473C9.76976 15.6456 10.3372 15.9876 10.9682 16.1153C10.5573 16.2266 10.1264 16.2431 9.70821 16.1633C9.88612 16.7174 10.2329 17.2021 10.6999 17.5494C11.167 17.8966 11.731 18.0891 12.3129 18.1C11.7345 18.5542 11.0723 18.8899 10.3641 19.0881C9.65594 19.2862 8.91563 19.3428 8.18555 19.2546C9.46001 20.0742 10.9436 20.5094 12.4589 20.5079C17.5875 20.5079 20.3922 16.2593 20.3922 12.5746C20.3922 12.4546 20.3889 12.3333 20.3835 12.2146C20.9295 11.8201 21.4006 11.3313 21.7749 10.7713L21.7742 10.7706Z"
                  fill="#00051E"
                />
              </svg>
            </Box>
          </VStack>
        </HStack>
      </Container>
    </Box>
  );
};

export default ReadSharedLearning;
