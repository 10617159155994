import React from "react";
import {
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  VStack,
  Image,
  Heading,
  HStack,
  Input,
} from "@chakra-ui/react";
import { MagnifyIcon } from "../Icons";

const PageHeader = ({
  title,
  subtitle,
  icon,
  value,
  handleInput,
  searchPlaceholder,
}) => (
  <Flex justifyContent='space-between' alignItems='center'>
    <Box>
      <Heading fontSize={32} fontWeight='500' marginBottom={2}>
        {title}
      </Heading>
      <Text fontSize={16} fontWeight='300' color='rgba(0, 0, 0, 0.65)'>
        {subtitle}
      </Text>
    </Box>
    {/*  */}
    <HStack
      background='white'
      border='1px solid rgba(103, 121, 142, 0.12)'
      borderRadius={2}
      height='45px'
      paddingLeft={4}
    >
      <MagnifyIcon />
      <Input
        _focus={{ outline: 0 }}
        placeholder={searchPlaceholder}
        border={0}
        borderRadius={0}
        value={value}
        onChange={e => handleInput(e.target.value)}
      />
    </HStack>
  </Flex>
)

export default PageHeader;
