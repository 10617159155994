import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Text,
  Grid,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Flex,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  Tag,
  TagLabel,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Avatar,
  Textarea,
} from "@chakra-ui/react"
import { SearchIcon, CloseIcon, SmallCloseIcon } from "@chakra-ui/icons"
import MemberCard from "../../../components/members/MemberCard"
import { baseUrl } from "../../../url"
import { useHistory } from "react-router-dom"

const appUrl = process.env.REACT_APP_URL

const Members = () => {
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [members, setMembers] = useState([])
  const [member, setMember] = useState(null)
  const [totalMembers, setTotalMembers] = useState(0)
  const [limit, setLimit] = useState(20)
  const [loading, setLoading] = useState(false)
  const [requestMsg, setRequestMsg] = useState("")
  const [sendLoading, setSendLoading] = useState(false)

  const [search, setSearch] = useState("")

  useEffect(() => {
    if (!search) getMembers()
    if (search) searchMembers(search)
  }, [limit, search])

  const getMembers = () => {
    setLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(`${baseUrl}/messages/users/list?limit=${limit}`, config)
      .then(({ data }) => {
        const { data: members, total } = data
        setMembers(members)
        setTotalMembers(total)
        setLoading(false)
      })
  }

  const searchMembers = query => {
    setLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(
        `${baseUrl}/messages/search/users/list?query=${query}&limit=${limit}`,
        config
      )
      .then(({ data }) => {
        const { data: members, total } = data
        setMembers(members)
        setTotalMembers(total)
        setLoading(false)
      })
  }

  const clearSearch = () => {
    setSearch("")
  }

  const openChat = id => {
    history.push({
      pathname: "/messages/chats",
      state: {
        chatId: id,
      },
    })
  }

  const loadMore = () => {
    let newLimit = limit + 20
    newLimit = newLimit > totalMembers ? totalMembers : newLimit
    setLimit(newLimit)
  }

  const createRequest = member => {
    setMember(member)
    onOpen()
  }

  const closeModal = () => {
    setMember(null)
    onClose()
  }

  const sendRequest = () => {
    setSendLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const body = {
      receiver_user_id: member.id,
      message: requestMsg,
    }
    axios
      .post(`${baseUrl}/messages/request`, body, config)
      .then(({ data }) => {
        setSendLoading(false)
        closeModal()
      })
      .catch(error => {
        console.log(error)
        setSendLoading(false)
      })
  }

  return (
    <Box className='container'>
      <Box py='65px'>
        <Flex alignItems='center' mb='60px'>
          <Text
            fontSize={32}
            fontWeight={500}
            color='rgba(0, 0, 0, 0.9)'
            mr='15px'
          >
            All Members
          </Text>
          <Tag
            borderRadius='full'
            h='34px'
            backgroundColor='#E5ECF9'
            px='12px'
            mr='auto'
          >
            <TagLabel color='#397EFE' fontSize={16}>
              {totalMembers}
            </TagLabel>
          </Tag>
          <InputGroup w='296px' mr='10px'>
            <InputLeftElement
              pointerEvents='none'
              children={<SearchIcon color='gray.300' />}
            />
            <Input
              h='45px'
              type='text'
              placeholder='Search for a member'
              borderColor='rgba(103, 121, 142, 0.12)'
              borderRadius='2px'
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
            {search && (
              <InputRightElement>
                <SmallCloseIcon color='gray.300' onClick={clearSearch} />
              </InputRightElement>
            )}
          </InputGroup>
          {/* <Menu>
            <MenuButton
              h="45px"
              w="122px"
              border="1px solid rgba(103, 121, 142, 0.15)"
              backgroundColor="rgba(255, 255, 255, 0.5)"
              _hover=""
              borderRadius="2px"
              color="#2A2A2A"
              fontWeight={500}
            >
              Sort by <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              <MenuItem>Download</MenuItem>
              <MenuItem>Create a Copy</MenuItem>
              <MenuItem>Mark as Draft</MenuItem>
              <MenuItem>Delete</MenuItem>
              <MenuItem>Attend a Workshop</MenuItem>
            </MenuList>
          </Menu> */}
        </Flex>
        <Grid gridTemplateColumns='1fr 1fr 1fr 1fr' gridGap='30px'>
          {members.map(member => (
            <MemberCard
              key={member.id}
              member={member}
              openChat={openChat}
              openModal={createRequest}
            />
          ))}
        </Grid>
        <Flex justifyContent='center' alignItems='center' p='20px'>
          {!loading && totalMembers - limit >= 0 && (
            <Text
              textDecoration='underline'
              cursor='pointer'
              onClick={loadMore}
            >
              Load more({totalMembers - limit})
            </Text>
          )}
          {loading && <Spinner />}
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p='30px'>
            <Flex justifyContent='space-between' alignItems='center' mb='30px'>
              <Text fontSize={20} fontWeight={500} color='rgba(0, 0, 0, 0.9)'>
                Send Message Request
              </Text>
              <CloseIcon onClick={closeModal} />
            </Flex>
            <Flex alignItems='center' mb='20px'>
              <Avatar
                name={`${member?.first_name} ${member?.last_name}`}
                boxSize='40px'
                mr='10px'
                // src={member?.avatar}
                src={`${appUrl}/${member?.avatar}`}
              />
              <Box>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color='rgba(0, 0, 0, 0.9)'
                  lineHeight='16px'
                >
                  {`${member?.first_name} ${member?.last_name}`}
                </Text>
                <Text fontSize={14} color='rgba(0, 0, 0, 0.7)'>
                  {member?.current_occupation && member?.current_occupation}
                </Text>
              </Box>
            </Flex>
            <Textarea
              value={requestMsg}
              onChange={e => setRequestMsg(e.target.value)}
              placeholder='Enter message...'
              size='sm'
              resize='none'
              mb='30px'
            />
            <Flex justifyContent='flex-end'>
              <Button
                backgroundColor='transparent'
                border='1px solid #0E62FF'
                h='35px'
                w='101px'
                color='#0E62FF'
                fontWeight={500}
                fontSize={14}
                _hover=''
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                backgroundColor='#0E62FF'
                h='35px'
                w='101px'
                color='#FFFFFF'
                fontWeight={500}
                fontSize={14}
                textTransform='capitalize'
                ml='20px'
                _hover=''
                onClick={sendRequest}
                disabled={!requestMsg}
                isLoading={sendLoading}
              >
                Send
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Members;
