import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const SearchComponent = ({ searchPlaceholder, pageTitle }) => {
  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <Text fontFamily='CamptonLight' fontWeight={500} fontSize={20}>
        {pageTitle}
      </Text>

      {/* <Flex
        alignItems="center"
        width={296}
        backgroundColor="white"
        px={4}
        border="1px solid rgba(103, 121, 142, 0.2)"
        borderRadius={4}
      >
        <SearchIcon color="rgba(0, 0, 0, 0.3)" />
        <Input _focus="" border={0} placeholder={searchPlaceholder} />
      </Flex> */}
    </Flex>
  )
};

export default SearchComponent;
