import React from "react";
import { Box, Flex, Text, Image, Heading } from "@chakra-ui/react";
import knowledge from "../../../assets/knowledge-illustration.svg";
import research from "../../../assets/research-illustration.svg";
import innovation from "../../../assets/innovation-illustration.svg";
import "./styles.scss";

const WhatWeDo = () => {
  const offers = [
    {
      name: "Knowledge Lab",
      icon: knowledge,
      summary:
        "Members have access to relevant and authoritative resources in the field of digital epidemiology including but not limited to a wide range of information on learning and development opportunities and capacity building.",
    },
    {
      name: "Research",
      icon: research,
      summary:
        "This network provides an opportunity for members to lend their expertise and resources in areas in which each has a comparative advantage, to collaborate on and submit proposals for various research projects in digital epidemiology.",
    },
    {
      name: "Innovation",
      icon: innovation,
      summary:
        "The network is dedicated to the creation and promotion of innovative technology, products and solutions in digital epidemiology on the continent. Information on innovation and solutions in the healthcare space.",
    },
  ];
  return (
    <Box id='what-we-do' background='#F6F9FC' paddingY={32}>
      <Box className='container'>
        <Text
          fontFamily='CamptonMedium'
          fontSize={16}
          fontWeight={500}
          marginBottom={5}
          textAlign='center'
        >
          WHAT WE DO
        </Text>
        <Heading
          textAlign='center'
          fontSize={34}
          fontWeight={700}
          marginBottom={5}
          className='gradient-heading'
          fontFamily='CamptonSemiBold'
        >
          Learn. Collaborate. Innovate.
        </Heading>
        <Text
          maxWidth={603}
          textAlign='center'
          width='100%'
          marginX='auto'
          fontSize={18}
          fontWeight={400}
          fontFamily='CamptonLight'
          color='#47484C'
          lineHeight='32px'
        >
          Members have access to a wide range of opportunities such as calls for
          papers, academic scholarships and research funding.
        </Text>

        <Flex justifyContent='space-between' flexWrap='wrap'>
          {offers.map(offer => {
            return (
              <Box
                key={offer.name}
                className='what-we-do-card'
                width={["100%", "100%", "60%", "32%"]}
                padding='2px'
                borderRadius={10}
                marginTop={20}
                marginX={[0, 0, "auto", 0]}
              >
                <Box
                  background='#F6F9FC'
                  paddingX={8}
                  paddingY={10}
                  height='100%'
                  borderRadius={10}
                >
                  <Image src={offer.icon} />
                  <Heading
                    marginY={5}
                    fontFamily='CamptonLight'
                    fontSize={20}
                    lineHeight='25px'
                    fontWeight={700}
                    color='rgba(44, 44, 44, 0.9)'
                  >
                    {offer.name}
                  </Heading>
                  <Text
                    fontFamily='CamptonLight'
                    fontSize={16}
                    lineHeight='28px'
                    fontWeight={400}
                    color='#47484C'
                  >
                    {offer.summary}
                  </Text>
                </Box>
              </Box>
            )
          })}
        </Flex>
      </Box>
    </Box>
  )
};

export default WhatWeDo;
