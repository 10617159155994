import React from "react";
import { Box, Flex, Text, Image, Heading } from "@chakra-ui/react";
import offer1 from "../../assets/offer-1.svg";
import offer2 from "../../assets/offer-2.svg";
import offer3 from "../../assets/offer-3.svg";

const WhatWeOffer = () => {
  let items = [
    {
      name: "Diverse Network",
      illustration: offer1,
      summary:
        "Be part of a multidisciplinary platform of experts in public health, data science, and technology in Africa",
    },
    {
      name: "Unique Engagement",
      illustration: offer2,
      summary:
        "Contribute to identifying and addressing public health needs across the continent.",
    },
    {
      name: "New Opportunities",
      illustration: offer3,
      summary:
        "Participate in the development of capacity, innovation and application of digital epidemiology in Africa.",
    },
  ];
  return (
    <Box id='benefits' background='#F6F9FC' paddingY={32}>
      <Box className='container'>
        <Text
          fontFamily='CamptonMedium'
          fontSize={16}
          fontWeight={500}
          marginBottom={5}
          textAlign='center'
        >
          WHAT WE OFFER
        </Text>
        <Heading
          textAlign='center'
          fontSize={34}
          fontWeight={700}
          marginBottom={5}
          className='gradient-heading'
          fontFamily='CamptonSemiBold'
        >
          Benefits of the Network
        </Heading>
        <Text
          maxWidth={603}
          textAlign='center'
          width='100%'
          marginX='auto'
          fontSize={18}
          fontWeight={400}
          fontFamily='CamptonLight'
          color='#47484C'
          lineHeight='32px'
        >
          Leveraging the experience of experts in public health, data science,
          and technology in Africa.
        </Text>
        <Flex width='100%' flexWrap='wrap' justifyContent='space-evenly'>
          {items.map((item, index) => {
            return (
              <Flex
                // maxWidth="300px"
                marginTop={20}
                width={["100%", "70%", "55%", "28%"]}
                textAlign='center'
                key={index}
                flexDirection='column'
                alignItems='center'
                marginX={[0, 0, "auto", 0]}
              >
                <Image src={item.illustration} width={20} />
                <Heading
                  fontSize={20}
                  fontWeight={700}
                  fontFamily='CamptonLight'
                  color='rgba(44, 44, 44, 0.9)'
                  marginY={3}
                >
                  {item.name}
                </Heading>
                <Text
                  fontSize={16}
                  fontWeight={400}
                  lineHeight='28px'
                  color='#47484C'
                  fontFamily='CamptonLight'
                >
                  {item.summary}
                </Text>
              </Flex>
            )
          })}
        </Flex>
      </Box>
    </Box>
  )
};

export default WhatWeOffer;
