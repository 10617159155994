import React from "react";
// import Routes from './routes/Routes';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import NotFound from "./pages/NotFound";
import LandingPage from "./pages/landingPage";
import LoginPage from "./pages/authPage/LoginPage";
import ForgotPasswordPage from "./pages/authPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/authPage/ResetPasswordPage";
import Signup from "./pages/authPage/SignupPage";
import PersonalInformation from "./pages/authPage/PersonalInformation";
import ReferenceInformation from "./pages/authPage/ReferenceInformation";
import ProfessionalQualifications from "./pages/authPage/ProfessionalQualifications";
import LoginDetails from "./pages/authPage/LoginDetails";
import Complete from "./pages/authPage/Complete";

// dashboard layout
import DashboardLayoutRoute from "./pages/_layouts/dashboardLayout";

/*
DATABANK
- Overview
*/

import Overview from "./pages/dashboard/Databank/Overview";
import OverviewCountries from "./pages/dashboard/Databank/Overview/Countries";
import OverviewDiseases from "./pages/dashboard/Databank/Overview/Diseases";
import OverviewIndicatorsWithDiseases from "./pages/dashboard/Databank/Overview/IndicatorsWithDiseases";
import OverviewIndicators from "./pages/dashboard/Databank/Overview/Indicators";
import OverviewCharts from "./pages/dashboard/Databank/Overview/Charts";

/*
DATABANK
- Internal Data
*/
import Internal from "./pages/dashboard/Databank/Internal/index";
import InternalIndicators from "./pages/dashboard/Databank/Internal/Indicators";
import InternalCharts from "./pages/dashboard/Databank/Internal/charts";

/*
DATABANK
- External Data
*/
import External from "./pages/dashboard/Databank/External/index";
import ExternalIndicators from "./pages/dashboard/Databank/External/indicators";
import ExternalCharts from "./pages/dashboard/Databank/External/charts";

/*
PROFILE
*/
import ProQualifications from "./pages/dashboard/Profile/ProfessionalQualifications";
import ProfilePassword from "./pages/dashboard/Profile/Password";
import PersonalInfo from "./pages/dashboard/Profile/PersonalInformation";

/*
OTHERS (coming soon)
*/
import SharedLearning from "./pages/dashboard/Sharedlearning";
import SharedLearningArticles from "./pages/dashboard/Sharedlearning/articles";
import ReadSharedLearning from "./pages/dashboard/Sharedlearning/read";
import Innovation from "./pages/dashboard/Innovation/";
import ReadInnovation from "./pages/dashboard/Innovation/read";
import Resources from "./pages/dashboard/Resources/";
import Messages from "./pages/dashboard/Messages/Chats";
import Requests from "./pages/dashboard/Messages/Requests";
import Members from "./pages/dashboard/Messages/Members";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./App.scss";
import ScrollToTop from "./components/ScrollToTop";

// little state machine
import littleStore from "./utils/littleStore/index";
import { StateMachineProvider, createStore } from "little-state-machine";
import { DevTool } from "little-state-machine-devtools";
import AdminDownloadPage from "./pages/authPage/AdminUserDownloads";

// create your store
createStore({
  littleStore,
});

// const theme = extendTheme({
//   styles: {
//     global: {
//       h1: {
//         // you can also use responsive styles
//         fontSize: "32px",
//         fontWeight: "bold",
//         lineHeight: "110%",
//         marginBottom: "16px"
//         // letterSpacing: "-2%",
//       },
//       h2: {
//         fontSize: "24px",
//         fontWeight: "bold",
//         lineHeight: "110%",
//         marginBottom: "16px"
//         // letterSpacing: "-1%",
//       },
//       h3: {
//         fontSize: "18px",
//         fontWeight: "bold",
//         lineHeight: "110%",
//         marginBottom: "16px"
//         // letterSpacing: "-1%",
//       },
//       h4: {
//         fontSize: "16px",
//         fontWeight: "bold",
//         lineHeight: "110%",
//         marginBottom: "16px"
//         // letterSpacing: "-1%",
//       },
//       h5: {
//         fontSize: "13px",
//         fontWeight: "bold",
//         lineHeight: "110%",
//         marginBottom: "16px"
//         // letterSpacing: "-1%",
//       },
//       h6: {
//         fontSize: "10px",
//         fontWeight: "bold",
//         lineHeight: "110%",
//         marginBottom: "16px"
//         // letterSpacing: "-1%",
//       },
//       p: {
//         fontSize: "16px",
//         lineHeight: "24px",
//       }
//     }
//   },
// })

function App({ children }) {
  return (
    <Provider store={store}>
      <StateMachineProvider>
        <DevTool />
        <ChakraProvider>
          {children}
          <Router>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/forgot-password" component={ForgotPasswordPage} />
                <Route path="/reset-password" component={ResetPasswordPage} />
                <Route path="/admin/download" component={AdminDownloadPage} />

                <Route exact path="/signup" component={Signup} />
                <Route
                  exact
                  path="/Signup/PersonalInformation"
                  component={PersonalInformation}
                />
                <Route
                  exact
                  path="/Signup/ReferenceInformation"
                  component={ReferenceInformation}
                />
                <Route
                  exact
                  path="/Signup/ProfessionalQualifications"
                  component={ProfessionalQualifications}
                />
                <Route
                  exact
                  path="/Signup/LoginDetails"
                  component={LoginDetails}
                />
                <Route exact path="/Signup/Complete" component={Complete} />

                <Redirect exact from="/databank" to="/databank/overview" />
                <DashboardLayoutRoute
                  exact
                  path="/databank/overview"
                  component={Overview}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/overview/countries"
                  component={OverviewCountries}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/overview/diseases"
                  component={OverviewDiseases}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/overview/IndicatorsWithDiseases"
                  component={OverviewIndicatorsWithDiseases}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/overview/Indicators"
                  component={OverviewIndicators}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/overview/Charts"
                  component={OverviewCharts}
                />

                {/* Internal data */}
                <DashboardLayoutRoute
                  exact
                  path="/databank/internal"
                  component={Internal}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/internal/indicators"
                  component={InternalIndicators}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/internal/charts"
                  component={InternalCharts}
                />

                {/* External Data */}
                <DashboardLayoutRoute
                  exact
                  path="/databank/external"
                  component={External}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/external/indicators"
                  component={ExternalIndicators}
                />
                <DashboardLayoutRoute
                  exact
                  path="/databank/external/charts"
                  component={ExternalCharts}
                />

                <Redirect
                  exact
                  from="/shared-learning"
                  to="/shared-learning/all"
                />
                {/* <DashboardLayoutRoute
                exact
                path="/shared-learning/all"
                component={SharedLearning}
              /> */}
                <DashboardLayoutRoute
                  exact
                  path="/shared-learning/all/:id"
                  component={ReadSharedLearning}
                />
                <DashboardLayoutRoute
                  exact
                  path="/shared-learning/:category"
                  component={SharedLearning}
                />
                <DashboardLayoutRoute
                  exact
                  path="/shared-learning/articles/:id"
                  component={ReadSharedLearning}
                />
                {/* <DashboardLayoutRoute
                exact
                path="/shared-learning/reports"
                component={SharedLearning}
              /> */}
                <DashboardLayoutRoute
                  exact
                  path="/shared-learning/reports/:id"
                  component={ReadSharedLearning}
                />
                {/* <DashboardLayoutRoute
                exact
                path="/shared-learning/interactives"
                component={SharedLearning}
              /> */}
                <DashboardLayoutRoute
                  exact
                  path="/shared-learning/interactives/:id"
                  component={ReadSharedLearning}
                />

                {/* Innovation page */}
                <DashboardLayoutRoute
                  exact
                  path="/innovation"
                  component={Innovation}
                />
                <DashboardLayoutRoute
                  exact
                  path="/innovation/:id"
                  component={ReadInnovation}
                />

                {/* Resources */}
                <DashboardLayoutRoute path="/events" component={Resources} />

                {/* Messages */}

                <Redirect exact from="/messages" to="/messages/chats" />
                <DashboardLayoutRoute
                  path="/messages/chats"
                  exact
                  component={Messages}
                />
                <DashboardLayoutRoute
                  path="/messages/requests"
                  exact
                  component={Requests}
                />
                <DashboardLayoutRoute
                  path="/messages/members"
                  exact
                  component={Members}
                />

                {/* Profile */}
                <Redirect exact from="/profile" to="/profile/personal" />
                <DashboardLayoutRoute
                  path="/profile/personal"
                  exact
                  component={PersonalInfo}
                />
                <DashboardLayoutRoute
                  path="/profile/professional"
                  exact
                  component={ProQualifications}
                />
                <DashboardLayoutRoute
                  path="/profile/password"
                  exact
                  component={ProfilePassword}
                />

                {/* 404 */}
                <Route component={NotFound} />
              </Switch>
            </ScrollToTop>
          </Router>
        </ChakraProvider>
      </StateMachineProvider>
    </Provider>
  );
}

export default App;
