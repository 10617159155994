import React, { useState, useEffect } from "react"
import { Box, Flex, Button, useToast } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import PageHeader from "../../../../components/profile/pageHeader"
import {
  CommonCustomInput,
  CustomDropDown,
} from "../../../../components/inputsComponent";
import axios from "axios";
import { baseUrl } from "../../../../url";
import { updateUserProProfile } from "../../../../redux/actions/user.action";

const ProQualifications = () => {
  const dispatch = useDispatch();
  let toast = useToast();

  const user = useSelector(state => state.user);
  const { userData, isLoading, message, profileUpdateError } = user;

  const [linkedIn, setLinkedIn] = useState([]);
  const [organization, setOrganization] = useState("");
  const [currentPosition, setCurrentPosition] = useState("");
  const [proEmail, setProEmail] = useState("");
  const [expertise, setExpertise] = useState("");

  const [occupations, setOccupations] = useState([]);

  useEffect(() => {
    message &&
      toast({
        title: "Important",
        description: `${message}`,
        status: "success",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    profileUpdateError &&
      toast({
        title: "Error",
        description: `${profileUpdateError}`,
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    return () => {};
  }, [message, profileUpdateError]);

  useEffect(() => {
    const setInitialStates = () => {
      userData && setLinkedIn(userData.linkedin_profile || "");
      userData && setOrganization(userData.organization_name || "");
      userData && setCurrentPosition(userData.current_occupation || "");
      userData && setProEmail(userData.professional_email || "");
      userData && setExpertise(userData.expertise || "");
    };

    setInitialStates();
  }, [userData]);

  useEffect(() => {
    const fetchOccupations = () => {
      axios
        .get(`${baseUrl}/users/filter/dd`)
        .then(response => {
          let extendArr = response.data.data.map(occupation => {
            return { name: occupation };
          });
          setOccupations(extendArr);
        })
        .catch(err => {
          console.log(err.data);
        });
    };
    fetchOccupations();
  }, []);

  const handleSubmit = () => {
    let userData = {
      professional_email: proEmail,
      organization_name: organization,
      current_occupation: currentPosition,
      expertise,
      linkedin_profile: linkedIn,
    };

    if (
      !proEmail ||
      !organization ||
      !currentPosition ||
      !expertise ||
      !linkedIn
    ) {
      toast({
        title: "Important",
        description: `All fields are required.`,
        status: "info",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    dispatch(updateUserProProfile(userData));
  };

  console.log(currentPosition, "currryyyy");
  return (
    <Box className='container'>
      <Box marginTop={10} marginBottom={14}>
        <PageHeader
          title='📄️ Professional Qualifications'
          subtitle='You can view or update your Professional Qualifications.'
        />
      </Box>
      <Box width={443} marginX='auto'>
        <Box marginTop={6}>
          <CommonCustomInput
            handleInput={value => setLinkedIn(value)}
            value={linkedIn}
            width='100%'
            label='LinkedIn Profile'
            inputType='text'
            placeholder='www.linkedin.com/in/John-Doe'
            isDisabled={false}
            labelFontSize={15}
          />
        </Box>
        <Box marginTop={6}>
          <CommonCustomInput
            handleInput={value => setOrganization(value)}
            value={organization}
            width='100%'
            label='Organization Name'
            inputType='text'
            placeholder='Facebook Inc.'
            isDisabled={false}
            labelFontSize={15}
          />
        </Box>
        <Box marginTop={6}>
          <CustomDropDown
            required={false}
            options={occupations}
            handleOption={value => setCurrentPosition(value)}
            value={currentPosition}
            width='100%'
            label='Current Occupation'
            placeholder='Select Occupation'
          />
        </Box>
        <Box marginTop={6}>
          <CommonCustomInput
            handleInput={value => setProEmail(value)}
            value={proEmail}
            width='100%'
            label='Professional Email Address'
            inputType='text'
            placeholder='example@gmail.com'
            isDisabled={false}
            labelFontSize={15}
          />
        </Box>{" "}
        <Box marginTop={6}>
          <CommonCustomInput
            handleInput={value => setExpertise(value)}
            value={expertise}
            width='100%'
            label='Expertise'
            inputType='text'
            placeholder='Research Analysis'
            isDisabled={false}
            labelFontSize={15}
          />
        </Box>
        <Flex justifyContent='center' marginTop={16}>
          <Button
            colorScheme='blue'
            fontFamily='CamptonMedium'
            fontSize={16}
            fontWeight={500}
            paddingX={8}
            paddingY={6}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ProQualifications
