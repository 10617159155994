import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Heading,
  Link,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Nav from "./components/Nav";
import ProgressBar from "./components/PregressBar";
import GoBack from "./components/GoBack";
import { CustomInput, CustomInputPassword } from "./components/CustomInputs";
import { useHistory } from "react-router-dom";
import FormButton from "./components/FormButton";
import PasswordStrengthBar from "react-password-strength-bar";
import { updateStore } from "../../utils/littleStore/action";
import { useStateMachine } from "little-state-machine";
import axios from "axios";
import { baseUrl } from "../../url";

const LoginDetails = () => {
  const {
    action,
    state: {
      littleStore: {
        first_name,
        last_name,
        phone,
        country,
        city,
        join_reason,
        referee_1_name,
        referee_1_email,
        hear_about_us,
        resume_file,
        linkedin_profile,
        organization_name,
        current_occupation,
        professional_email,
        expertise,
        login_email,
        password,
      },
    },
  } = useStateMachine(updateStore);

  let toast = useToast();
  let history = useHistory();

  const [isLoading, setisLoading] = useState(false);

  const handleSubmit = () => {
    setisLoading(true);
    if (!login_email || !password) {
      setisLoading(false);
      return toast({
        title: "Complete the form",
        description: "Please complete the form to continue",
        status: "error",
        duration: 4000,
        position: "top",
        isClosable: true,
      });
    }
    axios
      .post(`${baseUrl}/register`, {
        first_name,
        last_name,
        phone,
        country,
        city,
        join_reason,
        referee_1_name,
        referee_1_email,
        hear_about_us,
        resume_file,
        linkedin_profile,
        organization_name,
        current_occupation,
        professional_email,
        expertise,
        login_email,
        password,
      })
      .then((response) => {
        console.log(response);
        setisLoading(false);
        history.push("/Signup/Complete");
        action({
          first_name: "",
          last_name: "",
          phone: "",
          country: "",
          city: "",
          join_reason: "",
          referee_1_name: "",
          referee_1_email: "",
          hear_about_us: "",
          resume_file: "",
          linkedin_profile: "",
          organization_name: "",
          current_occupation: "",
          professional_email: "",
          expertise: "",
          login_email: "",
          password: "",
        });
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
        // if (error.response) {
        //   toast({
        //     title: `${error.response.data.data.messages[0]["message"]}`,
        //     description: ``,
        //     status: "error",
        //     duration: 4000,
        //     isClosable: true,
        //     position: "top",
        //   });
        // }
      });
  };

  return (
    <Box paddingBottom={20}>
      <Box
        paddingY={5}
        position={["relative", "sticky"]}
        top={0}
        width='100%'
        backgroundColor='#F3F7FF'
        border='1px solid rgba(103, 121, 142, 0.1)'
        zIndex={2}
      >
        <Nav />
        <Flex
          maxWidth='400px'
          marginX='auto'
          width='90%'
          position='relative'
          top={["0", "-30px"]}
          flexDirection='column'
          alignItems='center'
          paddingTop={[10, 5, 0]}
          // paddingBottom={[15, 5, 0]}
        >
          <ProgressBar progress={95} />
          <Heading
            fontFamily='Gordita'
            fontSize={24}
            fontWeight={700}
            color='#1D1E22'
            marginTop={10}
            marginBottom={3}
          >
            Login Details
          </Heading>
          <Text
            fontFamily='CamptonLight'
            fontSize={16}
            fontWeight={500}
            color='#1D1E22'
            textAlign='center'
            lineHeight='25px'
          >
            The following are requirements for the general public to join the
            <b>Digital Epidemiology Network.</b>
          </Text>
        </Flex>
      </Box>
      {/* body */}
      <Flex
        flexDirection={["column", "column", "row"]}
        maxWidth='1037px'
        width='100%'
        marginX='auto'
        marginY={10}
        justifyContent='space-between'
      >
        <Box
          marginLeft={[5, 10, 18, 0]}
          width={["90%", "20%"]}
          marginBottom={10}
        >
          <GoBack />
        </Box>
        <Box maxW={443} width={["90%", "90%", "60%"]} marginX='auto'>
          <Stack spacing={[4, 8]}>
            <Box>
              <CustomInput
                required={true}
                handleInput={value => action({ login_email: value })}
                value={login_email}
                width='100%'
                label='Email address'
                inputType='email'
                placeholder='Enter your email address'
              />
            </Box>
            <Box>
              <CustomInputPassword
                required={true}
                handleInput={value => action({ password: value })}
                value={password}
                width='100%'
                label='Password'
                placeholder='*********'
                inputType='password'
              />
              <PasswordStrengthBar password={password} />
            </Box>
            <Flex alignItems='baseline'>
              <input type='checkbox' />
              <Text
                fontSize={16}
                fontWeight={500}
                fontFamily='CamptonLight'
                color='#606164'
                lineHeight='26px'
                marginLeft={3}
              >
                Check this box to indicate that you have read and agreed with
                our{" "}
                <Link href='/' style={{ color: "#2A81FF" }}>
                  Terms of Consent
                </Link>
              </Text>
            </Flex>
          </Stack>
        </Box>
        <Box width='20%'></Box>
      </Flex>
      <Flex justifyContent='center' marginTop={20}>
        <FormButton
          label='Register'
          handleButtonAction={handleSubmit}
          loading={isLoading}
          icon=''
        />
      </Flex>
    </Box>
  )
};

export default LoginDetails;
