import React from "react";
import { Box, Flex, Text, Heading, Stack } from "@chakra-ui/react";
import Nav from "./components/Nav";
import ProgressBar from "./components/PregressBar";
import GoBack from "./components/GoBack";
import { CustomDropDown, CustomInput } from "./components/CustomInputs";
import { useHistory } from "react-router-dom";
import FormButton from "./components/FormButton";
import ArrowRight from "../../assets/arrow-right.svg";
import { updateStore } from "../../utils/littleStore/action";
import { useStateMachine } from "little-state-machine";

const ReferenceInformation = () => {
  const {
    action,
    state: {
      littleStore: { referee_1_name, referee_1_email, hear_about_us },
    },
  } = useStateMachine(updateStore);

  let history = useHistory();

  const handleSubmit = () => {
    history.push("/Signup/ProfessionalQualifications");
  };

  return (
    <Box paddingBottom={20}>
      <Box
        paddingY={5}
        position={["relative", "sticky"]}
        top={0}
        width='100%'
        backgroundColor='#F3F7FF'
        border='1px solid rgba(103, 121, 142, 0.1)'
        zIndex={2}
      >
        <Nav showLoginButton={true} />
        <Flex
          maxWidth='400px'
          marginX='auto'
          width='90%'
          position='relative'
          top={["0", "-30px"]}
          flexDirection='column'
          alignItems='center'
          paddingTop={[10, 5, 0]}
          // paddingBottom={[15, 5, 0]}
        >
          <ProgressBar progress={35} />
          <Heading
            fontFamily='Gordita'
            fontSize={24}
            fontWeight={700}
            color='#1D1E22'
            marginTop={10}
            marginBottom={3}
          >
            Reference Information
          </Heading>
          <Text
            fontFamily='CamptonLight'
            fontSize={16}
            fontWeight={500}
            color='#1D1E22'
            textAlign='center'
            lineHeight='25px'
          >
            The following are requirements for the general public to join the
            <b>Digital Epidemiology Network.</b>
          </Text>
        </Flex>
      </Box>
      {/* body */}
      <Flex
        flexDirection={["column", "column", "row"]}
        maxWidth='1037px'
        width='100%'
        marginX='auto'
        marginY={10}
        justifyContent='space-between'
      >
        <Box
          marginLeft={[5, 10, 18, 0]}
          width={["90%", "20%"]}
          marginBottom={10}
        >
          <GoBack />
        </Box>
        <Box maxW={443} width={["90%", "90%", "60%"]} marginX='auto'>
          <Stack spacing={[4, 8]}>
            {/* reference 1 */}
            <Box>
              <CustomInput
                handleInput={value => action({ referee_1_name: value })}
                value={referee_1_name}
                width='100%'
                label='Name of Referee'
                inputType='text'
                placeholder='Full name of referee'
              />
            </Box>
            <Box>
              <CustomInput
                handleInput={value => action({ referee_1_email: value })}
                value={referee_1_email}
                width='100%'
                label='Email Address of Referee'
                placeholder='Enter email address'
                inputType='email'
              />
            </Box>
            <Box>
              <CustomDropDown
                required={true}
                options={[
                  { name: "Twitter" },
                  { name: "Newsletter" },
                  { name: "Colleague" },
                  { name: "Other" },
                ]}
                handleOption={value => action({ hear_about_us: value })}
                value={hear_about_us}
                width='100%'
                label='How You Hear About the Network'
                placeholder='Select Option'
              />
            </Box>
          </Stack>
        </Box>
        <Box width='20%'></Box>
      </Flex>
      <Flex justifyContent='center' marginTop={18}>
        <FormButton
          handleButtonAction={handleSubmit}
          label='Continue'
          icon={ArrowRight}
        />
      </Flex>
    </Box>
  )
};

export default ReferenceInformation;
