import React from "react";
import {
  Box,
  Text,
  Flex,
  Avatar,
  Textarea,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import axios from 'axios';
import { baseUrl } from '../../../url';
import { CommentIcon, LikeIcon } from "../../../components/Icons";
import moment from 'moment';

const CommentInput = ({ type = "comment", close, addComment, setComment, comment, loading, commentOwner }) => {
  return (
    <Box
      bg="white"
      border="1px solid rgba(29, 30, 34, 0.1)"
      borderRadius="10px"
      boxShadow="0px 1px 24px rgba(0, 0, 0, 0.1)"
      p="24px"
      pb="16px"
      pr="16px"
    >
      <Flex alignItems="center" mb="33px">
        <Avatar src="" name="Tope Agba" boxSize="41px" mr="20px" />
        <Text color="#050038" fontSize="15px" fontWeight={600} mb="6px">
          Tope Agba
        </Text>
      </Flex>
      <Textarea
        placeholder={
          type === "comment"
            ? "Share your thoughts"
            : `Replying to ${commentOwner}`
        }
        size="sm"
        fontSize="15px"
        p="0"
        color="#1D1E22"
        resize="none"
        border="none"
        mb="20px"
        _focus={{
          outline: "none",
          boxShadow: "none",
        }}
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />
      <Flex alignItems="center" justifyContent="flex-end">
        <Text
          color="#0E62FF"
          fontSize="15px"
          fontWeight={500}
          cursor="pointer"
          mr="45px"
          onClick={close}
        >
          Cancel
        </Text>
        <Button
          h="45px"
          w="146px"
          bg="#0E62FF"
          color="white"
          _hover={{
            bg: "#0E62FF",
          }}
          onClick={addComment}
          isLoading={loading}
          disabled={!comment}
        >
          Post
        </Button>
      </Flex>
    </Box>
  );
};

const CommentItem = ({ commentData, articleId, likeComment }) => {
  const token = localStorage.getItem("bearerToken");

  const [showReplies, setShowReplies] = React.useState(false);
  const [showReplyInput, setShowReplyInput] = React.useState(false);
  const [reply, setReply] = React.useState('');
  const [addLoading, setAddLoading] = React.useState(false);

  const [replies, setReplies] = React.useState([]);
  const [loadingReplies, setLoadingReplies] = React.useState(false);

  const { user, comment, created_at, reply_count, like_count, liked } = commentData;

  const replyComment = () => {
    if (reply) {
      setAddLoading(true);
      axios.post(`${baseUrl}/post/comments/replies`,
        {
          post_id: articleId,
          reply_comment_id: commentData.id,
          comment: reply,
        }, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      })
        .then(res => {
          console.log(res)
          setAddLoading(false);
          // getComments();
          getReplies();
          setReply('');
        })
        .catch(e => {
          console.log(e);
          setAddLoading(false);
        })
    }
  }

  React.useEffect(() => {
    if (showReplies) {
      getReplies();
    }
  }, [showReplies])

  const getReplies = () => {
    setLoadingReplies(true);
    axios.get(`${baseUrl}/post/comments/replies/${commentData.id}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
      .then(res => {
        console.log('replies', res.data.data);
        setLoadingReplies(false);
        setReplies(res.data.data);
      })
      .catch(e => {
        console.log(e);
        setLoadingReplies(false);
      })
  }

  return (
    <Flex borderTop="1px solid rgba(130, 127, 152, 0.1)" py="40px">
      <Avatar src={`${baseUrl.replace('api/v1', '')}${user.avatar}`} name="Tope Agba" boxSize="41px" mr="20px" />
      <Box flexGrow={1}>
        <Text color="#050038" fontSize="15px" fontWeight={600} mb="6px">
          {user.first_name} {user.last_name}
        </Text>
        <Text color="#050038" fontSize="14px" opacity="0.6" mb="20px">
          {moment(created_at).fromNow()}
        </Text>
        <Text color="#252733" fontSize="18px" lineHeight="35px">
          {comment}
        </Text>
        <Flex alignItems="center" color="#050038" fontSize="15px" mt="26px">
          <Box as="span" onClick={() => likeComment(commentData.id)}>
            <LikeIcon stroke={liked ? 'red' : "rgba(5, 0, 56, 0.6)"} fill={liked ? 'blue' : 'blue'} />
          </Box>
          <Text ml="10px" opacity="0.6" mr="25px">
            {Boolean(like_count) ? like_count : ''}
          </Text>
          <CommentIcon width="18" height="18" stroke="rgba(5, 0, 56, 0.6)" />
          {
            reply_count > 0 &&
            <Text ml="10px" opacity="0.6" cursor="pointer" onClick={() => setShowReplies(!showReplies)}>
              {showReplies ? 'Hide Replies' : `${reply_count} repl${reply_count === 1 ? 'y' : 'ies'}`}
            </Text>
          }
          <Text ml="auto" cursor="pointer" opacity="0.6" onClick={() => setShowReplyInput(true)}>
            Reply
          </Text>
        </Flex>
        <Box
          pl="23px"
          ml="7px"
          py="10px"
          mt="10px"
          mb="-20px"
          borderLeft={showReplies || showReplyInput ? "4px solid #C4C4C4" : 'none'}
        >
          {
            showReplies && (
              <>
                {replies.length === 0 && loadingReplies && <Text>Loading...</Text>}
                {replies.map(reply => {
                  const { users: user, comment, created_at, like_count } = reply;
                  return (
                    <Box flexGrow={1} key={reply.id} mb="30px">
                      <Flex mb="20px">
                        <Avatar src={`${baseUrl.replace('api/v1', '')}${user.avatar}`} name="Tope Agba" boxSize="41px" mr="20px" />
                        <Box>
                          <Text color="#050038" fontSize="15px" fontWeight={600} mb="6px">
                            {user.first_name} {user.last_name}
                          </Text>
                          <Text color="#050038" fontSize="14px" opacity="0.6">
                            {moment(created_at).fromNow()}
                          </Text>
                        </Box>
                      </Flex>
                      <Text color="#252733" fontSize="18px" lineHeight="35px">
                        {comment}
                      </Text>
                      <Flex alignItems="center" color="#050038" fontSize="15px" mt="26px">
                        <LikeIcon stroke="rgba(5, 0, 56, 0.6)" />
                        <Text ml="10px" opacity="0.6" mr="25px">
                          {Boolean(like_count) ? like_count : ''}
                        </Text>
                        {/* <Text ml="auto" cursor="pointer" opacity="0.6" onClick={() => setShowReplyInput(true)}>
                          Reply
                        </Text> */}
                      </Flex>
                    </Box>
                  )
                })}
              </>
            )
          }
          {showReplyInput && (
            <CommentInput
              type="reply"
              close={() => setShowReplyInput(false)}
              addComment={replyComment}
              setComment={setReply}
              comment={reply}
              loading={addLoading}
              commentOwner={user.first_name + ' ' + user.last_name} />
          )}
        </Box>
      </Box>
    </Flex>
  )
}

const Comments = ({ articleId }) => {
  const token = localStorage.getItem("bearerToken");

  const [comment, setComment] = React.useState('');
  const [comments, setComments] = React.useState([]);
  const [addLoading, setAddLoading] = React.useState(false);
  const [replyCommentId, setReplyCommentId] = React.useState(null);
  const [repliesForId, setRepliesForId] = React.useState(null);

  const {
    isOpen: commentInputVisible,
    onOpen: showCommentInput,
    onClose: hideCommentInput,
  } = useDisclosure();

  // const {
  //   isOpen: replyInputVisible,
  //   onOpen: showReplyInput,
  //   onClose: hideReplyInput,
  // } = useDisclosure();

  React.useEffect(() => {
    getComments();
  }, [])

  const getComments = () => {
    axios.get(`${baseUrl}/post/comments/${articleId}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
      .then(res => {
        console.log(res.data.data);
        setComments(res.data.data)
      })
      .catch(e => console.log(e))
  }

  const likeComment = (id) => {
    axios.patch(`${baseUrl}/post/comments/like/`, {
      post_comment_id: id,
    }, {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
      .then(res => {
        console.log(res)
        getComments();
      })
      .catch(e => {
        console.log(e);
        setAddLoading(false);
      })
  }

  const addComment = () => {
    if (comment) {
      setAddLoading(true);
      axios.post(`${baseUrl}/post/comments`,
        {
          post_id: articleId,
          comment
        }, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      })
        .then(res => {
          console.log(res)
          setAddLoading(false);
          getComments();
          setComment('');
          hideCommentInput();
        })
        .catch(e => {
          console.log(e);
          setAddLoading(false);
        })
    }
  }

  return (
    <Box>
      <Flex alignItems="center" mb="16px">
        <CommentIcon />
        <Text ml="10px" fontWeight={500} fontSize="18px" color="#1D1E22">
          Comments {comments.length}
        </Text>
      </Flex>
      <Box mb="56px">
        {commentInputVisible ? (
          <CommentInput
            close={hideCommentInput}
            addComment={addComment}
            setComment={setComment}
            comment={comment}
            laoding={addLoading}
          />
        ) : (
          <Box
            bg="white"
            border="1px solid rgba(29, 30, 34, 0.1)"
            borderRadius="10px"
            boxShadow="0px 1px 24px rgba(0, 0, 0, 0.1)"
            p="24px"
            fontSize="15px"
            color="#1D1E22"
            opacity="0.6"
            cursor="pointer"
            onClick={showCommentInput}
          >
            Share your thoughts
          </Box>
        )}
      </Box>
      {
        comments.map(item => {
          return (
            <CommentItem
              commentData={item}
              articleId={articleId}
              key={item.id}
              likeComment={likeComment} />
          )
        })
      }
      {/* <Flex
        justifyContent="center"
        borderTop="1px solid rgba(130, 127, 152, 0.1)"
        pt="24px"
      >
        <Text cursor="pointer" color="#050038" opacity="0.6">
          See more Comments
        </Text>
      </Flex> */}
    </Box>
  );
};

export default Comments;
