import React from "react";
import { Box, Flex, Text, Heading, Button, Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Nav from "./components/Nav";
import PersonalInformation from "../../assets/personalInformation.svg";
import EducationalBackground from "../../assets/educationalBackground.svg";
import ProfessionalQualifications from "../../assets/professionalQualifications.svg";
import LoginDetails from "../../assets/loginDetails.svg";

const Signup = () => {
  let history = useHistory();
  return (
    <Box backgroundColor='#F3F7FF' height='100vh'>
      <Box paddingY={5}>
        <Nav showLoginButton={true} />
      </Box>

      <Box
        maxW='680px'
        width='94%'
        paddingX={[2, 10]}
        paddingY={[16]}
        marginX='auto'
        borderRadius={20}
        position='relative'
        top={[8, 20]}
        border='1px solid'
        background={["transparent", "transparent", "white"]}
        borderColor={["transparent", "transparent", "rgba(103, 121, 142, 0.2)"]}
      >
        <Heading
          fontFamily='Gordita'
          fontSize={24}
          fontWeight={700}
          color='#1D1E22'
          marginBottom={3}
          textAlign='center'
        >
          Become a Member
        </Heading>
        <Text
          maxW='400px'
          width='100%'
          marginX='auto'
          fontFamily='CamptonLight'
          fontSize={16}
          fontWeight={500}
          color='#1D1E22'
          textAlign='center'
          lineHeight='25px'
        >
          The following are requirements for the general public to join the{" "}
          <b>Digital Epidemiology Network.</b>
        </Text>

        <Flex
          flexDirection={["column", "row"]}
          alignItems='flex-start'
          maxWidth={["300px", "100%"]}
          marginX='auto'
          width='100%'
          marginY={16}
        >
          <Flex
            flexDirection={["row", "column"]}
            alignItems='center'
            justifyContent='center'
            w={["auto", "50%", "25%"]}
            margin={4}
            textAlign='center'
            marginBottom={[5, 0]}
          >
            <Image src={PersonalInformation} height={38} />
            <Text
              marginTop={2}
              fontFamily='CamptonLight'
              color='#1D1E22'
              fontWeight={400}
              textAlign='center'
              lineHeight='19px'
            >
              Personal Information
            </Text>
          </Flex>
          <Flex
            flexDirection={["row", "column"]}
            alignItems='center'
            justifyContent='center'
            w={["auto", "50%", "25%"]}
            margin={4}
            textAlign='center'
            marginBottom={[5, 0]}
          >
            <Image src={EducationalBackground} height={38} />
            <Text
              marginTop={2}
              fontFamily='CamptonLight'
              color='#1D1E22'
              fontWeight={400}
              textAlign='center'
              lineHeight='19px'
            >
              Reference Information
            </Text>
          </Flex>{" "}
          <Flex
            flexDirection={["row", "column"]}
            alignItems='center'
            justifyContent='center'
            w={["auto", "50%", "25%"]}
            margin={4}
            textAlign='center'
            marginBottom={[5, 0]}
          >
            <Image src={ProfessionalQualifications} height={38} />
            <Text
              marginTop={2}
              fontFamily='CamptonLight'
              color='#1D1E22'
              fontWeight={400}
              textAlign='center'
              lineHeight='19px'
            >
              Professional Qualifications
            </Text>
          </Flex>{" "}
          <Flex
            flexDirection={["row", "column"]}
            alignItems='center'
            justifyContent='center'
            w={["auto", "50%", "25%"]}
            margin={4}
            textAlign='center'
            marginBottom={[5, 0]}
          >
            <Image src={LoginDetails} height={38} />
            <Text
              marginTop={2}
              fontFamily='CamptonLight'
              color='#1D1E22'
              fontWeight={400}
              textAlign='center'
              lineHeight='19px'
            >
              Login Details
            </Text>
          </Flex>
        </Flex>

        <Flex justifyContent='center'>
          <Button
            onClick={() => history.push("/Signup/PersonalInformation")}
            _hover=''
            background='#2A81FF'
            size='lg'
            width={204}
            color='white'
            fontFamily='Gordita'
            fontWeight={500}
            fontSize={14}
          >
            Get Started
          </Button>
        </Flex>
      </Box>
    </Box>
  )
};

export default Signup;
