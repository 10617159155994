import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  useToast,
  Link,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Nav from "./components/Nav";
import axios from "axios";
import { baseUrl } from "../../url";
import { CustomInput, CustomInputPassword } from "./components/CustomInputs";
import FormButton from "./components/FormButton";
import { getUserProfile } from "../../redux/actions/user.action";
import * as FileDownload from "js-file-download";

const AdminDownloadPage = () => {
  let history = useHistory();
  let toast = useToast();

  const [login_email, setlogin_email] = useState("");
  const [password, setpassword] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const fireToast = (title = "Error!", description, status = "error") => {
    toast({
      position: "top",
      title,
      description,
      status,
      duration: 9000,
      isClosable: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!login_email || !password) {
      fireToast("Important", "Email and password required.", "info");
    } else {
      setisLoading(true);
      axios
        .post(`${baseUrl}/login`, {
          login_email,
          password,
        })
        .then(async (response) => {
          setisLoading(false);
          // save token
          // window.localStorage.setItem("bearerToken", response.data.data.token);

          axios
            .get(`${baseUrl}/users/download?token=${response.data.data.token}`)
            .then((res) => FileDownload(res.data, "users.csv"));

          history.push("/");
        })
        .catch((error) => {
          setisLoading(false);
          if (error.response) {
            fireToast(undefined, "Invalid credentials.", undefined);
            setpassword("");
          } else {
            fireToast(
              undefined,
              "An error occured, please try again.",
              undefined
            );
          }
        });
    }
  };

  return (
    <Box backgroundColor="#F3F7FF" height="100vh">
      <Box paddingY={5}>
        <Nav showSignupButton={true} />
      </Box>

      <Box
        maxW="603px"
        width="94%"
        paddingX={[2, 20]}
        paddingY={[16]}
        marginX="auto"
        borderRadius={20}
        position="relative"
        top={[8, 20]}
        border="1px solid"
        background={["transparent", "transparent", "white"]}
        borderColor={["transparent", "transparent", "rgba(103, 121, 142, 0.2)"]}
      >
        <Box marginBottom={10}>
          <Heading
            fontFamily="Gordita"
            fontSize={24}
            fontWeight={700}
            color="#1D1E22"
            marginBottom={3}
            textAlign="center"
          >
            Welcome!
          </Heading>
          <Text
            maxW="400px"
            width="100%"
            marginX="auto"
            fontFamily="CamptonLight"
            fontSize={16}
            fontWeight={500}
            color="#1D1E22"
            textAlign="center"
            lineHeight="25px"
          >
            Sign in as ADMIN to download user data.
          </Text>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={[4, 5]}>
            <Box>
              <CustomInput
                required={true}
                handleInput={(value) => setlogin_email(value)}
                value={login_email}
                width="100%"
                label="Email address"
                inputType="email"
                placeholder="Enter your email address"
              />
            </Box>
            <Box>
              <CustomInputPassword
                required={true}
                handleInput={(value) => setpassword(value)}
                value={password}
                width="100%"
                label="Password"
                placeholder="*********"
                inputType="password"
              />
            </Box>
            <Flex justifyContent="center" marginTop={0}>
              <FormButton label="Download" loading={isLoading} icon="" />
            </Flex>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default AdminDownloadPage;
