import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Select,
  HStack,
  Input,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import axios from "axios";
import { baseUrl } from "../../../../url";
import IndicatorList from "../../components/IndicatorList";
import { MagnifyIcon } from "../../../../components/Icons";
import { useLocation, useHistory } from "react-router-dom";
import { queryHelpers } from "@testing-library/react";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Indicators = () => {
  let query = useQuery();
  let history = useHistory();
  const [diseases, setdiseases] = useState([]);
  const [selectedDisease, setselectedDisease] = useState({});
  const [indicators, setindicators] = useState([]);
  const [indicatorsCopy, setindicatorsCopy] = useState([]);
  const [primaryExists, setPrimaryExists] = useState(true);

  useEffect(() => {
    // get all diseases
    axios
      .get(
        `${baseUrl}/disease/all?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setdiseases(response.data.data);
        // set active tab

        const viewingDisease = response.data.data.find((disease) => {
          return disease.name === query.get("disease");
        });
        setselectedDisease(viewingDisease);
        getDiseaseDetail(viewingDisease["id"]);
      });
  }, []);

  const getDiseaseDetail = (id) => {
    axios
      .get(
        `${baseUrl}/disease/${id}?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setselectedDisease(response.data.data);
      });
    // indicators
    axios
      .get(
        `${baseUrl}/indicator/disease/${id}?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setindicators(response.data.data);
        setindicatorsCopy(response.data.data);
      });
  };

  const handleSearch = (searchWord) => {
    let filteredIndicators = indicatorsCopy.filter((indicator) => {
      return indicator.name
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(searchWord.replace(/\s/g, "").toLowerCase());
    });
    setindicators(filteredIndicators);
  };

  const handleSorting = (option) => {
    if (option === "a-z") {
      let sorted = indicators.sort((a, b) =>
        a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
      );
      setPrimaryExists((x) => !x);
      setindicators(sorted);
    }

    if (option === "z-a") {
      let sorted = indicators
        .sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0))
        .reverse();
      setPrimaryExists((x) => !x);
      // setindicators(sort);
      setindicators(sorted);
    }
  };

  return (
    <Box className='container'>
      <Breadcrumb
        marginTop={10}
        spacing='8px'
        fontSize='14px'
        separator={<ChevronRightIcon color='gray.500' />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink color='#2A81FF' href='/databank/overview'>
            Databank
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>Indicators</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justifyContent='space-between' marginY={10}>
        <Text fontWeight={700} fontSize={18} fontFamily='CamptonMedium'>
          All Indicators
        </Text>
        <HStack
          background='white'
          border='1px solid rgba(103, 121, 142, 0.12)'
          borderRadius={2}
          height='45px'
          paddingLeft={4}
        >
          <MagnifyIcon />
          <Input
            onChange={e => handleSearch(e.target.value)}
            _focus={{ outline: 0 }}
            placeholder='Search for Indicators'
            border={0}
            borderRadius={0}
          />
        </HStack>
      </Flex>

      <Flex justifyContent='space-between'>
        <Box width='30%'>
          <Box
            background='white'
            border='1px solid rgba(103, 121, 142, 0.15)'
            padding='20px 0 20px 0px'
          >
            {diseases.map(disease => (
              <>
                {disease.indicators.length > 0 && (
                  <Flex
                    justifyContent='space-between'
                    alignItems='center'
                    onClick={() => getDiseaseDetail(disease.id)}
                    cursor='pointer'
                    _hover={{ background: "rgba(191,227,245,0.4)" }}
                    paddingLeft='40px'
                    paddingY={5}
                  >
                    <Text
                      color={
                        disease.id === selectedDisease.id ? "#0259D7" : "#000"
                      }
                      fontFamily='CamptonLight'
                    >
                      {disease.name}
                    </Text>
                    {disease.id === selectedDisease.id && (
                      <svg
                        width='8'
                        height='20'
                        viewBox='0 0 8 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M0.499161 10.6244C0.207286 10.2593 0.207287 9.7407 0.499161 9.37559L7.99408 0L7.99408 20L0.499161 10.6244Z'
                          fill='#0259D7'
                        />
                      </svg>
                    )}
                  </Flex>
                )}
              </>
            ))}
          </Box>
        </Box>
        <Box
          width='65%'
          border='1px solid rgba(103, 121, 142, 0.15)'
          background='white'
          padding={14}
        >
          <Flex justifyContent='space-between'>
            <Text fontWeight={700} fontSize={18} fontFamily='CamptonMedium'>
              {selectedDisease.name}
            </Text>
            <Select
              placeholder='Sort by'
              width='108px'
              borderRadius={2}
              border='1px solid rgba(103, 121, 142, 0.15)'
              borderColor='gray'
              onChange={e => handleSorting(e.target.value)}
            >
              <option value='a-z'>A to Z</option>
              <option value='z-a'>Z to A</option>
            </Select>
          </Flex>
          <Box marginTop={10} key={String(primaryExists)}>
            {indicators.map((indicator, index) => {
              return (
                <Flex
                  borderBottom={
                    index === indicators.length - 1
                      ? "none"
                      : "1px solid #F0F0F0"
                  }
                  paddingY={5}
                  justifyContent='space-between'
                  alignItems='center'
                  key={index}
                  cursor='pointer'
                  onClick={() =>
                    history.push(
                      `/databank/internal/charts?diseaseId=${selectedDisease.id}&indicatorPathname=${indicator.path_name}&diseaseName=${selectedDisease.name}`
                    )
                  }
                >
                  <Text fontFamily='CamptonLight' fontSize={16} fontWeight={400}>
                    {indicator.name}
                  </Text>
                  <svg
                    width='10'
                    height='10'
                    viewBox='0 0 10 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8.00305 3.0605L1.5478 9.51575L0.487305 8.45525L6.9418 2H1.25305V0.5H9.50305V8.75H8.00305V3.0605Z'
                      fill='#2A81FF'
                    />
                  </svg>
                </Flex>
              )
            })}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
};

export default Indicators;
