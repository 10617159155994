import React, { useState } from "react";
import { Box, Flex, Heading, Text, Stack, useToast } from "@chakra-ui/react";
import axios from "axios"
import Nav from "./components/Nav"
import { baseUrl } from "../../url"
import { CustomInput } from "./components/CustomInputs"
import FormButton from "./components/FormButton"

const ForgotPassword = () => {
  let toast = useToast()

  const [login_email, setlogin_email] = useState("")
  const [isLoading, setisLoading] = useState("")

  const handleSubmit = () => {
    setisLoading(true)
    if (!login_email) {
      toast({
        title: "Important",
        description: "Please enter email",
        status: "info",
        position: "top",
        duration: 9000,
        isClosable: true,
      })
      setisLoading(false)
    } else {
      axios
        .post(`${baseUrl}/password-recovery`, {
          login_email,
        })
        .then(response => {
          toast({
            title: "Important",
            description: `${response.data.message}`,
            status: "success",
            position: "top",
            duration: 9000,
            isClosable: true,
          })
          setisLoading(false)
        })
        .catch(err => {
          setisLoading(false)
          toast({
            title: "Important",
            description: `An error has occured, check email and try again.`,
            status: "error",
            position: "top",
            duration: 5000,
            isClosable: true,
          })
        })
    }
  }

  return (
    <Box backgroundColor='#F3F7FF' height='100vh'>
      <Box paddingY={5}>
        <Nav showSignupButton={true} />
      </Box>

      <Box
        maxW='603px'
        width='94%'
        paddingX={[2, 20]}
        paddingY={[16]}
        marginX='auto'
        borderRadius={20}
        position='relative'
        top={[8, 20]}
        border='1px solid'
        background={["transparent", "transparent", "white"]}
        borderColor={["transparent", "transparent", "rgba(103, 121, 142, 0.2)"]}
      >
        <Box marginBottom={10}>
          <Heading
            fontFamily='Gordita'
            fontSize={24}
            fontWeight={700}
            color='#1D1E22'
            marginBottom={3}
            textAlign='center'
          >
            Reset password
          </Heading>
          <Text
            maxW='400px'
            width='100%'
            marginX='auto'
            fontFamily='CamptonLight'
            fontSize={16}
            fontWeight={500}
            color='#1D1E22'
            textAlign='center'
            lineHeight='25px'
          >
            Enter your email to reset your password
          </Text>
        </Box>
        <Stack spacing={[4, 10]}>
          <Box>
            <CustomInput
              required={true}
              handleInput={value => setlogin_email(value)}
              value={login_email}
              width='100%'
              label='Email address'
              inputType='email'
              placeholder='Enter your email address'
            />
          </Box>

          <Flex justifyContent='center' marginTop={0}>
            <FormButton
              label='Reset Password'
              handleButtonAction={handleSubmit}
              loading={isLoading}
              icon=''
            />
          </Flex>
        </Stack>
      </Box>
    </Box>
  )
}

export default ForgotPassword;
