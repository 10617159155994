import React from "react";
import {
  Box,
  Text,
  Grid,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Flex,
  Avatar,
  Button,
  Spinner,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalBody,
  // ModalCloseButton,
  // useDisclosure,
  Circle,
} from "@chakra-ui/react";
import moment from "moment";
import axios from "axios";
import { SearchIcon, SmallCloseIcon } from "@chakra-ui/icons";
// import { MoreIcon } from "../../../components/Icons";
import { useState } from "react";
import { baseUrl } from "../../../url";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { compareObjects } from "../../../utils/helper";

const appUrl = process.env.REACT_APP_URL

const Messages = () => {
  const location = useLocation()
  const history = useHistory()

  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [modalType, setModalType] = useState("");
  const messagesEndRef = useRef(null)
  // const messageBoxRef = useRef(null);

  const [currentUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [chatId, setChatId] = useState(null)
  const [chatList, setChatList] = useState(null)
  const [chat, setChat] = useState([])
  const chatRef = useRef()
  chatRef.current = chat
  const [chatUser, setChatUser] = useState(null)

  const [text, setText] = useState("")
  const [search, setSearch] = useState("")
  const [searchResult, setSearchResult] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)

  const [members, setMembers] = useState([])
  const [totalMembers, setTotalMembers] = useState(0)

  // const openModal = (type) => {
  //   setModalType(type);
  //   onOpen();
  // };

  // const closeModal = () => {
  //   setModalType("");
  //   onClose();
  // };

  useEffect(() => {
    const chatListPoll = setInterval(() => getChatList(), 500)
    let chatPoll
    if (chatId) {
      chatPoll = setInterval(() => getChatData(chatId), 500)
      openChat(chatId)
    }
    searchChat(search)
    getMembers()
    return () => {
      clearInterval(chatPoll)
      clearInterval(chatListPoll)
    }
  }, [chatId, search, location])

  const scrollToBottom = () => {
    return messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const getChatList = () => {
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios.get(`${baseUrl}/messages/list`, config).then(({ data }) => {
      let sorted = data.data.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      })

      setChatList(sorted)
      if (location.state) {
        const id = location?.state.chatId
        setChatId(id)
      }
    })
  }

  const getMembers = () => {
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(`${baseUrl}/messages/users/list?limit=3`, config)
      .then(({ data }) => {
        const { data: members, total } = data
        setMembers(members)
        setTotalMembers(total)
      })
  }

  const getProfileDetails = id => {
    const data = chatList.find(item => item.message_link_id === id)
    if (data) setChatUser(data.user)
  }

  const openChat = id => {
    getProfileDetails(id)
    readChat(id)
    setChat([])
  }

  const getChatData = id => {
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios.get(`${baseUrl}/messages/${id}`, config).then(({ data }) => {
      const hasChanged = !compareObjects(data.data, chatRef.current)
      if (hasChanged) {
        setChat(data.data)
        scrollToBottom()
      }
    })
  }

  const writeMessage = e => {
    const { value } = e.target
    setText(value)
  }

  const sendChat = () => {
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const body = {
      user_id: chatUser.id,
      message_link_id: chat[0].message_link_id,
      message: text,
    }
    axios.post(`${baseUrl}/messages`, body, config).then(response => {
      setText("")
    })
  }

  const searchChat = value => {
    setSearchLoading(true);
    const token = localStorage.getItem("bearerToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${baseUrl}/messages/u/search?query=${value}`, config)
      .then(response => {
        const { data } = response;
        setSearchResult(data.data);
        setSearchLoading(false);
      });
  };

  const readChat = id => {
    const msgId = chatList.find(item => item.message_link_id === id).id;
    const token = localStorage.getItem("bearerToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${baseUrl}/messages/mark/read?id=${msgId}`, config)
      .then(response => {
        // console.log(response)
      })
      .catch(error => {
        console.log(error);
      });
  };

  const clearSearch = () => {
    setSearch("")
    setSearchResult([])
  }

  return (
    <Box className='container'>
      {chatList && chatList.length === 0 && (
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          pt='100px'
        >
          <Flex mb='40px' paddingLeft='110px'>
            {members.map(member => {
              const { id, last_name, first_name, avatar } = member
              return (
                <Avatar
                  key={id}
                  boxSize='150px'
                  ml='-110px'
                  name={`${first_name} ${last_name}`}
                  src={`${appUrl}/${avatar}`}
                />
              )
            })}
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              h='150px'
              w='150px'
              borderRadius='50%'
              backgroundColor='#FFFFFF'
              border='3px solid #3A5EFA'
              ml='-110px'
              zIndex='10'
              color='#3A5EFA'
              fontWeight={600}
            >
              <Text fontSize={30}>{totalMembers}</Text>
              <Text fontSize={14}>Members</Text>
            </Flex>
          </Flex>
          <Text color='#000000' fontSize={30} fontWeight={600}>
            No Chats Yet!
          </Text>
          <Text
            color='rgba(0, 0, 0, 0.65)'
            textAlign='center'
            maxWidth='346px'
            mb='30px'
          >
            Send a message request to a member on the platform to start
            chatting.
          </Text>
          <Button
            width='171px'
            height='45px'
            backgroundColor='#0E62FF'
            borderRadius='5px'
            color='#FFFFFF'
            fontWeight={500}
            _hover=''
            _focus=''
            _active=''
            onClick={() => history.push("/messages/members")}
          >
            Send Request
          </Button>
        </Flex>
      )}
      {chatList && chatList.length > 0 && (
        <Grid
          gridTemplateColumns='1fr 2fr'
          my='51px'
          border='1px solid rgba(103, 121, 142, 0.15)'
          borderRadius='5px'
        >
          <Box
            backgroundColor='rgba(255, 255, 255, 0.8)'
            borderRight='1px solid rgba(103, 121, 142, 0.15)'
          >
            <Flex
              px='30px'
              borderBottom='1px solid rgba(103, 121, 142, 0.15)'
              h='80px'
              flexDirection='column'
              justifyContent='center'
            >
              {/* <Text
              color="rgba(0, 0, 0, 0.9)"
              fontSize={18}
              fontWeight={500}
              mb="14px"
            >
              All Chats
            </Text> */}
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<SearchIcon color='gray.300' />}
                />
                <Input
                  type='text'
                  placeholder='Search for a chat'
                  borderColor='rgba(103, 121, 142, 0.12)'
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                />
                {search && (
                  <InputRightElement>
                    <SmallCloseIcon color='gray.300' onClick={clearSearch} />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
            <Box height='calc(100vh - 296px)' overflowY='scroll'>
              {!search &&
                chatList.map(item => {
                  const {
                    message,
                    user,
                    created_at,
                    read_at,
                    user_id,
                    message_link_id,
                  } = item
                  const { last_name, first_name, avatar } = user
                  const today = moment().format("DD/MM/YYYY")
                  const created = moment(created_at).format("DD/MM/YYYY")
                  let time =
                    today === created
                      ? moment(created_at).format("h:mma")
                      : created
                  const isActive = message_link_id === chatId
                  const read = Boolean(read_at)

                  return (
                    <Flex
                      key={message_link_id}
                      height='90px'
                      alignItems='center'
                      px={isActive ? "27px" : "30px"}
                      borderBottom='1px solid rgba(103, 121, 142, 0.15)'
                      backgroundColor={isActive ? "#F6F9FE" : ""}
                      borderLeft={isActive ? "3px solid #2A81FF" : ""}
                      onClick={() => setChatId(message_link_id)}
                    >
                      <Avatar
                        name={`${first_name} ${last_name}`}
                        boxSize='40px'
                        mr='10px'
                        src={`${appUrl}/${avatar}`}
                      />
                      <Box>
                        <Text
                          fontSize={15}
                          fontWeight={500}
                          color='rgba(0, 0, 0, 0.9)'
                        >
                          {`${first_name} ${last_name}`}
                        </Text>
                        <Flex alignItems='center'>
                          <Text
                            fontSize={14}
                            color='rgba(110, 110, 112, 0.8)'
                            mr='10px'
                            maxWidth='11vw'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                          >
                            {message}
                          </Text>
                          <Text fontSize={14} color='#6E6E70'>
                            {time}
                          </Text>
                        </Flex>
                      </Box>
                      {!read && user_id !== currentUser.id && (
                        <Circle size='10px' bg='#FFBF56' ml='auto' />
                      )}
                    </Flex>
                  )
                })}
              {search &&
                searchResult.map(item => {
                  const {
                    message,
                    user,
                    created_at,
                    read_at,
                    user_id,
                    message_link_id,
                  } = item
                  const { last_name, first_name, avatar } = user
                  const today = moment().format("DD/MM/YYYY")
                  const created = moment(created_at).format("DD/MM/YYYY")
                  let time =
                    today === created
                      ? moment(created_at).format("h:mma")
                      : created
                  const isActive = message_link_id === chatId
                  const read = Boolean(read_at)

                  return (
                    <Flex
                      key={message_link_id}
                      height='90px'
                      alignItems='center'
                      px={isActive ? "27px" : "30px"}
                      borderBottom='1px solid rgba(103, 121, 142, 0.15)'
                      backgroundColor={isActive ? "#F6F9FE" : ""}
                      borderLeft={isActive ? "3px solid #2A81FF" : ""}
                      onClick={() => setChatId(message_link_id)}
                    >
                      <Avatar
                        name={`${first_name} ${last_name}`}
                        boxSize='40px'
                        mr='10px'
                        src={`${appUrl}/${avatar}`}
                      />
                      <Box>
                        <Text
                          fontSize={15}
                          fontWeight={500}
                          color='rgba(0, 0, 0, 0.9)'
                        >
                          {`${first_name} ${last_name}`}
                        </Text>
                        <Flex alignItems='center'>
                          <Text
                            fontSize={14}
                            color='rgba(110, 110, 112, 0.8)'
                            mr='10px'
                            maxWidth='11vw'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                          >
                            {message}
                          </Text>
                          <Text fontSize={14} color='#6E6E70'>
                            {time}
                          </Text>
                        </Flex>
                      </Box>
                      {!read && user_id !== currentUser.id && (
                        <Circle size='10px' bg='#FFBF56' ml='auto' />
                      )}
                    </Flex>
                  )
                })}
              {searchLoading && (
                <Flex justifyContent='center' p='20px'>
                  <Spinner />
                </Flex>
              )}
            </Box>
          </Box>
          <Box backgroundColor='rgba(255, 255, 255, 0.4)'>
            {chatUser && (
              <>
                <Flex
                  px='50px'
                  borderBottom='1px solid rgba(103, 121, 142, 0.15)'
                  h='80px'
                  alignItems='center'
                >
                  <Avatar
                    name={`${chatUser.first_name} ${chatUser.last_name}`}
                    boxSize='50px'
                    mr='20px'
                    // src={chatUser.avatar}
                    src={`${appUrl}/${chatUser.avatar}`}
                  />
                  <Box>
                    <Text
                      fontSize={20}
                      fontWeight={500}
                      color='rgba(0, 0, 0, 0.9)'
                    >
                      {`${chatUser.first_name} ${chatUser.last_name}`}
                    </Text>
                    <Text fontSize={15} color='rgba(0, 0, 0, 0.7)' mr='10px'>
                      {chatUser.current_occupation &&
                        chatUser.current_occupation}
                    </Text>
                  </Box>
                  {/* <Box cursor="pointer" ml="auto">
                    <Menu>
                      <MenuButton as={Text}>
                        <MoreIcon />
                      </MenuButton>
                      <MenuList>
                        <MenuItem _hover="">Search Chat</MenuItem>
                        <MenuItem onClick={() => openModal("clear")}>
                          Clear Chat
                        </MenuItem>
                        <MenuItem
                          onClick={() => openModal("block")}
                          color="#DE4628"
                        >
                          Block User
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box> */}
                </Flex>
                <Box
                  height='calc(100vh - 396px)'
                  overflowY='scroll'
                  padding='0 50px'
                >
                  {chat &&
                    chat.map((item, index) => {
                      const { id, user_id, message, created_at } = item
                      const nextMsgTime = chat[index + 1]?.created_at
                      const prevMsgTime = chat[index - 1]?.created_at
                      const time = moment(created_at).format("h:mma")
                      const day = moment(created_at).format("DD/MM/YYYY")

                      const nextTime = nextMsgTime
                        ? moment(nextMsgTime).format("h:mma")
                        : nextMsgTime

                      const prevDay = prevMsgTime
                        ? moment(prevMsgTime).format("DD/MM/YYYY")
                        : prevMsgTime
                      return (
                        <Box key={id}>
                          {day !== prevDay && (
                            <Text
                              textAlign='center'
                              fontSize={14}
                              fontWeight={400}
                              color='rgba(0, 0, 0, 0.7)'
                              mb='30px'
                              mt='10px'
                            >
                              {day === moment().format("DD/MM/YYYY")
                                ? "Today"
                                : day}
                            </Text>
                          )}
                          {user_id === currentUser.id ? (
                            <>
                              <Flex justifyContent='flex-end' mb='10px'>
                                <Box
                                  maxWidth='60%'
                                  padding='12px 20px'
                                  backgroundColor='#0E62FF'
                                  borderRadius='25px'
                                  border='1px solid rgba(103, 121, 142, 0.1)'
                                  position='relative'
                                >
                                  <Text
                                    fontSize={15}
                                    fontWeight={400}
                                    color='#FFFFFF'
                                  >
                                    {message}
                                  </Text>
                                </Box>
                              </Flex>
                              {time !== nextTime && (
                                <Text
                                  textAlign='right'
                                  m='10px 0'
                                  color='rgba(0, 0, 0, 0.7)'
                                  fontSize={12}
                                >
                                  {time}
                                </Text>
                              )}
                            </>
                          ) : (
                            <>
                              <Flex alignItems='flex-end' mb='10px'>
                                {time !== nextTime ? (
                                  <Avatar
                                    name={`${chatUser.first_name} ${chatUser.last_name}`}
                                    boxSize='25px'
                                    mr='10px'
                                    size='xs'
                                    src={`${appUrl}/${chatUser.avatar}`}
                                  />
                                ) : (
                                  <Box width='25px' mr='10px' />
                                )}
                                <Box
                                  maxWidth='60%'
                                  padding='12px 20px'
                                  backgroundColor='rgba(51, 51, 51, 0.03)'
                                  borderRadius='25px'
                                  border='1px solid rgba(103, 121, 142, 0.1)'
                                  position='relative'
                                >
                                  <Text
                                    fontSize={15}
                                    fontWeight={400}
                                    color='rgba(0, 0, 0, 0.8)'
                                  >
                                    {message}
                                  </Text>
                                </Box>
                              </Flex>
                              {time !== nextTime && (
                                <Text
                                  m='10px 35px'
                                  color='rgba(0, 0, 0, 0.7)'
                                  fontSize={12}
                                >
                                  {time}
                                </Text>
                              )}
                            </>
                          )}
                        </Box>
                      )
                    })}
                  <div ref={messagesEndRef} />
                </Box>
                <Flex
                  backgroundColor='#F5F7FB'
                  h='100px'
                  px='50px'
                  alignItems='center'
                >
                  <Input
                    placeholder='Write a Message'
                    h='40px'
                    mr='16px'
                    border={0}
                    backgroundColor='#E5EDFB'
                    value={text}
                    onChange={writeMessage}
                    onKeyPress={e => (e.key === "Enter" ? sendChat() : null)}
                  />
                  <Button
                    backgroundColor='#0E62FF'
                    h='40px'
                    w='110px'
                    color='#FFFFFF'
                    fontWeight={500}
                    onClick={sendChat}
                    _hover=''
                    _focus=''
                  >
                    Send
                  </Button>
                </Flex>
              </>
            )}
          </Box>
        </Grid>
      )}
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p="50px">
            {modalType === "clear" ? (
              <>
                <Text
                  textAlign="center"
                  fontSize={20}
                  fontWeight={500}
                  color="rgba(0, 0, 0, 0.9)"
                  mb="10px"
                >
                  Clear Chat?
                </Text>
                <Text
                  textAlign="center"
                  color="rgba(0, 0, 0, 0.8)"
                  mx="50px"
                  mb="30px"
                >
                  You are about to clear your chat with{" "}
                  <Text as="b">Chidinma Aniugbo</Text>.
                </Text>
              </>
            ) : (
              <>
                <Text
                  textAlign="center"
                  fontSize={20}
                  fontWeight={500}
                  color="rgba(0, 0, 0, 0.9)"
                  mb="10px"
                >
                  Block Chidinma Aniugbo?
                </Text>
                <Text
                  textAlign="center"
                  color="rgba(0, 0, 0, 0.8)"
                  mx="30px"
                  mb="30px"
                >
                  You are about to block a member. Blocked members will no
                  longer be able to send you messages.
                </Text>
              </>
            )}
            <Flex justifyContent="space-around" px="50px">
              <Button
                backgroundColor="transparent"
                border="1px solid #0E62FF"
                h="35px"
                w="101px"
                color="#0E62FF"
                fontWeight={500}
                fontSize={14}
                _hover=""
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                backgroundColor="#CA3214"
                h="35px"
                w="101px"
                color="#FFFFFF"
                fontWeight={500}
                fontSize={14}
                textTransform="capitalize"
                _hover=""
              >
                {modalType}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </Box>
  )
}

export default Messages;
