import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Container,
  HStack,
} from "@chakra-ui/react";
// import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { useHistory, useParams } from "react-router-dom";
import { cmsUrl } from "../../../url";

import "./read.scss";
import Comments from "./Comments";

const ReadSharedLearning = () => {
  const history = useHistory();
  const { id } = useParams();

  const [articleData, setArticleData] = useState({});

  useEffect(() => {
    fetchArticle(id);
  }, [id]);

  const fetchArticle = (id) => {
    fetch(`${cmsUrl}/shared-learnings/${id}`)
      .then((res) => {
        return res.json();
      })
      .then((resJson) => {
        setArticleData(resJson);
      });
  };

  const thumbnailUrl = articleData.thumbnail
    ? `${cmsUrl}${articleData.thumbnail.url}`
    : "";

  const formatUrl = (text) => {
    return text.replaceAll("/uploads/", `${cmsUrl}/uploads/`);
  };

  return (
    <Box className="container" paddingBottom="150px">
      {/* go back */}
      <Flex
        marginTop={12}
        marginBottom={5}
        backgroundColor="transparent"
        padding={0}
        onClick={() => history.goBack()}
        cursor="pointer"
        alignItems="baseline"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.871 5.25L6.894 1.227L5.8335 0.166504L9.53674e-07 6L5.8335 11.8335L6.894 10.773L2.871 6.75H12V5.25H2.871Z"
            fill="#2A81FF"
          />
        </svg>
        <Text
          marginLeft={3}
          color="#2A81FF"
          fontFamily="Gordita"
          fontWeight={500}
          fontSize={14}
        >
          Go Back
        </Text>
      </Flex>

      <Heading fontSize={40} fontWeight="600" maxW="1140px" marginBottom={8}>
        {articleData.title}
      </Heading>
      <Image
        src={thumbnailUrl}
        rounded={5}
        width="100%"
        height="508px"
        style={{ objectFit: "cover" }}
        marginBottom={12}
      />

      <Container maxW="3xl" marginBottom={10}>
        <HStack justifyContent="space-between" width="100%">
          <Box width="90%">
            <Box marginBottom={5} fontSize={15}>
              <Text fontWeight="600">{articleData.author}</Text>
              <Text color="rgba(5, 0, 56, 0.6)">
                {moment(articleData.published_at).format("MMMM DD, YYYY")}
              </Text>
            </Box>
            <Box className="markdown" mb="50px">
              <ReactMarkdown>
                {articleData.body && formatUrl(articleData.body)}
              </ReactMarkdown>
              {/* <ReactMarkdown
              components={ChakraUIRenderer()}
              escapeHtml={false}
              children={articleData.body}
            /> */}
            </Box>
            <Comments articleId={id} />
          </Box>
        </HStack>
      </Container>
    </Box>
  );
};

export default ReadSharedLearning;
