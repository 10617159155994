import React, { useState } from "react";
import { MagnifyIcon, DropdownIcon } from "../Icons";
import "./styles.scss";
import EyeIcon from "../../assets/eye.svg"
import {
  Box,
  Input,
  Textarea,
  Text,
  Image,
  FormControl,
  Flex,
  FormLabel,
  Select,
} from "@chakra-ui/react"

export const SearchComponent = ({ placeholder }) => {
  return (
    <div className='customSeachWrapper'>
      <MagnifyIcon />
      <input type='search' placeholder={placeholder} />
    </div>
  )
}

// export const Drop

export const SelectDropdownComponent = () => {
  const [dropdown, setDropdown] = useState(false)
  return (
    <div className='customDropdownWrapper'>
      <div className='dropdown-select' onClick={() => setDropdown(!dropdown)}>
        <div className='dropdown-value'>Select option</div>
        <DropdownIcon />
      </div>

      <div
        style={{ display: dropdown ? "block" : "none" }}
        className='dropdown-options'
      ></div>
    </div>
  )
}

export const CommonCustomInput = ({
  placeholder,
  label,
  width,
  handleInput,
  value,
  required,
  isDisabled,
  labelFontSize,
}) => {
  const [isFocused, setisFocused] = useState(false)

  const handleFocus = () => setisFocused(true)
  const handleBlur = () => setisFocused(false)
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderStyle='solid'
      borderWidth={1.5}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      background='#fff'
      style={{ borderColor: isFocused ? "#2A81FF" : "#ddd" }}
    >
      <FormLabel
        htmlFor='email'
        color='#1D1E22'
        fontFamily='CamptonLight'
        fontWeight={400}
        fontSize={labelFontSize}
        margin={0}
        opacity={0.7}
      >
        {label}
      </FormLabel>

      <Input
        onBlur={handleBlur}
        onFocus={handleFocus}
        // focusBorderColor="transparent"
        // errorBorderColor="transparent"
        _placeholder={{ color: "#ddd", fontWeight: 400 }}
        fontWeight={500}
        fontSize={18}
        fontFamily='CamptonLight'
        value={value}
        onChange={e => handleInput(e.target.value)}
        variant='unstyled'
        placeholder={placeholder}
        border='none'
        type='text'
        isDisabled={isDisabled}
      />
    </FormControl>
  )
}

export const CommonCustomTextarea = ({
  placeholder,
  inputType,
  handleInput,
  value,
  label,
  width,
  required,
}) => {
  const [isFocused, setisFocused] = useState(false)

  const handleFocus = () => setisFocused(true)
  const handleBlur = () => setisFocused(false)
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1.5}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      background='#fff'
      style={{ borderColor: isFocused ? "#2A81FF" : "#ddd" }}
    >
      <FormLabel
        htmlFor='email'
        color='#616164'
        fontWeight={400}
        fontFamily='CamptonLight'
        fontSize={15}
        margin={0}
      >
        {label}
      </FormLabel>
      <Textarea
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        onChange={e => handleInput(e.target.value)}
        _placeholder={{ color: "#ddd", fontWeight: 400 }}
        fontWeight={500}
        fontSize={18}
        fontFamily='CamptonLight'
        variant='unstyled'
        placeholder={placeholder}
        border='none'
        type={inputType}
      />
    </FormControl>
  )
}

export const CustomDropDown = ({
  placeholder,
  handleOption,
  value,
  options,
  label,
  width,
  required,
}) => {
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      background='#fff'
    >
      <FormLabel
        htmlFor='email'
        color='#616164'
        fontFamily='CamptonLight'
        fontWeight={400}
        fontSize={13}
        margin={0}
        opacity={0.7}
      >
        {label}
      </FormLabel>
      <Select
        placeholder={placeholder}
        _placeholder={{ color: "#ddd", fontWeight: 400 }}
        border='none'
        background='transparent'
        _hover=''
        _focus=''
        fontWeight={500}
        fontSize={18}
        size='sm'
        fontFamily='CamptonLight'
        padding={0}
        value={value}
        onChange={e => handleOption(e.target.value)}
      >
        {options.map((option, index) => {
          return (
            <option key={index} value={option.name}>
              {option.name}
            </option>
          )
        })}
      </Select>
    </FormControl>
  )
}

export const CustomInputPassword = ({
  placeholder,
  handleInput,
  value,
  label,
  width,
  required,
}) => {
  const [visible, toggleVisibility] = useState(false)
  const [isFocused, setisFocused] = useState(false)

  const handleFocus = () => setisFocused(true)
  const handleBlur = () => setisFocused(false)

  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1.5}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      background='#fff'
      style={{ borderColor: isFocused ? "#2A81FF" : "#ddd" }}
    >
      <Box width='90%'>
        <FormLabel
          htmlFor='email'
          color='#616164'
          fontFamily='CamptonLight'
          fontWeight={400}
          fontSize={15}
          margin={0}
        >
          {label}
        </FormLabel>
        <Input
          onBlur={handleBlur}
          onFocus={handleFocus}
          _placeholder={{ color: "#ddd", fontWeight: 400 }}
          fontWeight={500}
          fontSize={18}
          fontFamily='CamptonLight'
          value={value}
          onChange={e => handleInput(e.target.value)}
          variant='unstyled'
          placeholder={placeholder}
          border='none'
          type={visible ? "text" : "password"}
        />
      </Box>
      <Image onClick={() => toggleVisibility(!visible)} src={EyeIcon} />
    </FormControl>
  )
}