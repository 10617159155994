import React from "react";
import illustration from "../../assets/images/who-we-are.png";
import { Box, Flex, Text, Image, Heading } from "@chakra-ui/react";

const WhoWeAre = () => {
  return (
    <Box
      id='who-we-are'
      background='#ffffff'
      paddingBottom={[20, 32, 32]}
      paddingTop={[20, 32, "35rem"]}
    >
      <Flex
        className='container'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        flexDirection={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
        ]}
      >
        <Box width={["80%", "80%", "60%", "33%"]} marginTop={[10, 10, 10, 0]}>
          <Image src={illustration} width='100%' />
          {/* <Box position="relative">
            <Image
              src={map_standalone}
              display={["none", "none", "none", "block"]}
              width="100%"
            /> */}
          {/* USERS PHOTOS*/}
          {/* <Box display={["none", "none", "none", "block"]}>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: "20px",
                }}
                background="rgba(233, 239, 249, 1)"
                height={80}
                width={80}
                borderRadius={50}
              ></Box>

              <Box
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "130px",
                }}
                background="rgba(233, 239, 249, 1)"
                height={80}
                width={80}
                borderRadius={50}
              ></Box>

              <Box
                style={{
                  position: "absolute",
                  top: "250px",
                  right: "100px",
                }}
                background="rgba(233, 239, 249, 1)"
                height={80}
                width={80}
                borderRadius={50}
              ></Box>

              <Box
                style={{
                  position: "absolute",
                  top: "250px",
                  left: "130px",
                }}
                background="rgba(233, 239, 249, 1)"
                height={80}
                width={80}
                borderRadius={50}
              ></Box>

              <Box
                style={{
                  position: "absolute",
                  bottom: "-20px",
                  left: "350px",
                }}
                background="rgba(233, 239, 249, 1)"
                height={80}
                width={80}
                borderRadius={50}
              ></Box>
            </Box>
          </Box> */}
        </Box>
        <Box width={["100%", "100%", "80%", "48%"]}>
          <Text
            fontFamily='CamptonMedium'
            fontSize={16}
            fontWeight={500}
            marginBottom={5}
          >
            WHO WE ARE
          </Text>
          <Heading
            fontSize={34}
            fontWeight={700}
            marginBottom={5}
            width={376}
            className='gradient-heading'
            fontFamily='CamptonSemiBold'
          >
            We are a Pan-African Community
          </Heading>
          <Text
            fontSize={18}
            fontWeight={400}
            fontFamily='CamptonLight'
            color='#47484C'
            lineHeight='32px'
          >
            A Pan-African community of epidemiologists, health practitioners,
            scientists, researchers, data analysts, and developers engaged in
            exploring the effective use of data and technology in the monitoring
            and surveillance of diseases on the continent.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default WhoWeAre;
