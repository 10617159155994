import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { NavLink, Route, useHistory } from "react-router-dom"
import "./styles.scss"
import {
  Box,
  Flex,
  Text,
  Image,
  Avatar,
  Button,
  Stack,
  Circle,
} from "@chakra-ui/react"
import Logo from "../../../assets/adein-logo.svg"
import { Popover } from "antd"
import axios from "axios"
import { baseUrl } from "../../../url"
import { getUserProfile } from "../../../redux/actions/user.action"

const appUrl = process.env.REACT_APP_URL

function DashboardLayout({ children }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(state => state.user)
  const { userData } = user

  const [msgBadgeStatus, setMsgBadgeStatus] = useState(false)
  const [chatBadgeStatus, setChatBadgeStatus] = useState(false)
  const [requestBadgeStatus, setRequestBadgeStatus] = useState(false)

  useEffect(() => {
    if (!window.localStorage.getItem("bearerToken")) {
      window.location = "/login"
    }
    // listen to route change
    history.listen(location => {
      // console.log(location);
      // console.log(location.pathname)
      if (location.pathname === "/dashboard/databank/") {
        history.push("/dashboard/databank/overview")
      }
      if (location.pathname === "/dashboard/shared-learning/") {
        history.push("/dashboard/shared-learning/all")
        console.log(location.pathname)
      }
      if (location.pathname === "/messages") {
        history.push("/messages/chats")
      }
      if (location.pathname === "/profile") {
        history.push("/profile/personal")
      }
    })
  }, [])

  useEffect(() => {
    const token = localStorage.getItem("bearerToken")
    const userId = JSON.parse(localStorage.getItem("user")).id
    dispatch(getUserProfile(userId, token))
  }, [dispatch])

  useEffect(() => {
    const token = localStorage.getItem("bearerToken");

    const fetchMsgBadge = async token => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios
        .get(`${baseUrl}/messages/new/badge`, config)
        .then(res => {
          setMsgBadgeStatus(res.data.data.message);
          setRequestBadgeStatus(res.data.data.request);
          setChatBadgeStatus(res.data.data.message);
        })
        .catch(ex => {
          console.log("");
        });
    };

    const badgePoll = setInterval(() => fetchMsgBadge(token), 1000);

    return () => {
      clearInterval(badgePoll);
    };
  }, []);

  const handleClickMenu = (e, clickable) => {
    !clickable && e.preventDefault();
  };

  const menus = [
    {
      name: "Databank",
      path: "/databank",
      clickable: true,
      submenus: [
        {
          name: "Overview",
          path: "/databank/overview",
          clickable: true,
        },
        {
          name: "Internal Data",
          path: "/databank/internal",
          clickable: true,
        },
        {
          name: "External Data",
          path: "/databank/external",
          clickable: true,
        },
      ],
    },
    {
      name: "Shared Learning",
      path: "/shared-learning/",
      clickable: true,
      submenus: [
        {
          name: "All",
          path: "/shared-learning/all",
          clickable: true,
        },
        {
          name: "Articles",
          path: "/shared-learning/articles",
          clickable: true,
        },
        {
          name: "Reports",
          path: "/shared-learning/reports",
          clickable: true,
        },
        {
          name: "Interactives",
          path: "/shared-learning/interactives",
          clickable: true,
        },
      ],
    },
    {
      name: "Innovation",
      path: "/innovation",
      clickable: true,
      submenus: [],
    },
    {
      name: "Events",
      path: "/events",
      clickable: true,
      submenus: [],
    },
    {
      name: "Messages",
      path: "/messages",
      clickable: true,
      submenus: [
        {
          name: "Chats",
          path: "/messages/chats",
          clickable: true,
        },
        {
          name: "Requests",
          path: "/messages/requests",
          clickable: true,
        },
        {
          name: "Members",
          path: "/messages/members",
          clickable: true,
        },
      ],
    },
  ];

  const profileMenu = [
    {
      name: "Profile Settings",
      path: "/profile",
      clickable: false,
      submenus: [
        {
          name: "Personal Information",
          path: "/profile/personal",
          clickable: true,
        },
        {
          name: "Professional Qualifications",
          path: "/profile/professional",
          clickable: true,
        },
        {
          name: "Password",
          path: "/profile/password",
          clickable: true,
        },
      ],
    },
  ];

  return (
    <Box>
      <Box position='fixed' width='100%' zIndex={10} background='white'>
        <Flex
          paddingTop={8}
          height={20}
          justifyContent='space-between'
          className='container'
        >
          <Box width='auto'>
            <Image src={Logo} alt='ADEIN' />
          </Box>
          <Flex justifyContent='center' width='auto'>
            {menus.map((menu, index) => {
              return (
                <NavLink
                  to={{
                    pathname: menu.path,
                    submenus: menu.submenus,
                  }}
                  className={`menu-link ${!menu.clickable && "disabled-link"}`}
                  activeClassName='menu-link-active'
                  key={index}
                  onClick={e => {
                    handleClickMenu(e, menu.clickable);
                  }}
                >
                  <Flex>
                    <Text
                      fontFamily='CamptonLight'
                      fontSize={16}
                      fontWeight={500}
                      marginLeft={2}
                    >
                      {menu.name}
                    </Text>
                    {menu.name === "Messages" && msgBadgeStatus && (
                      <Circle size='10px' bg='#FFBF56' />
                    )}
                  </Flex>
                  <svg
                    className='polygon'
                    width='23'
                    height='9'
                    viewBox='0 0 23 9'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10.6339 0.499161C10.999 0.207286 11.5176 0.207287 11.8827 0.499161L22.5167 9H0L10.6339 0.499161Z'
                      fill='#0259D7'
                    />
                  </svg>
                  <Flex
                    className='submenus'
                    position='absolute'
                    width='100%'
                    left={0}
                    background='#0259d7'
                  >
                    <Flex justifyContent='center' position='relative'>
                      {menu.submenus.map((submenu, index) => {
                        return (
                          <NavLink
                            key={index}
                            className={`submenu-link ${
                              !submenu.clickable && "disabled-link"
                            }`}
                            activeClassName='submenu-link-active'
                            to={submenu.path}
                            onClick={e => {
                              handleClickMenu(e, submenu.clickable);
                            }}
                          >
                            <Flex>
                              <Text>{submenu.name}</Text>
                              {submenu.name === "Chats" &&
                                chatBadgeStatus &&
                                history.location.pathname !==
                                  "/messages/chats" && (
                                  <Circle size='10px' bg='#FFBF56' />
                                )}
                              {submenu.name === "Requests" &&
                                requestBadgeStatus &&
                                history.location.pathname !==
                                  "/messages/requests" && (
                                  <Circle size='10px' bg='#FFBF56' />
                                )}
                            </Flex>
                          </NavLink>
                        );
                      })}
                    </Flex>
                  </Flex>
                </NavLink>
              );
            })}
          </Flex>

          <Flex width='auto' justifyContent='flex-end'>
            {profileMenu.map((menu, index) => {
              return (
                <NavLink
                  to={{
                    pathname: menu.path,
                    submenus: menu.submenus,
                  }}
                  className={`menu-link`}
                  activeClassName='menu-link-active'
                  key={index}
                  onClick={e => {
                    handleClickMenu(e, menu.clickable);
                  }}
                >
                  <Text
                    fontFamily='CamptonLight'
                    fontSize={16}
                    fontWeight={500}
                    marginLeft={2}
                  >
                    <Popover
                      content={
                        <Stack>
                          {/* <Link href="">Notifications</Link>
                  <Link href="">Profile Settings</Link> */}
                          <NavLink
                            to={{
                              pathname: menu.path,
                              submenus: menu.submenus,
                            }}
                            onClick={e => {
                              handleClickMenu(e, true);
                            }}
                          >
                            {menu.name}
                          </NavLink>
                          <Text
                            _hover={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            color='#DE4628'
                            onClick={() => {
                              window.localStorage.clear();
                              window.location = "/login";
                            }}
                          >
                            Logout
                          </Text>
                        </Stack>
                      }
                    >
                      <Button variant='unstyled' type='primary'>
                        <Flex
                          position='relative'
                          right={0}
                          top={-3}
                          width='auto'
                          borderRadius={100}
                          background='rgba(42, 129, 255, 0.08)'
                          padding={1}
                          alignItems='center'
                        >
                          <Avatar
                            size='sm'
                            name={
                              userData &&
                              userData.first_name + " " + userData.last_name
                            }
                            bg='blue'
                            color='white'
                            src={`${appUrl}/${userData && userData.avatar}`}
                          />
                          <Text
                            paddingX={3}
                            color='#252733'
                            fontFamily='CamptonLight'
                            fontSize={16}
                            fontWeight={500}
                          >
                            {userData &&
                              userData.first_name + " " + userData.last_name}
                          </Text>
                        </Flex>
                      </Button>
                    </Popover>
                    {/* {menu.name} */}
                  </Text>
                  <svg
                    className='polygon'
                    width='23'
                    height='9'
                    viewBox='0 0 23 9'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10.6339 0.499161C10.999 0.207286 11.5176 0.207287 11.8827 0.499161L22.5167 9H0L10.6339 0.499161Z'
                      fill='#0259D7'
                    />
                  </svg>
                  <Flex
                    className='submenus'
                    position='absolute'
                    width='100%'
                    left={0}
                    background='#0259d7'
                  >
                    <Flex justifyContent='center' position='relative'>
                      {menu.submenus.map((submenu, index) => {
                        return (
                          <NavLink
                            key={index}
                            className={`submenu-link ${
                              !submenu.clickable && "disabled-link"
                            }`}
                            activeClassName='submenu-link-active'
                            to={submenu.path}
                            onClick={e => {
                              handleClickMenu(e, submenu.clickable);
                            }}
                          >
                            {submenu.name}
                          </NavLink>
                        );
                      })}
                    </Flex>
                  </Flex>
                </NavLink>
              );
            })}

            {/* </Popover> */}
          </Flex>
        </Flex>
      </Box>

      <Box paddingTop={36}>{children}</Box>
    </Box>
  );
}

const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <Component {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;
