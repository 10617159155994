import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Box,
  Text,
  Grid,
  Flex,
  Avatar,
  Button,
  Image,
} from "@chakra-ui/react";
import { baseUrl } from "../../../url";

import alarm from "../../../assets/alarm.svg";
import { useHistory } from "react-router";

const appUrl = process.env.REACT_APP_URL

const Requests = () => {
  const history = useHistory()

  const [requestList, setRequestList] = useState([])
  const [requestId, setRequestId] = useState(null)
  const [request, setRequest] = useState(null)
  const [requester, setRequester] = useState(null)

  const [approveLoading, setApproveLoading] = useState(false)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRequestList()
    if (requestId) getRequest(requestId)
  }, [requestId])

  const getRequestList = () => {
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios.get(`${baseUrl}/messages/requests/list`, config).then(({ data }) => {
      setRequestList(data.data)
      setLoading(false)
    })
  }

  const getRequest = id => {
    setRequester(findProfile(id))

    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(`${baseUrl}/messages/requests/messages/${id}`, config)
      .then(({ data }) => {
        // console.log(data);
        setRequest(data.data)
      })
  }

  const findProfile = id => {
    // console.log(id);
    return requestList.find(item => item.id === id).user
  }

  const approveRequest = () => {
    setApproveLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const body = {
      link_id: request[0].message_link_id,
    }
    axios
      .post(`${baseUrl}/messages/approve`, body, config)
      .then(({ data }) => {
        // console.log(data);
        setApproveLoading(false)

        history.push({
          pathname: "/messages/chats",
          state: {
            chatId: request[0].message_link_id,
          },
        })
      })
      .catch(error => {
        console.log(error)
        setApproveLoading(false)
      })
  }

  return (
    <Box className='container'>
      {!loading && requestList.length === 0 && (
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          pt='100px'
        >
          <Image src={alarm} mb='40px' />
          <Text color='#000000' fontSize={30} fontWeight={600}>
            No Requests!
          </Text>
          <Text
            color='rgba(0, 0, 0, 0.65)'
            textAlign='center'
            maxWidth='346px'
            mb='30px'
          >
            You have not received any message request yet.
          </Text>
        </Flex>
      )}
      {requestList.length > 0 && (
        <Grid
          gridTemplateColumns='1fr 2fr'
          my='51px'
          border='1px solid rgba(103, 121, 142, 0.15)'
          borderRadius='5px'
        >
          <Box
            backgroundColor='rgba(255, 255, 255, 0.8)'
            borderRight='1px solid rgba(103, 121, 142, 0.15)'
          >
            <Flex
              px='30px'
              borderBottom='1px solid rgba(103, 121, 142, 0.15)'
              h='80px'
              flexDirection='column'
              justifyContent='center'
            >
              <Text color='rgba(0, 0, 0, 0.9)' fontSize={18} fontWeight={500}>
                All Request
              </Text>
            </Flex>
            <Box height='calc(100vh - 296px)' overflowY='scroll'>
              {requestList.map(item => {
                const { id, message, user, created_at } = item
                const { last_name, first_name, avatar } = user
                const today = moment().format("DD/MM/YYYY")
                const created = moment(created_at).format("DD/MM/YYYY")
                let time =
                  today === created
                    ? moment(created_at).format("h:mma")
                    : created
                return (
                  <Flex
                    key={item}
                    height='90px'
                    alignItems='center'
                    px='30px'
                    borderBottom='1px solid rgba(103, 121, 142, 0.15)'
                    onClick={() => setRequestId(id)}
                  >
                    <Avatar
                      name={`${first_name} ${last_name}`}
                      boxSize='40px'
                      mr='10px'
                      src={`${appUrl}/${avatar}`}
                    />
                    <Box>
                      <Text
                        fontSize={15}
                        fontWeight={500}
                        color='rgba(0, 0, 0, 0.9)'
                      >
                        {`${first_name} ${last_name}`}
                      </Text>
                      <Flex alignItems='center'>
                        <Text
                          fontSize={14}
                          color='rgba(110, 110, 112, 0.8)'
                          mr='10px'
                        >
                          {message}
                        </Text>
                        <Text fontSize={14} color='#6E6E70'>
                          {time}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                )
              })}
            </Box>
          </Box>
          {requester && (
            <>
              <Box backgroundColor='rgba(255, 255, 255, 0.4)'>
                <Flex
                  px='50px'
                  borderBottom='1px solid rgba(103, 121, 142, 0.15)'
                  h='80px'
                  alignItems='center'
                >
                  <Avatar
                    name={`${requester.first_name} ${requester.last_name}`}
                    boxSize='50px'
                    mr='20px'
                    src={`${appUrl}/${requester.avatar}`}
                  />
                  <Box>
                    <Text
                      fontSize={20}
                      fontWeight={500}
                      color='rgba(0, 0, 0, 0.9)'
                    >
                      {`${requester.first_name} ${requester.last_name}`}
                    </Text>
                    <Text fontSize={15} color='rgba(0, 0, 0, 0.7)' mr='10px'>
                      {`${requester.current_occupation} @${requester.organization_name}` ||
                        ""}
                    </Text>
                  </Box>
                </Flex>
                <Box
                  height='calc(100vh - 396px)'
                  overflowY='scroll'
                  padding='0 50px'
                >
                  <Text
                    textAlign='center'
                    fontSize={14}
                    fontWeight={400}
                    color='rgba(0, 0, 0, 0.7)'
                    mb='30px'
                  >
                    Today
                  </Text>
                  {request &&
                    request.map(item => {
                      const { message } = item
                      return (
                        <>
                          <Flex alignItems='flex-end' mb='10px'>
                            <Avatar
                              name={`${requester.first_name} ${requester.last_name}`}
                              boxSize='25px'
                              mr='10px'
                              size='xs'
                              src={`${appUrl}/${requester.avatar}`}
                            />
                            <Box
                              maxWidth='60%'
                              padding='12px 20px'
                              backgroundColor='rgba(51, 51, 51, 0.03)'
                              borderRadius='25px'
                              border='1px solid rgba(103, 121, 142, 0.1)'
                              position='relative'
                            >
                              <Text
                                fontSize={15}
                                fontWeight={400}
                                color='rgba(0, 0, 0, 0.8)'
                              >
                                {message}
                              </Text>
                            </Box>
                          </Flex>
                          <Text
                            m='10px 35px'
                            color='rgba(0, 0, 0, 0.7)'
                            fontSize={12}
                          >
                            12:30pm
                          </Text>
                        </>
                      )
                    })}
                  <Text
                    m='50px 30%'
                    textAlign='center'
                    color='rgba(0, 0, 0, 0.3)'
                    fontSize={14}
                  >
                    {`${requester.first_name} ${requester.last_name}`} wants to
                    send you a message
                  </Text>
                </Box>
                <Flex
                  backgroundColor='#F5F7FB'
                  h='100px'
                  px='50px'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Button
                    backgroundColor='transparent'
                    border='1px solid #F6431E'
                    h='40px'
                    w='48%'
                    color='#F6431E'
                    fontWeight={500}
                    _hover=''
                  >
                    Delete
                  </Button>
                  <Button
                    backgroundColor='#0E62FF'
                    h='40px'
                    w='48%'
                    color='#FFFFFF'
                    fontWeight={500}
                    _hover=''
                    onClick={approveRequest}
                    isLoading={approveLoading}
                  >
                    Accept
                  </Button>
                </Flex>
              </Box>
            </>
          )}
        </Grid>
      )}
    </Box>
  )
}

export default Requests;
