import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Grid,
  GridItem,
  VStack,
  Image,
  Container,
  Skeleton,
} from "@chakra-ui/react"
import PageHeader from "../../../components/sharedLearning/pageHeader"
import { useHistory } from "react-router-dom"
import { cmsUrl } from "../../../url"

const Innovation = () => {
  const history = useHistory()
  const [articles, setArticles] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [filtered, setFiltered] = useState(null)
  const [loaded, setLoaded] = useState(true) //as required by chakra Skeleton isLoaded prop

  const searchSharedData = e => {
    setSearchQuery(e)

    const searchResult = articles.filter(article => {
      const regex = new RegExp(`${e}`, "gi")
      return article.title.match(regex)
    })

    setFiltered(searchResult)
  }

  useEffect(() => {
    fetchArticles()
  }, [])

  const fetchArticles = () => {
    setLoaded(false)
    fetch(`${cmsUrl}/innovation`)
      .then(res => res.json())
      .then(resJson => {
        setArticles(resJson)
        setLoaded(true)
      })
      .catch(ex => {
        setLoaded(true)
        console.log(ex)
      })
  }

  return (
    <Box className='container'>
      <Box marginBottom={14}>
        <PageHeader
          title='📖 Innovation'
          subtitle='Our curated list of innovative solutions across africa.'
          icon=''
          searchPlaceholder='Search for Innovation'
          value={searchQuery}
          handleInput={value => searchSharedData(value)}
        />
      </Box>

      <Container maxW='5xl' paddingBottom='150px'>
        <Grid
          marginY={10}
          templateColumns={["1fr", "1fr 1fr"]}
          // templateColumns="repeat(auto-fit, minmax(425px, 1fr))"
          gap={24}
        >
          {filtered !== null
            ? filtered.map((item, index) => {
                const { id, title, thumbnail, tag } = item;
                const thumbnailUrl = thumbnail
                  ? `${cmsUrl}${thumbnail.url}`
                  : "";
                return (
                  <Skeleton key={id} isLoaded={loaded}>
                    <GridItem
                      cursor='pointer'
                      height={639}
                      rounded={10}
                      paddingTop={index % 2 ? 10 : 0}
                      onClick={() => history.push(`/innovation/${id}`)}
                      key={id}
                    >
                      <VStack
                        justifyContent='space-between'
                        alignItems='flex-start'
                      >
                        <Box height={530} width='100%'>
                          <Image
                            src={thumbnailUrl}
                            style={{ objectFit: "cover" }}
                            height='100%'
                          />
                        </Box>
                        <Box>
                          <Text
                            color='#0E62FF'
                            fontWeight='500'
                            marginBottom={3}
                            fontSize={12}
                            marginTop={2}
                          >
                            {tag}
                          </Text>
                          <Text
                            color='rgba(33, 29, 86, 0.85)'
                            fontWeight='700'
                            fontSize={18}
                          >
                            {title}
                          </Text>
                        </Box>
                      </VStack>
                    </GridItem>
                  </Skeleton>
                );
              })
            : articles.map((item, index) => {
                const { id, title, thumbnail, tag } = item;
                const thumbnailUrl = thumbnail
                  ? `${cmsUrl}${thumbnail.url}`
                  : "";
                return (
                  <Skeleton key={id} isLoaded={loaded}>
                    <GridItem
                      cursor='pointer'
                      height={639}
                      rounded={10}
                      paddingTop={index % 2 ? 10 : 0}
                      onClick={() => history.push(`/innovation/${id}`)}
                      key={id}
                    >
                      <VStack
                        justifyContent='space-between'
                        alignItems='flex-start'
                      >
                        <Box height={530} width='100%'>
                          <Image
                            src={thumbnailUrl}
                            style={{ objectFit: "cover" }}
                            height='100%'
                          />
                        </Box>
                        <Box>
                          <Text
                            color='#0E62FF'
                            fontWeight='500'
                            marginBottom={3}
                            fontSize={12}
                            marginTop={2}
                          >
                            {tag}
                          </Text>
                          <Text
                            color='rgba(33, 29, 86, 0.85)'
                            fontWeight='700'
                            fontSize={18}
                          >
                            {title}
                          </Text>
                        </Box>
                      </VStack>
                    </GridItem>
                  </Skeleton>
                );
              })}
        </Grid>
      </Container>
    </Box>
  );
}

export default Innovation;
