import React from "react";
import { Box, Flex, Text, Heading, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const Banner = () => {
  let history = useHistory();
  return (
    <Box className="gradient-bg" paddingY={32}>
      <Flex
        flexDirection={["column", "column", "column", "row"]}
        className="container"
        justifyContent="space-between"
        alignItems={["center", "center", "center", "center"]}
        textAlign={["center", "center", "center", "left"]}
      >
        <Box
          width={["100%", "100%", "85%", "60%"]}
          marginBottom={[10, 10, 10, 0]}
        >
          <Heading
            fontSize={34}
            fontWeight={700}
            marginBottom={5}
            color="white"
            fontFamily="CamptonSemiBold"
          >
            Explore Unique Opportunities
          </Heading>
          <Text
            color="white"
            fontSize={20}
            fontFamily="CamptonLight"
            fontWeight={500}
            lineHeight="32px"
          >
            Be part of a multidisciplinary platform of experts in public health,
            data science, and technology in Africa.
          </Text>
        </Box>

        <Button
          size="lg"
          background="white"
          color="#2A81FF"
          fontSize={18}
          width={234}
          borderRadius={6}
          onClick={() => history.push("/signup")}
        >
          Become a Member
        </Button>
      </Flex>
    </Box>
  );
};

export default Banner;
