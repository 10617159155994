import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Flex,
  Heading,
  HStack,
  Text,
  Select,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { baseUrl } from "../../../../url";
import axios from "axios";
import EligibilityForTesting from "../../components/Charts/covid-19/eligibilityForTesting";
import EligibilityByEnrollment from "../../components/Charts/covid-19/eligibilityByEnrollment";
import PresentingForFreeTest from "../../components/Charts/covid-19/presentingForFreeTest";
import GetHivPrevalanceData from "../../components/Charts/hiv/getHIVPrevalenceData";
import GetRiskyBehaviorData from "../../components/Charts/hiv/getRiskyBehaviorData";
import { useQuery } from "../../../../customhooks/useQuery"

const Charts = () => {
  let query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [disease, setdisease] = useState({});

  const [selectedIndicator, setselectedIndicator] = useState("");
  const [selectedCountry, setselectedCountry] = useState("");
  const [diseases, setdiseases] = useState([]);
  const [indicators, setindicators] = useState([]);
  const [countries, setcountries] = useState([]);

  useEffect(() => {
    // get disease indicators
    fetchIndicators(query.get("diseaseId"));
    setselectedCountry(query.get("country"));
    setselectedIndicator(query.get("indicatorPathname"));

    // get countries
    axios
      .get(
        `${baseUrl}/country/all?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setcountries(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // get disease details
    axios
      .get(
        `${baseUrl}/disease/${query.get(
          "diseaseId"
        )}?token=${window.localStorage.getItem("bearerToken")}`
      )
      .then((response) => {
        setdisease(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // get disease indicators
    fetchIndicators(query.get("diseaseId"));

    // get diseases
    axios
      .get(
        `${baseUrl}/disease/all?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        let hasIndicators = response.data.data.filter((disease) => {
          return disease.indicators.length > 0;
        });
        setdiseases(hasIndicators);
      });
  }, []);

  useEffect(() => {
    history.push(
      `/databank/internal/charts?diseaseId=${query.get(
        "diseaseId"
      )}&indicatorPathname=${selectedIndicator}&country=${selectedCountry}&diseaseName=${query.get(
        "diseaseName"
      )}`
    );
  }, [selectedCountry, selectedIndicator]);

  const fetchIndicators = (diseaseId) => {
    axios
      .get(
        `${baseUrl}/indicator/disease/${diseaseId}?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setindicators(response.data.data);
        setselectedIndicator(response.data.data[0].path_name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container maxW='6xl' marginTop={16}>
      <Breadcrumb
        marginBottom={10}
        spacing='8px'
        separator={<ChevronRightIcon color='gray.500' />}
        fontWeight={500}
      >
        <BreadcrumbItem>
          <BreadcrumbLink color='#2A81FF' href='/databank/overview'>
            Databank
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink color='#2A81FF' href='/databank/overview/countries'>
            Internal Data
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            color='#2A81FF'
            href={`/databank/overview/indicators?disease=${query.get(
              "diseaseId"
            )}&country=${query.get("country")}`}
          >
            {disease.name}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#' color='rgba(0,0,0,0.5)'>
            Indicators
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        borderBottom='1px solid #eee'
        paddingBottom={10}
      >
        <Heading fontSize={32} fontWeight='500' fontFamily='CamptonMedium'>
          {disease?.name}
        </Heading>
        <HStack spacing={3} alignItems='center'>
          <Text>Filtered by</Text>
          <Select
            width='140px'
            borderRadius={2}
            border='1px solid rgba(103, 121, 142, 0.15)'
            borderColor='gray'
            onChange={e => setselectedIndicator(e.target.value)}
          >
            {indicators.map(indicator => (
              <option key={indicator.id} value={indicator.path_name}>
                {indicator.name}
              </option>
            ))}
          </Select>

          <Select
            width='140px'
            borderRadius={2}
            border='1px solid rgba(103, 121, 142, 0.15)'
            borderColor='gray'
            onChange={e => setselectedCountry(e.target.value)}
          >
            {countries.map(country => {
              return (
                <>
                  {country.diseases.length > 0 && (
                    <option value={country.id}>{country.name}</option>
                  )}
                </>
              )
            })}
          </Select>
        </HStack>
      </Flex>
      {/* charts */}
      {query.get("diseaseName") === "COVID-19" && (
        <>
          {query.get("indicatorPathname") === "eligibilityForTesting" && (
            <EligibilityForTesting />
          )}
          {query.get("indicatorPathname") === "eligibilityByEnrollment" && (
            <EligibilityByEnrollment />
          )}
          {query.get("indicatorPathname") === "presentingForFreeTest" && (
            <PresentingForFreeTest />
          )}
        </>
      )}

      {query.get("diseaseName") === "HIV" && (
        <>
          {query.get("indicatorPathname") === "getHIVPrevalenceData" && (
            <GetHivPrevalanceData />
          )}
          {query.get("indicatorPathname") === "getRiskyBehaviorData" && (
            <GetRiskyBehaviorData />
          )}
        </>
      )}
    </Container>
  )
};

export default Charts;
