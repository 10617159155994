import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import Logo from "../../assets/adein-logo.svg";
import { useToast, Flex, Text, Box, Image, Button } from "@chakra-ui/react";

const AuthButtons = () => {
  let history = useHistory();
  let toast = useToast();
  return (
    <Flex
      width={["100%", "100%", "100%", "30%"]}
      justifyContent="flex-end"
      alignItems="center"
      // className="auth-buttons"
    >
      {/* mobile login */}
      <Button
        display={["block", "block", "none"]}
        marginLeft={[0, 0, 10]}
        background="transparent"
        color="#2A81FF"
        borderColor="#2A81FF"
        borderWidth={2}
        borderStyle="solid"
        size="lg"
        fontFamily="Gordita"
        fontWeight={500}
        fontSize={14}
        onClick={() => {
          toast({
            title: "Not available yet",
            description: "",
            status: "info",
            duration: 4000,
            position: "top",
            isClosable: true,
          });
        }}
        _hover={{
          boxShadow: "inset 200px 0 0 0 #2A81FF",
          color: "#fff",
        }}
      >
        Login
      </Button>
      {/* desktop login */}
      <Button
        display={["none", "none", "block"]}
        _hover={{
          boxShadow: "inset 30px 0 0 0 rgba(32,155,255,0.5)",
          color: "#2A81FF",
        }}
        borderRadius={0}
        padding={3}
        variant="unstyled"
        color="#2A81FF"
        fontFamily="Gordita"
        fontWeight={500}
        fontSize={14}
        onClick={() => history.push("/login")}
      >
        Login
      </Button>
      <Button
        display={["none", "none", "flex"]}
        marginLeft={[0, 0, 10]}
        background="transparent"
        color="#2A81FF"
        borderColor="#2A81FF"
        borderWidth={2}
        borderStyle="solid"
        size="lg"
        fontFamily="Gordita"
        fontWeight={500}
        fontSize={14}
        onClick={() => history.push("/signup")}
        _hover={{
          boxShadow: "inset 200px 0 0 0 #2A81FF",
          color: "#fff",
        }}
      >
        Become a member
      </Button>
    </Flex>
  );
};

const Navigation = () => {
  return (
    <Box
      position="sticky"
      zIndex={2}
      top={0}
      width="100%"
      background="white"
      border="1px solid rgba(44, 44, 44, 0.05)"
    >
      <Flex
        className="container"
        justifyContent="space-between"
        alignItems="center"
        paddingY={5}
      >
        <Box width={["50%", "50%", "30%"]}>
          <Image src={Logo} alt="" />
        </Box>

        <Flex
          display={["none", "none", "none", "flex"]}
          width="30%"
          justifyContent="space-between"
        >
          <a href="#who-we-are">
            <Text fontFamily="Gordita" fontWeight={500} fontSize={14}>
              Who we are
            </Text>
          </a>
          <a href="#what-we-do">
            <Text fontFamily="Gordita" fontWeight={500} fontSize={14}>
              What we do
            </Text>
          </a>
          <a href="#benefits">
            <Text fontFamily="Gordita" fontWeight={500} fontSize={14}>
              Benefits
            </Text>
          </a>
        </Flex>
        <AuthButtons />
      </Flex>
    </Box>
  );
};

export default Navigation;
