import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Container, Select } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import SearchComponent from "../../components/SearchComponent";
import GoBack from "../../components/GoBack";
import axios from "axios";
import { baseUrl } from "../../../../url";
import { facebookData } from "../../../../utils/helper"

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const DiseaseIndicators = () => {
  let query = useQuery()
  let history = useHistory()
  const [indicators, setindicators] = useState([])
  const [disease, setdisease] = useState({})
  const [primaryExists, setPrimaryExists] = useState(true)

  useEffect(() => {
    let diseaseQuery = query.get("disease")
    // get disease detail
    if (diseaseQuery === "fbdfg") {
      setdisease(facebookData)
      setindicators(facebookData.externalIndicators)
    } else {
      axios
        .get(
          `${baseUrl}/external/diseases/${query.get(
            "disease"
          )}?token=${window.localStorage.getItem("bearerToken")}`
        )
        .then(response => {
          setdisease(response.data.data)
          setindicators(response.data.data.externalIndicators)
          console.log(response, "na responsseee")
        })
        .catch(error => {
          console.log(error)
        })
    }

    console.log(diseaseQuery, "=#+#+#+#+#+=")
  }, [])

  const handleSorting = option => {
    if (option === "a-z") {
      const sorted = indicators.sort((a, b) =>
        a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
      )
      setindicators(sorted)
      setPrimaryExists(x => !x)
    }

    if (option === "z-a") {
      let sorted = indicators
        .sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0))
        .reverse()
      // setindicators(sort);
      setPrimaryExists(x => !x)
      setindicators(sorted)
    }
  }

  return (
    <Container maxW='5xl'>
      <Box marginTop={10}>
        <GoBack to='/databank/external' />
      </Box>
      <Box marginY={10}>
        <SearchComponent
          searchPlaceholder='Search for Indicators'
          pageTitle={disease.name}
        />
      </Box>

      <Box
        marginBottom={20}
        paddingY={16}
        paddingX={20}
        background='rgba(255, 255, 255, 0.4)'
        border='1px solid rgba(103, 121, 142, 0.15)'
      >
        <Flex justifyContent='space-between'>
          <Text fontWeight={700} fontSize={18} fontFamily='CamptonMedium'>
            All Indicators
          </Text>
          <Select
            placeholder='Sort by'
            width='108px'
            borderRadius={2}
            border='1px solid rgba(103, 121, 142, 0.15)'
            borderColor='gray'
            onChange={e => handleSorting(e.target.value)}
          >
            <option value='a-z'>A to Z</option>
            <option value='z-a'>Z to A</option>
          </Select>
        </Flex>
        <Box marginTop={10} key={String(primaryExists)}>
          {disease?.id === "fbdfg"
            ? indicators.map((indicator, index) => {
                return (
                  <Flex
                    as='a'
                    borderBottom={
                      index === indicators.length - 1
                        ? "none"
                        : "1px solid #F0F0F0"
                    }
                    paddingY={5}
                    justifyContent='space-between'
                    alignItems='center'
                    marginBottom={8}
                    key={indicator.id}
                    cursor='pointer'
                    _hover=''
                    target='_blank'
                    rel='noopener'
                    href={indicator.data_link}
                  >
                    <Text
                      fontFamily='CamptonLight'
                      fontSize={16}
                      fontWeight={400}
                    >
                      {indicator.name}
                    </Text>
                    <Box>
                      <svg
                        width='10'
                        height='10'
                        viewBox='0 0 10 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.00305 3.0605L1.5478 9.51575L0.487305 8.45525L6.9418 2H1.25305V0.5H9.50305V8.75H8.00305V3.0605Z'
                          fill='#2A81FF'
                        />
                      </svg>
                    </Box>
                  </Flex>
                )
              })
            : indicators.map((indicator, index) => {
                return (
                  <Flex
                    borderBottom={
                      index === indicators.length - 1
                        ? "none"
                        : "1px solid #F0F0F0"
                    }
                    paddingY={5}
                    justifyContent='space-between'
                    alignItems='center'
                    marginBottom={8}
                    key={index}
                    cursor='pointer'
                    _hover=''
                    onClick={() =>
                      history.push(
                        `/databank/external/charts?diseaseId=${disease.id}&indicator=${indicator.id}`,
                        {
                          visualizations:
                            indicator.externalVisualizations[0]
                              .interactive_map_link,
                        }
                      )
                    }
                  >
                    <Text
                      fontFamily='CamptonLight'
                      fontSize={16}
                      fontWeight={400}
                    >
                      {indicator.name}
                    </Text>
                    <Box>
                      <svg
                        width='10'
                        height='10'
                        viewBox='0 0 10 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.00305 3.0605L1.5478 9.51575L0.487305 8.45525L6.9418 2H1.25305V0.5H9.50305V8.75H8.00305V3.0605Z'
                          fill='#2A81FF'
                        />
                      </svg>
                    </Box>
                  </Flex>
                )
              })}
        </Box>
      </Box>
    </Container>
  )
}

export default DiseaseIndicators