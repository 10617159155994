import React from "react";
import { Box } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const GoBack = ({ to }) => {
  let history = useHistory();
  return (
    <>
      <Box
        cursor='pointer'
        color='#2A81FF'
        fontFamily='CamptonMedium'
        fontWeight={500}
        fontSize={16}
        onClick={() => history.push(to)}
      >
        {"< "}Go Back
      </Box>
    </>
  )
};

export default GoBack;
