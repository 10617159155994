import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Divider,
  Container,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import { Space } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../url";

const StatCard = ({ label, total, link }) => {
  let history = useHistory()

  return (
    <Flex width='30%' flexDirection='column' alignItems='center'>
      <Heading fontSize={32} color='white' fontWeight={700}>
        {total === "loading" ? <Spinner color='white' /> : total}
      </Heading>
      <Text
        marginTop={3}
        marginBottom={5}
        fontSize={16}
        color='white'
        fontWeight={500}
      >
        {label}
      </Text>
      <Button
        rounded='2px'
        disabled={total === "loading"}
        height='25px'
        color='white'
        fontSize={12}
        fontWeight={500}
        fontFamily='CamptonMedium'
        background='rgba(0, 0, 0, 0.15)'
        onClick={() => history.push(link)}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        View all
      </Button>
    </Flex>
  )
}

const Overview = () => {
  let history = useHistory()
  // states
  const [totalCountries, settotalCountries] = useState("loading")
  const [totalDiseases, settotalDiseases] = useState("loading")
  const [totalIndicators, settotalIndicators] = useState("loading")
  const [newlyAddedData, setnewlyAddedData] = useState([])
  const [user, setuser] = useState({})
  const [topDiseaseIndicators, settopDiseaseIndicators] = useState([])

  // newly added data columns
  const columns = [
    {
      title: "INDICATOR",
      dataIndex: "indicator",
      key: "indicator",
    },
    {
      title: "DISEASE",
      dataIndex: "disease",
      key: "disease",
    },
    {
      title: "DATE UPLOADED",
      dataIndex: "dateUploaded",
      key: "dateUploaded",
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Space size='middle'>
          <Button
            variant='unstyled'
            size='sm'
            color='#2A81FF'
            // onClick={() =>
            //   history.push({
            //     pathname: `/dashboard/requests/profile`,
            //     state: { id: record.key },
            //   })
            // }
          >
            View
          </Button>
        </Space>
      ),
    },
  ]

  // get countries count
  useEffect(() => {
    axios
      .get(
        `${baseUrl}/overview?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        const { totalCountries, totalDiseases, totalIndicators } =
          response.data.data
        settotalCountries(totalCountries)
        settotalDiseases(totalDiseases)
        settotalIndicators(totalIndicators)
      })
      .catch(err => {
        console.log(err.data)
      })

    // get 3 most viewd indicators

    axios
      .get(
        `${baseUrl}/overview/top-three-disease-indicators?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        settopDiseaseIndicators(response.data.data)
      })
      .catch(err => {
        console.log(err.data)
      })
  }, [])

  useEffect(() => {
    setuser(JSON.parse(window.localStorage.getItem("user")))
  }, [])

  // newly added data
  useEffect(() => {
    axios
      .get(
        `${baseUrl}/new-added-data-list?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        setnewlyAddedData(response.data.data);
        // console.log(response.data.data)
      })
      .catch(err => {
        console.log(err.data);
      });
  }, []);

  return (
    <Box>
      <Box className='container'>
        {/* hello */}

        <Text
          marginTop={20}
          fontWeight={500}
          fontFamily='CamptonMedium'
          fontSize={32}
        >
          <span role='img' aria-label='emoji hand wave'>
            👋🏽
          </span>{" "}
          Hello, {user.first_name}
        </Text>
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonLight'
          color='rgba(0, 0, 0, 0.65)'
          marginTop={2}
        >
          Here's an overview of the epidemiology network today.
        </Text>
        {/* end hello */}
        {/* stats */}
        <Flex
          marginTop={10}
          marginBottom={20}
          background='linear-gradient(149.46deg, #209BFF -25.94%, #0E62FF 78.47%)'
          paddingY={12}
          borderRadius={5}
          paddingX={10}
          justifyContent='space-between'
          alignItems='center'
        >
          <StatCard
            label='Countries'
            total={totalCountries}
            link='/databank/overview/countries'
          />
          <Divider
            orientation='vertical'
            borderColor='rgba(248, 248, 248, 0.5)'
            height={32}
          />
          <StatCard
            label='Diseases'
            total={totalDiseases}
            link='/databank/overview/diseases'
          />
          <Divider
            orientation='vertical'
            borderColor='rgba(248, 248, 248, 0.5)'
            height={32}
          />
          <StatCard
            label='Indicators'
            total={totalIndicators}
            link='/databank/overview/IndicatorsWithDiseases?disease=HIV'
          />
        </Flex>
        {/* send stats */}
        {/* most viewd indicators */}
        <Box marginY={20}>
          <Text fontFamily='CamptonMedium' fontWeight={500} fontSize={20}>
            3 Most Viewed Disease & Indicators
          </Text>
          <Flex
            marginTop={5}
            justifyContent='space-between'
            // alignItems="center"
          >
            {topDiseaseIndicators.map((topDiseaseIndicator, index) => (
              <Box
                key={index}
                width='32%'
                background='rgba(255, 255, 255, 0.5)'
                border='1px solid rgba(103, 121, 142, 0.1)'
                borderRadius={5}
                paddingX={5}
                paddingTop={5}
                paddingBottom={2}
              >
                <Flex
                  justifyContent='space-between'
                  alignItems='center'
                  marginBottom={5}
                >
                  <Text fontSize='18px' fontWeight={700} color='#000000'>
                    {topDiseaseIndicator.name}
                  </Text>
                  <Button
                    display={
                      topDiseaseIndicator.indicators.length > 0
                        ? "flex"
                        : "none"
                    }
                    justifyContent='center'
                    alignItems='center'
                    rounded={2}
                    size='sm'
                    border='1.4px solid #2A81FF'
                    color='#2A81FF'
                    background='transparent'
                    onClick={() =>
                      history.push(
                        "/databank/overview/IndicatorsWithDiseases?disease=" +
                          topDiseaseIndicator.name
                      )
                    }
                  >
                    View
                  </Button>
                </Flex>
                {topDiseaseIndicator.indicators.map((indicator, index) => (
                  <Flex
                    key={indicator.name}
                    justifyContent='space-between'
                    alignItems='center'
                    borderBottom={
                      index === topDiseaseIndicator.indicators.length - 1
                        ? "1px solid transparent"
                        : "1px solid #F0F0F0"
                    }
                    paddingY={4}
                  >
                    <Text fontSize={14}>{indicator.name}</Text>
                    <Text color='#3AA674'>{indicator.percentage}%</Text>
                  </Flex>
                ))}
              </Box>
            ))}
          </Flex>
        </Box>
        {/*end most viewd indicators */}
        {/* newly added data */}
        <Box marginY={20} paddingBottom={10}>
          <Text fontFamily='CamptonMedium' fontWeight={500} fontSize={20}>
            Newly Added data
          </Text>
          <Box marginTop={5} background='white' border='1px solid #eee'>
            <Table variant='striped' colorScheme='gray' width='100%'>
              <Thead>
                <Tr>
                  <Th paddingY={8} style={{ color: "rgba(0,0,0,0.5)" }}>
                    INDICATOR
                  </Th>
                  <Th paddingY={8} style={{ color: "rgba(0,0,0,0.5)" }}>
                    DISEASE
                  </Th>
                  <Th paddingY={8} style={{ color: "rgba(0,0,0,0.5)" }}>
                    DATE UPLOADED
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              {newlyAddedData.length > 0 && (
                <Tbody>
                  <Tr>
                    <Td paddingY={8}>yards</Td>
                    <Td paddingY={8}>metres (m)</Td>
                    <Td paddingY={8}>0.91444</Td>
                    <Td
                      textAlign='right'
                      fontWeight={500}
                      fontSize='14px'
                      fontFamily='CamptonMedium'
                    >
                      <Button color='rgba(42,129,255,1)'>View</Button>
                    </Td>
                  </Tr>
                </Tbody>
              )}
            </Table>
            {newlyAddedData.length === 0 && (
              <Flex
                justifyContent='center'
                alignItems='center'
                width='100%'
                backgroundColor='green'
                height='100px'
                textAlign='center'
                maxW='400px'
                marginX='auto'
              >
                No Data to display
              </Flex>
            )}
          </Box>
        </Box>
        {/*end  newly added data */}
      </Box>
    </Box>
  );
}

export default Overview;
