import React, { useEffect, useState } from "react";
import { Box, Grid, Heading } from "@chakra-ui/react";
import GoBack from "../../components/GoBack";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../../../../url";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import DiseaseCard from "../../components/DiseaseCard";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Diseases = () => {
  const [diseases, setdiseases] = useState([]);
  const [country, setcountry] = useState({});
  let query = useQuery();

  useEffect(() => {
    // get country detail
    axios
      .get(
        `${baseUrl}/country/${query.get(
          "country"
        )}?token=${window.localStorage.getItem("bearerToken")}`
      )
      .then((response) => {
        setcountry(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // get diseaes
    axios
      .get(
        `${baseUrl}/disease/all?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setdiseases(response.data.data);
      });
  }, []);
  return (
    <Box className='container-sm' paddingTop={10}>
      {/* heading */}
      {country.name ? (
        <Heading
          fontFamily='CamptonMedium'
          fontSize={20}
          fontWeight={500}
          marginY={5}
        >
          Select a disease to see the data in <b>{country.name}</b>
        </Heading>
      ) : (
        <GoBack to='/databank/overview' />
      )}
      {/* end heading */}
      <Grid templateColumns='repeat(4, 1fr)' marginTop={10} gap={5}>
        {diseases.length === 0 && (
          <>
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
          </>
        )}
        {diseases.map((disease, index) => (
          <>
            {disease.indicators.length > 0 && (
              <DiseaseCard
                key={index}
                disease={disease}
                link={
                  "/databank/overview/indicators?disease=" +
                  disease.id +
                  "&country=" +
                  country.id
                }
              />
            )}
          </>
        ))}
      </Grid>
    </Box>
  )
};

export default Diseases;
