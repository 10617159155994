import React from "react"
import {
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  VStack,
  Image,
  Heading,
  HStack,
  Input,
} from "@chakra-ui/react"
import { SettingsIcon } from "../Icons"

const PageHeader = ({ title, subtitle, icon }) => (
  <Flex justifyContent='space-between' alignItems='center'>
    <Box>
      <HStack>
        <Heading fontSize={32} fontWeight='500' marginBottom={2}>
          {title}
        </Heading>
      </HStack>
      <Text fontSize={16} fontWeight='300' color='rgba(0, 0, 0, 0.65)'>
        {subtitle}
      </Text>
    </Box>
    {/*  */}
  </Flex>
)

export default PageHeader
