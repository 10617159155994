import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Box, Flex, Heading, Text, Stack, useToast } from "@chakra-ui/react"
import axios from "axios"
import Nav from "./components/Nav"
import { CustomInputPassword } from "./components/CustomInputs"
import FormButton from "./components/FormButton"
import { baseUrl } from "../../url"
import { useQuery } from "../../customhooks/useQuery"

const ResetPasswordPage = () => {
  let toast = useToast()
  let history = useHistory()
  const query = useQuery()

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [token, setToken] = useState("")
  const [isLoading, setisLoading] = useState("")

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      toast({
        title: "Important",
        description: "Password and confirm password do not match",
        status: "info",
        position: "top",
        duration: 2500,
        isClosable: true,
      })
      return
    }
    if (!password || !confirmPassword) {
      toast({
        title: "Important",
        description: "Please enter password and confirm password",
        status: "info",
        position: "top",
        duration: 9000,
        isClosable: true,
      })
    } else {
      setisLoading(true)
      axios
        .patch(`${baseUrl}/password-change`, {
          password,
          password_confirm: confirmPassword,
          token,
        })
        .then(response => {
          setisLoading(false)
          toast({
            position: "top",
            title: "Success!",
            description: `${response.data.message}`,
            status: "success",
            duration: 2500,
            isClosable: true,
          })
          history.push("/login")
        })
        .catch(ex => {
          setisLoading(false)
          if (ex.response) {
            toast({
              position: "top",
              title: "Error!",
              description: "An error  occured, try again",
              status: "error",
              duration: 9000,
              isClosable: true,
            })
          }
        })
    }
  }

  useEffect(() => {
    const queryToken = query.get("token")
    setToken(queryToken)
    return () => {
      return
    }
  }, [token, query])

  return (
    <Box backgroundColor='#F3F7FF' height='100vh'>
      <Box paddingY={5}>
        <Nav showSignupButton={true} />
      </Box>

      <Box
        maxW='603px'
        width='94%'
        paddingX={[2, 20]}
        paddingY={[16]}
        marginX='auto'
        borderRadius={20}
        position='relative'
        top={[8, 20]}
        border='1px solid'
        background={["transparent", "transparent", "white"]}
        borderColor={["transparent", "transparent", "rgba(103, 121, 142, 0.2)"]}
      >
        <Box marginBottom={10}>
          <Heading
            fontFamily='Gordita'
            fontSize={24}
            fontWeight={700}
            color='#1D1E22'
            marginBottom={3}
            textAlign='center'
          >
            Reset Password
          </Heading>
          <Text
            maxW='400px'
            width='100%'
            marginX='auto'
            fontFamily='CamptonLight'
            fontSize={16}
            fontWeight={500}
            color='#1D1E22'
            textAlign='center'
            lineHeight='25px'
          >
            Set your new password
          </Text>
        </Box>
        <Stack spacing={[4, 10]}>
          <Box>
            <CustomInputPassword
              required={true}
              handleInput={value => setPassword(value)}
              value={password}
              width='100%'
              label='New Password'
              inputType='Password'
              placeholder='Enter new password'
            />
          </Box>
          <Box>
            <CustomInputPassword
              required={true}
              handleInput={value => setConfirmPassword(value)}
              value={confirmPassword}
              width='100%'
              label='Confirm New Password'
              inputType='Password'
              placeholder='Enter confirm new password'
            />
          </Box>

          <Flex justifyContent='center' marginTop={0}>
            <FormButton
              label='Reset Password'
              handleButtonAction={handleSubmit}
              loading={isLoading}
              icon=''
            />
          </Flex>
        </Stack>
      </Box>
    </Box>
  )
}

export default ResetPasswordPage
