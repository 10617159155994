import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootreducer from "./reducers"

// export const store = createStore(rootreducer, applyMiddleware(thunk))

const middleware = [thunk]

export const store = createStore(
  rootreducer,
  composeWithDevTools(applyMiddleware(...middleware))
)
