import React from "react";
import {
  Box,
  Text,
  GridItem,
  VStack,
  Image,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { cmsUrl } from "../../url";

export const ArticleCard = ({ basePath, item }) => {
  const history = useHistory();
  const { id, title, thumbnail, categories_shared_learning } = item;

  const thumbnailUrl = thumbnail ? `${cmsUrl}${thumbnail.url}` : '';
  const category = categories_shared_learning?.name;
  return (
    <GridItem
      border="1px solid rgba(103, 121, 142, 0.07)"
      cursor="pointer"
      background="white"
      height={300}
      rounded={10}
      onClick={() => history.push(basePath + id)}
    >
      <VStack
        justifyContent="space-between"
        alignItems="flex-start"
        height="100%"
      >
        <Box height="50%" width="100%">
          <Image
            src={thumbnailUrl}
            rounded={10}
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Box height="50%" paddingX={5} paddingY={2} background="white">
          <Text color="#0E62FF" fontWeight="500" marginBottom={3} fontSize={12}>
            {category}
          </Text>
          <Text color="rgba(33, 29, 86, 0.85)" fontWeight="700">
            {title}
          </Text>
        </Box>
      </VStack>
    </GridItem>
  );
};
