import React, { useState, useEffect } from "react";
import { Box, Heading, Grid, Flex, Image } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import axios from "axios"
import { baseUrl } from "../../../../url"
import Skeleton from "react-loading-skeleton"
import DiseaseCard from "../../components/DiseaseCard"

const ExternalData = () => {
  const [diseases, setdiseases] = useState([])

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/external/diseases?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        setdiseases(response.data.data)
        console.log(response.data.data)
      })
  }, [])

  return (
    <Box className='container-sm' paddingTop={10}>
      <Heading
        fontFamily='CamptonLight'
        fontSize={20}
        fontWeight={500}
        marginY={5}
      >
        Select a disease to see the data
      </Heading>
      {/* diseases */}

      <Grid templateColumns='repeat(4, 1fr)' marginTop={10} gap={5}>
        {diseases.length === 0 && (
          <>
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
          </>
        )}
        {diseases.map((disease, index) => (
          <DiseaseCard
            key={index}
            disease={disease}
            link={"/databank/external/indicators?disease=" + disease.id}
          />
        ))}
        {/* TEMPORARY FOR HACKATHON */}
        {diseases.length > 0 && (
          <Flex
            as={Link}
            cursor='pointer'
            outline='none'
            _hover={{ background: "rgba(191, 227, 245, 1)" }}
            w='100%'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            p={5}
            h={48}
            bg='rgba(191, 227, 245, 0.6)'
            borderRadius={50}
            to={`/databank/external/indicators?disease=fbdfg`}
          >
            <Image
              boxSize='56px'
              src={
                "https://facebookbrand.com/wp-content/uploads/2019/10/flogo_RGB_HEX-BRC-Site-250.png?w=125&h=125"
              }
            />
            <Heading
              marginTop={5}
              fontSize={20}
              fontWeight={700}
              fontFamily='CamptonBold'
              textAlign='center'
            >
              Facebook Data for Good
            </Heading>
          </Flex>
        )}
      </Grid>
    </Box>
  )
}

export default ExternalData;
