import React from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  Divider,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { baseUrl } from "../../url";

const Subscribe = () => {
  let toast = useToast();

  const [email, setEmail] = React.useState("");
  const [isLoading, setisLoading] = React.useState(false);

  const fireToast = (title = "Error!", description, status = "error") => {
    toast({
      position: "bottom",
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();

    if (!email) {
      fireToast("Important", "Email is required.", "info");
    } else {
      setisLoading(true);
      await axios
        .post(`${baseUrl}/email/subscription`, {
          email,
        })
        .then(res => {
          setEmail("");
          setisLoading(false);
          fireToast("Subscribed", `${res?.data?.message}`, "success");
        })
        .catch(error => {
          setisLoading(false);
          if (error.response) {
            error?.response?.data?.data?.errors
              ? fireToast(
                  undefined,
                  `${email} is already subscribed.`,
                  undefined
                )
              : fireToast(
                  undefined,
                  `${error?.response?.data?.message}`,
                  undefined
                );
          } else {
            fireToast("An error occured", "please try again.", undefined);
          }
        });
    }
  };

  return (
    <Box background='#FCFCFE'>
      <Flex
        paddingY={[20, 20, 32]}
        className='container'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        flexDirection={["column", "column", "row"]}
        textAlign={["center", "center", "left"]}
      >
        <Text
          fontSize={22}
          fontFamily='CamptonMedium'
          fontWeight={700}
          color='#2C2C2C'
          width={["100%", "100%", "48%"]}
          lineHeight='32px'
          marginBottom={[8, 8, 0]}
        >
          Get updates on the latest information on digital epidemiology, other
          public health issues, current trends in public health practices.
        </Text>
        <Box width={["100%", "100%", "48%"]}>
          <form>
            <Flex
              alignItems='center'
              justifyContent={["space-between", "space-between", "flex-end"]}
              flexWrap='wrap'
            >
              <Input
                width={["100%", "60%", "auto"]}
                type='email'
                size='lg'
                border='1px solid rgba(130, 127, 152, 0.3)'
                marginRight={[0, 3]}
                marginBottom={[4, 0, 0]}
                placeholder='Email Address'
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
              <Button
                width={["100%", "35%", "auto"]}
                size='lg'
                background='#2A81FF'
                color='white'
                borderRadius={6}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Subscribe
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
      <Box className='container'>
        <Divider margin={0} borderColor='rgba(130, 127, 152, 0.3)' />
      </Box>
    </Box>
  );
};

export default Subscribe;
