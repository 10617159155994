import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Grid,
  Flex,
  Button,
  Modal,
  useToast,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  // Heading,
} from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { baseUrl } from "../../../../../url"
import axios from "axios"
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts"

import {
  CommonCustomInput,
  CommonCustomTextarea,
} from "../../../../../components/inputsComponent"
import RequestData from "../../RequestData"

import { useQuery } from "../../../../../customhooks/useQuery"

const GetRiskyBehaviorData = () => {
  let location = useLocation()
  let query = useQuery()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [firstAndMiddleName, setFirstAndMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [position, setPosition] = useState("")
  const [requestReason, setRequestReason] = useState("")
  const [disease, setDisease] = useState("")
  const [dataRequestLoading, setDataRequestLoading] = useState(false)

  const closeModal = () => {
    onClose()
  }

  const setInitialStates = () => {
    const userInfo = JSON.parse(localStorage.getItem("user"))

    setLastName(userInfo.last_name)
    setEmail(userInfo.login_email)
    setPosition(userInfo.current_occupation || "")
    setFirstAndMiddleName(userInfo.first_name)
  }

  const requestData = () => {
    let data = {
      data: disease,
      reason: requestReason,
    }
    if (!requestReason) {
      toast({
        title: "Important",
        description: "Please enter reason for data request.",
        status: "info",
        position: "top",
        duration: 5000,
        isClosable: true,
      })
      return
    }
    setDataRequestLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = { headers: { Authorization: `Bearer ${token}` } }
    axios
      .post(`${baseUrl}/data-request`, data, config)
      .then(res => {
        // console.log(res, "data req res")
        setDataRequestLoading(false)
        closeModal()
        toast({
          title: "Important",
          description: "Data requested successfully.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(ex => {
        setDataRequestLoading(false)
        toast({
          title: "Something went wrong",
          description: "Check internet connection and retry.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
  }

  // previousely tested postive to STI
  const [
    previouslyTestedPositiveToSTIFemale,
    setpreviouslyTestedPositiveToSTIFemale,
  ] = useState([])
  const [
    previouslyTestedPositiveToSTIMale,
    setpreviouslyTestedPositiveToSTIMale,
  ] = useState([])
  // shared Needle And Injection
  const [sharedNeedleAndInjectionFemale, setsharedNeedleAndInjectionFemale] =
    useState([])
  const [sharedNeedleAndInjectionMale, setsharedNeedleAndInjectionMale] =
    useState([])
  // unprotected anal sex
  const [unprotectedAnalSexFemale, setunprotectedAnalSexFemale] = useState([])
  const [unprotectedAnalSexMale, setunprotectedAnalSexMale] = useState([])
  // unprotected vaginal sex
  const [unprotectedVaginalSexFemale, setunprotectedVaginalSexFemale] =
    useState([])
  const [unprotectedVaginalSexMale, setunprotectedVaginalSexMale] = useState([])

  const formatPreviouslyTestedPositiveToSTI = data => {
    //female
    let arrayedDataFemale = []
    var partner = data.female.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataFemale.push(partner)
    var self = data.female.self
    Object.assign(self, { name: "Self" })
    arrayedDataFemale.push(self)
    setpreviouslyTestedPositiveToSTIFemale(arrayedDataFemale)
    // male
    let arrayedDataMale = []
    partner = data.male.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataMale.push(partner)
    self = data.male.self
    Object.assign(self, { name: "Self" })
    arrayedDataMale.push(self)
    setpreviouslyTestedPositiveToSTIMale(arrayedDataMale)
  }

  const formatSharedNeedleAndInjection = data => {
    let arrayedDataFemale = []
    var partner = data.female.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataFemale.push(partner)
    var self = data.female.self
    Object.assign(self, { name: "Self" })
    arrayedDataFemale.push(self)
    setsharedNeedleAndInjectionFemale(arrayedDataFemale)
    // male
    let arrayedDataMale = []
    partner = data.male.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataMale.push(partner)
    self = data.male.self
    Object.assign(self, { name: "Self" })
    arrayedDataMale.push(self)
    setsharedNeedleAndInjectionMale(arrayedDataMale)
  }

  const formatUnprotectedAnalSex = data => {
    let arrayedDataFemale = []
    var partner = data.female.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataFemale.push(partner)
    var self = data.female.self
    Object.assign(self, { name: "Self" })
    arrayedDataFemale.push(self)
    setunprotectedAnalSexFemale(arrayedDataFemale)
    // male
    let arrayedDataMale = []
    partner = data.male.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataMale.push(partner)
    self = data.male.self
    Object.assign(self, { name: "Self" })
    arrayedDataMale.push(self)
    setunprotectedAnalSexMale(arrayedDataMale)
  }

  const formatUnprotectedVaginalSex = data => {
    let arrayedDataFemale = []
    var partner = data.female.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataFemale.push(partner)
    var self = data.female.self
    Object.assign(self, { name: "Self" })
    arrayedDataFemale.push(self)
    setunprotectedVaginalSexFemale(arrayedDataFemale)
    // male
    let arrayedDataMale = []
    partner = data.male.partner
    Object.assign(partner, { name: "Partner" })
    arrayedDataMale.push(partner)
    self = data.male.self
    Object.assign(self, { name: "Self" })
    arrayedDataMale.push(self)
    setunprotectedVaginalSexMale(arrayedDataMale)
  }

  useEffect(() => {
    setInitialStates()
    const diseaseName = query.get("diseaseName")
    const indicatorPathname = query.get("indicatorPathname")
    setDisease(indicatorPathname)

    axios
      .get(
        `${baseUrl}/disease/${diseaseName.toLowerCase()}/indicator/${indicatorPathname}?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        // previouslu tested + to STI
        formatPreviouslyTestedPositiveToSTI(
          response.data.data.previouslyTestedPositiveToSTI
        )
        // shared needle and injection
        formatSharedNeedleAndInjection(
          response.data.data.sharedNeedleAndInjection
        )
        // unprotecetd anal sex
        formatUnprotectedAnalSex(response.data.data.unprotectedAnalSex)
        // unprotecetd vaginal sex
        formatUnprotectedVaginalSex(response.data.data.unprotectedVaginalSex)
      })
      .catch(error => {
        console.log(error)
      })

    console.log(diseaseName, "disead=se name")
    console.log(indicatorPathname, "indicator pathname")
  }, [location.state])

  return (
    <Box paddingTop={10}>
      {/* <Heading marginTop={20} fontSize={18}>
        {location.state.indicator.name}
      </Heading> */}
      {/* Previously Tested Positive To STI */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
        paddingTop={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Previously Tested Positive To STI
        </Text>
        <Flex justifyContent='space-between'>
          <Box width='50%'>
            <BarChart
              width={500}
              height={400}
              data={previouslyTestedPositiveToSTIFemale}
            >
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'> Female</Text>
          </Box>
          <Box width='50%'>
            <BarChart
              width={500}
              height={400}
              data={previouslyTestedPositiveToSTIMale}
            >
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'>Male</Text>
          </Box>
        </Flex>
      </Box>

      {/* Shared needle and injection */}
      <Box background='#fff' marginTop={10} padding={10} marginBottom={20}>
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Shared Needle And Injection Male
        </Text>
        <Flex justifyContent='space-between'>
          <Box width='50%'>
            <BarChart
              width={500}
              height={400}
              data={sharedNeedleAndInjectionFemale}
            >
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'> Female</Text>
          </Box>
          <Box width='50%'>
            <BarChart
              width={500}
              height={400}
              data={sharedNeedleAndInjectionMale}
            >
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'>Male</Text>
          </Box>
        </Flex>
      </Box>

      {/* Unprotected Anal sex */}
      <Box background='#fff' marginTop={10} padding={10} marginBottom={20}>
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Unprotected Anal sex
        </Text>
        <Flex justifyContent='space-between'>
          <Box width='50%'>
            <BarChart width={500} height={400} data={unprotectedAnalSexFemale}>
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'> Female</Text>
          </Box>
          <Box width='50%'>
            <BarChart width={500} height={400} data={unprotectedAnalSexMale}>
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'>Male</Text>
          </Box>
        </Flex>
      </Box>
      {/* Unprotected Vaginal sex */}
      <Box background='#fff' marginTop={10} padding={10} marginBottom={20}>
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Unprotected Vaginal sex
        </Text>
        <Flex justifyContent='space-between'>
          <Box width='50%'>
            <BarChart
              width={500}
              height={400}
              data={unprotectedVaginalSexFemale}
            >
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'> Female</Text>
          </Box>
          <Box width='50%'>
            <BarChart width={500} height={400} data={unprotectedVaginalSexMale}>
              <XAxis dataKey='name' stroke='#000' />
              <YAxis domain={[0, 200]} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <Bar
                dataKey='positive'
                fill='#0E62FF'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='notSure'
                fill='#FFBF56'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
              <Bar
                dataKey='negative'
                fill='#50E3C2'
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>
            <Text textAlign='center'>Male</Text>
          </Box>
        </Flex>
      </Box>

      {/*Request Data Banner*/}
      <Box paddingBottom={20}>
        <RequestData handleClick={() => onOpen()} />
      </Box>

      {/* DATA REQUEST MODAL */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='xl'
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request for Data on HIV </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid gap={6} templateColumns='repeat(2, 1fr)'>
              <Box>
                <CommonCustomInput
                  isDisabled={true}
                  value={firstAndMiddleName}
                  width='100%'
                  label='First name'
                  inputType='text'
                  placeholder='John'
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  isDisabled={true}
                  value={lastName}
                  width='100%'
                  label='Last name'
                  inputType='text'
                  placeholder='Doe'
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Grid gap={6} templateColumns='repeat(2, 1fr)' marginTop={6}>
              <Box>
                <CommonCustomInput
                  isDisabled={true}
                  value={email}
                  width='100%'
                  label='Email Address'
                  inputType='email'
                  placeholder='example@email.com'
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  isDisabled={true}
                  value={position}
                  width='100%'
                  label='Current Position'
                  inputType='text'
                  placeholder='UX Designer'
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Box marginTop={10}>
              <CommonCustomTextarea
                handleInput={value => setRequestReason(value)}
                value={requestReason}
                width='100%'
                label='Reason for Data Request'
                placeholder='Enter your reason for requesting this data'
                inputType='text'
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              paddingX={8}
              paddingY={6}
              fontSize={15}
              colorScheme='blue'
              variant='outline'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              fontSize={15}
              paddingX={8}
              paddingY={6}
              ml={5}
              onClick={requestData}
              isLoading={dataRequestLoading}
            >
              Request Data
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default GetRiskyBehaviorData;
