import React, { useState, useEffect } from "react"
import {
  Box,
  Text,
  Grid,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  // Heading,
} from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { baseUrl } from "../../../../../url"
import axios from "axios"
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts"

import {
  CommonCustomInput,
  CommonCustomTextarea,
} from "../../../../../components/inputsComponent"
import RequestData from "../../RequestData"

import { useQuery } from "../../../../../customhooks/useQuery"

const PresentingForFreeTest = () => {
  let location = useLocation()
  let query = useQuery()
    const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [testsByAgeGroup, settestsByAgeGroup] = useState({})
  const [testsByEducation, settestsByEducation] = useState({})
  const [testsByGender, settestsByGender] = useState({})
  const [testsByMaritalStatus, settestsByMaritalStatus] = useState({})
  const [testsByResidenceType, settestsByResidenceType] = useState({})

  const [firstAndMiddleName, setFirstAndMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [position, setPosition] = useState("")
  const [requestReason, setRequestReason] = useState("")
    const [disease, setDisease] = useState("")
    const [dataRequestLoading, setDataRequestLoading] = useState(false)

  const closeModal = () => {
    onClose()
  }

  const setInitialStates = () => {
    const userInfo = JSON.parse(localStorage.getItem("user"))

    setLastName(userInfo.last_name)
    setEmail(userInfo.login_email)
    setPosition(userInfo.current_occupation || "")
    setFirstAndMiddleName(userInfo.first_name)
  }

  const requestData = () => {
    let data = {
      data: disease,
      reason: requestReason,
    }
    if (!requestReason) {
      toast({
        title: "Important",
        description: "Please enter reason for data request.",
        status: "info",
        position: "top",
        duration: 5000,
        isClosable: true,
      })
      return
    }
    setDataRequestLoading(true)
    const token = localStorage.getItem("bearerToken")
    const config = { headers: { Authorization: `Bearer ${token}` } }
    axios
      .post(`${baseUrl}/data-request`, data, config)
      .then(res => {
        // console.log(res, "data req res")
        setDataRequestLoading(false)
        closeModal()
        toast({
          title: "Important",
          description: "Data requested successfully.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(ex => {
        setDataRequestLoading(false)
        toast({
          title: "Something went wrong",
          description: "Check internet connection and retry.",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        })
      })
  }

  useEffect(() => {
    setInitialStates()
    const diseaseName = query.get("diseaseName")
    const indicatorPathname = query.get("indicatorPathname")
    setDisease(indicatorPathname)

    const testsByAgeGroup = data => {
      let arrayedData = []
      let age10To19 = data["10-19"]
      Object.assign(age10To19, { name: "10-19" })
      arrayedData.push(age10To19)

      let age20To29 = data["20-29"]
      Object.assign(age20To29, { name: "20-29" })
      arrayedData.push(age20To29)

      let age30To39 = data["30-39"]
      Object.assign(age30To39, { name: "30-39" })
      arrayedData.push(age30To39)

      let age40To49 = data["40-49"]
      Object.assign(age40To49, { name: "40-49" })
      arrayedData.push(age40To49)

      let age50To59 = data["50-59"]
      Object.assign(age50To59, { name: "50-59" })
      arrayedData.push(age50To59)

      let age60To69 = data["60-69"]
      Object.assign(age60To69, { name: "60-69" })
      arrayedData.push(age60To69)

      let age70To79 = data["70-79"]
      Object.assign(age70To79, { name: "70-79" })
      arrayedData.push(age70To79)

      let age80Above = data["80 and above"]
      Object.assign(age80Above, { name: "80 and above" })
      arrayedData.push(age80Above)

      settestsByAgeGroup(arrayedData)
    }

    const testsByEducation = data => {
      let arrayedData = []
      let bachelors = data["bachelors"]
      Object.assign(bachelors, { name: "Bachelors" })
      arrayedData.push(bachelors)

      let postgraduate = data["postgraduate"]
      Object.assign(postgraduate, { name: "Post Graduate" })
      arrayedData.push(postgraduate)

      let seniorSecondarySchool = data["seniorSecondarySchool"]
      Object.assign(seniorSecondarySchool, { name: "Senior Secondary School" })
      arrayedData.push(seniorSecondarySchool)

      let vocationalOrTechnicalEducation =
        data["vocationalOrTechnicalEducation"]
      Object.assign(vocationalOrTechnicalEducation, {
        name: "Vocational Or Technical Education",
      })
      arrayedData.push(vocationalOrTechnicalEducation)

      settestsByEducation(arrayedData)
    }

    const testsByGender = data => {
      let arrayedData = []
      let female = data["FEMALE"]
      Object.assign(female, { name: "Female" })
      arrayedData.push(female)

      let male = data["MALE"]
      Object.assign(male, { name: "Male" })
      arrayedData.push(male)
      settestsByGender(arrayedData)
    }

    const testsByMaritalStatus = data => {
      let arrayedData = []
      let married = data["married"]
      Object.assign(married, { name: "Married" })
      arrayedData.push(married)

      let neverMarried = data["neverMarried"]
      Object.assign(neverMarried, { name: "Never Married" })
      arrayedData.push(neverMarried)
      settestsByMaritalStatus(arrayedData)
    }

    const testsByResidenceType = data => {
      let arrayedData = []
      let rural = data["rural"]
      Object.assign(rural, { name: "Rural" })
      arrayedData.push(rural)

      let semiUrban = data["semiUrban"]
      Object.assign(semiUrban, { name: "Semi Urban" })
      arrayedData.push(semiUrban)

      let urban = data["urban"]
      Object.assign(urban, { name: "Urban" })
      arrayedData.push(urban)

      settestsByResidenceType(arrayedData)
    }

    axios
      .get(
        `${baseUrl}/disease/${diseaseName.toLowerCase()}/indicator/${indicatorPathname}?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then(response => {
        testsByAgeGroup(response.data.data.testsByAgeGroup.ageGroupsCount)
        testsByEducation(
          response.data.data.testsByEducation.educationLevelCount
        )
        testsByGender(response.data.data.testsByGender.genderCount)
        testsByMaritalStatus(
          response.data.data.testsByMaritalStatus.maritalStatusCount
        )
        testsByResidenceType(
          response.data.data.testsByResidenceType.locationCount
        )
      })
      .catch(error => {
        console.log(error)
      })
  }, [location.state])

  return (
    <Box paddingTop={10}>
      {/* <Heading marginTop={20} fontSize={18}>
        {location.state.indicator.name}
      </Heading> */}
      {/* tests by age group */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Age Group
        </Text>
        <BarChart width={1050} height={449} data={testsByAgeGroup}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='show'
            fill='#0E62FF'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
          <Bar
            dataKey='noShow'
            fill='#50E3C2'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by education */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Education
        </Text>
        <BarChart width={1050} height={449} data={testsByEducation}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='show'
            fill='#0E62FF'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
          <Bar
            dataKey='noShow'
            fill='#50E3C2'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by gender */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Gender
        </Text>
        <BarChart width={1050} height={449} data={testsByGender}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='show'
            fill='#0E62FF'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
          <Bar
            dataKey='noShow'
            fill='#50E3C2'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by marital status */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Marital Status
        </Text>
        <BarChart width={1050} height={449} data={testsByMaritalStatus}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='show'
            fill='#0E62FF'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
          <Bar
            dataKey='noShow'
            fill='#50E3C2'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/* tests by Residence Type */}
      <Box
        background='#fff'
        marginTop={10}
        marginBottom={20}
        paddingBottom={10}
      >
        <Text
          fontSize={16}
          fontWeight={500}
          fontFamily='CamptonMedium'
          paddingX={10}
          paddingY={5}
          marginBottom={10}
          borderBottom='1px solid #eee'
        >
          Tests by Residence Type
        </Text>
        <BarChart width={1050} height={449} data={testsByResidenceType}>
          <XAxis dataKey='name' stroke='#000' />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <Bar
            dataKey='show'
            fill='#0E62FF'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
          <Bar
            dataKey='noShow'
            fill='#50E3C2'
            barSize={50}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </Box>

      {/*Request Data Banner*/}
      <Box paddingBottom={20}>
        <RequestData handleClick={() => onOpen()} />
      </Box>

      {/* DATA REQUEST MODAL */}
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request for Data on Covid-19 </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid gap={6} templateColumns='repeat(2, 1fr)'>
              <Box>
                <CommonCustomInput
                  value={firstAndMiddleName}
                  width='100%'
                  label='First name'
                  inputType='text'
                  placeholder='John'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  // handleInput={value => setLastName(value)}
                  value={lastName}
                  width='100%'
                  label='Last name'
                  inputType='text'
                  placeholder='Doe'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Grid gap={6} templateColumns='repeat(2, 1fr)' marginTop={6}>
              <Box>
                <CommonCustomInput
                  value={email}
                  width='100%'
                  label='Email Address'
                  inputType='email'
                  placeholder='example@email.com'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
              <Box>
                <CommonCustomInput
                  value={position}
                  width='100%'
                  label='Current Position'
                  inputType='text'
                  placeholder='UX Designer'
                  isDisabled={true}
                  labelFontSize={13}
                />
              </Box>
            </Grid>
            <Box marginTop={10}>
              <CommonCustomTextarea
                handleInput={value => setRequestReason(value)}
                value={requestReason}
                width='100%'
                label='Reason for Data Request'
                placeholder='Enter your reason for requesting this data'
                inputType='text'
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              paddingX={8}
              paddingY={6}
              fontSize={15}
              colorScheme='blue'
              variant='outline'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              fontSize={15}
              paddingX={8}
              paddingY={6}
              ml={5}
              onClick={requestData}
              isLoading={dataRequestLoading}
            >
              Request Data
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default PresentingForFreeTest
