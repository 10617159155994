import React, { useState, useEffect } from "react";
import { Box, Heading, Grid } from "@chakra-ui/react";
import axios from "axios";
import { baseUrl } from "../../../../url";
import Skeleton from "react-loading-skeleton";
import DiseaseCard from "../../components/DiseaseCard";

const InternalData = () => {
  const [diseases, setdiseases] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/disease/all?token=${window.localStorage.getItem(
          "bearerToken"
        )}`
      )
      .then((response) => {
        setdiseases(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box className='container-sm' paddingTop={10}>
      <Heading
        fontFamily='CamptonMedium'
        fontSize={20}
        fontWeight={500}
        marginY={5}
      >
        Select a disease to see the data
      </Heading>
      {/* diseases */}

      <Grid templateColumns='repeat(4, 1fr)' marginTop={10} gap={5}>
        {diseases.length === 0 && (
          <>
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
            <Skeleton width='100%' height={180} />
          </>
        )}
        {diseases.map((disease, index) => (
          <>
            {disease.indicators.length > 0 && (
              <DiseaseCard
                key={index}
                disease={disease}
                link={"/databank/internal/Indicators?disease=" + disease.id}
              />
            )}
          </>
        ))}
      </Grid>
    </Box>
  )
};

export default InternalData;
