import React from "react";
import { Text, Flex, Avatar, Button } from "@chakra-ui/react";
import { SendIcon, RequestIcon } from "../Icons";

const Member = ({ member, openChat, openModal }) => {
  const {
    first_name,
    last_name,
    current_occupation,
    organization_name,
    message_link_id,
    avatar,
    is_message_approved,
  } = member;

  return (
    <Flex
      backgroundColor="rgba(255, 255, 255, 0.4)"
      border="1px solid rgba(103, 121, 142, 0.15)"
      borderRadius="5px"
      p="30px"
      flexDirection="column"
      alignItems="center"
    >
      <Avatar
        name={`${first_name} ${last_name}`}
        src={`${process.env.REACT_APP_URL}/${avatar}`}
        boxSize="160px"
        mb="30px"
      />
      <Text
        color="rgba(0, 0, 0, 0.8)"
        fontSize={20}
        fontWeight={500}
        textAlign="center"
        mb="6px"
      >
        {`${first_name} ${last_name}`}
      </Text>
      <Text
        color="rgba(0, 0, 0, 0.8)"
        fontSize={15}
        fontWeight={400}
        textAlign="center"
      >
        {organization_name && organization_name}
      </Text>
      <Text
        color="rgba(0, 0, 0, 0.8)"
        fontSize={15}
        fontWeight={400}
        textAlign="center"
      >
        {current_occupation && current_occupation}
      </Text>

      {is_message_approved === 1 && (
        <Button
          leftIcon={<SendIcon />}
          flexDirection="row"
          mt="15px"
          border="1px solid #0E62FF"
          color="#0E62FF"
          backgroundColor="#FAFAFA"
          fontSize="13px"
          h="33px"
          _hover=""
          onClick={() => openChat(message_link_id)}
        >
          Send Message
        </Button>
      )}
      {is_message_approved === null && (
        <Button
          leftIcon={<RequestIcon />}
          flexDirection="row"
          mt="15px"
          border="1px solid #0E62FF"
          color="#0E62FF"
          backgroundColor="#FAFAFA"
          fontSize="13px"
          h="33px"
          _hover=""
          onClick={() => openModal(member)}
        >
          Send Request
        </Button>
      )}
      {is_message_approved === 0 && (
        <Text color="#0E62FF" fontWeight={500}>
          Message Request Sent
        </Text>
      )}
    </Flex>
  );
};

export default Member;
