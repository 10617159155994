export default {
  first_name: "",
  last_name: "",
  phone: "",
  country: "",
  city: "",
  join_reason: "",

  referee_1_name: "",
  referee_1_email: "",
  hear_about_us: "",

  resume_file: "",
  linkedin_profile: "",
  organization_name: "",
  current_occupation: "",
  professional_email: "",
  expertise: "",

  login_email: "",
  password: "",
};
