import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Skeleton } from "@chakra-ui/react"
import { ArticleCard } from "../../../components/sharedLearning/cards"
import PageHeader from "../../../components/sharedLearning/pageHeader"
import { cmsUrl } from "../../../url"

const objMap = {
  articles: "Article",
  reports: "Report",
  interactives: "Interactive",
}

const All = () => {
  const { category } = useParams()
  const [articles, setArticles] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [filtered, setFiltered] = useState(null)
  const [loaded, setLoaded] = useState(true) //as required by chakra Skeleton isLoaded prop

  const PageTitle = () => {
    return category === "articles"
      ? "📰 Articles"
      : category === "reports"
      ? "📚 Reports"
      : category === "interactives"
      ? "🔖 Interactives"
      : "📖 Shared Learning";
  };

  const PageSubtitle = () => {
    return category === "articles"
      ? "Our latest articles on Public health."
      : category === "reports"
      ? "Our latest reports on Public health."
      : category === "interactives"
      ? "Our latest interactives on Public health."
      : "Our latest articles and publications on Public health.";
  };

  const searchSharedData = e => {
    setSearchQuery(e);

    const searchResult = articles.filter(article => {
      const regex = new RegExp(`${e}`, "gi");
      return article.title.match(regex);
    });

    setFiltered(searchResult);
  };

  useEffect(() => {
    setFiltered(null);
    setSearchQuery("");
    const fetchArticles = category => {
      setLoaded(false);
      const query =
        category !== "all"
          ? `?categories_shared_learning.name=${objMap[category]}`
          : "";
      console.log(`${cmsUrl}/shared-learnings${query}`);
      fetch(`${cmsUrl}/shared-learnings${query}`)
        .then(res => res.json())
        .then(resJson => {
          console.log(resJson);
          if(!resJson.hasOwnProperty('error')){
            setArticles(resJson);
          }
          setLoaded(true);
        })
        .catch(ex => {
          setLoaded(true);
          console.log(ex);
        });
    };
    fetchArticles(category);
  }, [category]);

  return (
    <Box className='container'>
      <Box marginTop={10}>
        <PageHeader
          title={PageTitle()}
          subtitle={PageSubtitle()}
          icon=''
          searchPlaceholder='Search for Publications'
          value={searchQuery}
          handleInput={value => searchSharedData(value)}
        />
      </Box>

      <Grid
        marginY={10}
        templateColumns={["1fr", "1fr", "1fr 1fr", "repeat(3, 1fr)"]}
        // templateColumns="repeat(auto-fit, minmax(360px, 1fr))"
        gap={5}
      >
        {filtered !== null
          ? filtered.map(item => (
              <Skeleton key={item.id} isLoaded={loaded}>
                <ArticleCard
                  key={item.id}
                  basePath='/shared-learning/all/'
                  item={item}
                />
              </Skeleton>
            ))
          : articles.map(item => (
              <Skeleton key={item.id} isLoaded={loaded}>
                <ArticleCard
                  key={item.id}
                  basePath='/shared-learning/all/'
                  item={item}
                />
              </Skeleton>
            ))}
      </Grid>
    </Box>
  );
}

export default All;
