import React, { useState, useCallback } from "react";
import {
  Box,
  Input,
  Textarea,
  Text,
  Image,
  FormControl,
  Flex,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import UploadIcon from "../../../assets/upload.svg";
import EyeIcon from "../../../assets/eye.svg";

export const CustomInput = ({
  placeholder,
  label,
  width,
  handleInput,
  value,
  required,
}) => {
  const [isFocused, setisFocused] = useState(false);

  const handleFocus = () => setisFocused(true);
  const handleBlur = () => setisFocused(false);
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderStyle='solid'
      borderWidth={1.5}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      background='#fff'
      style={{ borderColor: isFocused ? "#2A81FF" : "#ddd" }}
    >
      <FormLabel
        htmlFor='email'
        color='#616164'
        fontFamily='CamptonLight'
        fontWeight={600}
        fontSize={15}
        margin={0}
      >
        {label}
      </FormLabel>

      <Input
        onBlur={handleBlur}
        onFocus={handleFocus}
        // focusBorderColor="transparent"
        // errorBorderColor="transparent"
        _placeholder={{ color: "#ddd", fontWeight: 400 }}
        fontWeight={500}
        fontSize={18}
        fontFamily='CamptonLight'
        value={value}
        onChange={e => handleInput(e.target.value)}
        variant='unstyled'
        placeholder={placeholder}
        border='none'
        type='text'
      />
    </FormControl>
  )
};

export const CustomInputPassword = ({
  placeholder,
  handleInput,
  value,
  label,
  width,
  required,
}) => {
  const [visible, toggleVisibility] = useState(false);
  const [isFocused, setisFocused] = useState(false);

  const handleFocus = () => setisFocused(true);
  const handleBlur = () => setisFocused(false);

  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1.5}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      background='#fff'
      style={{ borderColor: isFocused ? "#2A81FF" : "#ddd" }}
    >
      <Box width='90%'>
        <FormLabel
          htmlFor='email'
          color='#616164'
          fontFamily='CamptonLight'
          fontWeight={400}
          fontSize={15}
          margin={0}
        >
          {label}
        </FormLabel>
        <Input
          onBlur={handleBlur}
          onFocus={handleFocus}
          _placeholder={{ color: "#ddd", fontWeight: 400 }}
          fontWeight={500}
          fontSize={18}
          fontFamily='CamptonLight'
          value={value}
          onChange={e => handleInput(e.target.value)}
          variant='unstyled'
          placeholder={placeholder}
          border='none'
          type={visible ? "text" : "password"}
        />
      </Box>
      <Image onClick={() => toggleVisibility(!visible)} src={EyeIcon} />
    </FormControl>
  )
};

export const CustomTextarea = ({
  placeholder,
  inputType,
  handleInput,
  value,
  label,
  width,
  required,
}) => {
  const [isFocused, setisFocused] = useState(false);

  const handleFocus = () => setisFocused(true);
  const handleBlur = () => setisFocused(false);
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1.5}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      background='#fff'
      style={{ borderColor: isFocused ? "#2A81FF" : "#ddd" }}
    >
      <FormLabel
        htmlFor='email'
        color='#616164'
        fontWeight={400}
        fontFamily='CamptonLight'
        fontSize={15}
        margin={0}
      >
        {label}
      </FormLabel>
      <Textarea
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        onChange={e => handleInput(e.target.value)}
        _placeholder={{ color: "#ddd", fontWeight: 400 }}
        fontWeight={500}
        fontSize={18}
        fontFamily='CamptonLight'
        variant='unstyled'
        placeholder={placeholder}
        border='none'
        type={inputType}
      />
    </FormControl>
  )
};

export const CustomDropDown = ({
  placeholder,
  handleOption,
  value,
  options,
  label,
  width,
  required,
}) => {
  return (
    <FormControl
      isRequired={required}
      width={width}
      borderColor='#ddd'
      borderStyle='solid'
      borderWidth={1}
      borderRadius={10}
      paddingY={2}
      paddingX={4}
      background='#fff'
    >
      <FormLabel
        htmlFor='email'
        color='#616164'
        fontFamily='CamptonLight'
        fontWeight={600}
        fontSize={15}
        margin={0}
      >
        {label}
      </FormLabel>
      <Select
        placeholder={placeholder}
        border='none'
        background='transparent'
        _hover=''
        _focus=''
        fontWeight={500}
        fontSize={18}
        fontFamily='CamptonLight'
        padding={0}
        value={value}
        onChange={e => handleOption(e.target.value)}
      >
        {options.map((option, index) => {
          return (
            <option key={index} value={option.name}>
              {option.name}
            </option>
          )
        })}
      </Select>
    </FormControl>
  )
};

export const Upload = ({ heading, value, handleUpload }) => {
  const maxSize = 1048576;

  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles)
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    // accept: 'image/png',
    minSize: 0,
    maxSize,
  });

  // const isFileTooLarge =
  //   rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize

  return (
    <Box>
      <Text
        marginBottom={5}
        color="#606164"
        fontSize={15}
        fontWeight={400}
        fontFamily="CamptonLight"
        lineHeight="20px"
      >
        {heading}
      </Text>
      <Flex
        {...getRootProps()}
        borderRadius={10}
        borderStyle="dashed"
        borderWidth={1}
        width="100%"
        borderColor="#B8B9BD"
        padding={8}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <input
          value={value}
          onChange={(e) => {
            // let formData = e ? new FormData(e.target.value[0]) : ''
            // axios
            //   .post(
            //     `https://cors-anywhere.herokuapp.com/${baseUrl}/registration/upload`,
            //     formData,
            //     {
            //       headers: {
            //         'Content-Type': 'multipart/form-data',
            //       },
            //     },
            //   )
            //   .then((response) => {
            //     console.log(response)
            //     alert()
            //   })
            handleUpload(e.target.value);
          }}
          {...getInputProps()}
        />
        <Image src={UploadIcon} marginBottom={5} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Flex>
              <Text
                fontSize={16}
                fontWeight={400}
                fontFamily="CamptonLight"
                marginRight={1}
              >
                Drop file here or
              </Text>
              <Text
                color="#2A81FF"
                fontFamily="CamptonLight"
                fontWeight={400}
                fontSize={16}
              >
                Browse
              </Text>
            </Flex>
            <Text
              color="rgba(29, 30, 34, 0.6)"
              fontSize={16}
              marginTop={3}
              fontFamily="camptonLight"
              fontWeight={400}
            >
              Size limit: 5MB (pdf or docx)
            </Text>
          </Flex>
        )}
        {isDragReject && "File type not accepted, sorry!"}
        {/* {isFileTooLarge && (
          <div className="text-danger mt-2">File is too large.</div>
        )} */}
      </Flex>
    </Box>
  );
};
