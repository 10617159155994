import React from "react";
import Navigation from "../../components/Navigation";
import Hero from "./hero";
import WhoWeAre from "./who-we-are";
import WhatWeDo from "./what-we-do/";
// import Benefits from "./benefits/Benefits";
import Subscribe from "./subscribe";
import Footer from "./footer";
import Banner from "./banner";
import WhatWeOffer from "./what-we-offer";
import FrequentlyAskedQuestion from "./frequenty-asked-question";

const LandingPage = () => {
  return (
    <div>
      <Navigation />
      <Hero />
      <WhoWeAre />
      <WhatWeDo />
      <Banner />
      <WhatWeOffer />
      <FrequentlyAskedQuestion />
      <Subscribe />
      <Footer />
      {/* 
      <Learn />
      <Benefits />
      */}
    </div>
  );
};

export default LandingPage;
